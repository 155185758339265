import { Delete, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider } from '@mui/material';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'src/components/error_message';
import { FormAutocomplete, FormSwitch } from 'src/components/hook_form';
import Loading from 'src/components/loading';
import { useGetStatus } from 'src/hooks';
import { useAppDispatch } from 'src/redux_store';
import { editCameraFunction, getCameraFunction } from 'src/redux_store/camera/camera_action';
import { ICamera, ICameraFunction } from 'src/types/camera';
import { toastMessage } from 'src/utils/toast';
import { useStyles } from './styles';

interface IProps {
  camera: ICamera;
  deleteCamera: () => void;
  refreshCameras?: () => void;
}

const CameraFunction = ({ camera, refreshCameras, deleteCamera }: IProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [data, setData] = React.useState<ICameraFunction | null>(null);
  const [isLoading, isError] = useGetStatus('camera', 'getCameraFunction');
  const [isUpdate] = useGetStatus('camera', 'editCameraFunction');
  const [isDelete] = useGetStatus('camera', 'deleteCamera');

  const defaultValues = React.useMemo(
    () => ({
      functionEnabled: Boolean(data?.functionEnabled),
      isStream: Boolean(data?.streamEnabled),
      isRecord: Boolean(data?.recordEnabled),
      streamEnabled: data?.functionEnabled && data?.streamEnabled ? data.streamEnabled : null,
      recordEnabled: data?.functionEnabled && data?.recordEnabled ? data.recordEnabled : null,
    }),
    [data],
  );

  const { control, handleSubmit, reset, setError, watch } = useForm<ICameraFunction>({
    defaultValues,
  });

  const fetchApi = () => {
    dispatch(getCameraFunction(camera.id))
      .unwrap()
      .then((data) => setData(data));
  };

  React.useEffect(() => {
    if (data) {
      reset(defaultValues);
    }
  }, [data, camera.id]);

  React.useEffect(() => {
    fetchApi();
  }, [camera.id]);

  const onSubmit = (data: ICameraFunction) => {
    dispatch(
      editCameraFunction({
        id: camera.id,
        data: {
          ...data,
          recordEnabled: data.isRecord ? data.streamEnabled : null,
        },
      }),
    )
      .unwrap()
      .then(() => {
        toastMessage.success('Cập nhật camera thành công');
        if (refreshCameras) {
          refreshCameras();
        }
      })
      .catch((errors) => toastMessage.setErrors(errors, setError));
  };

  return (
    <Box className={classes.root} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Scrollbars autoHide>
        <Box flex={1} p={1}>
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <ErrorMessage onClick={fetchApi} />
          ) : (
            <Box>
              <FormSwitch
                control={control}
                label="Bật chức năng camera"
                name="functionEnabled"
                disabled={isUpdate}
              />
              {watch('functionEnabled') && (
                <>
                  {/* <FormSwitch
                    control={control}
                    label="Phát trực tiếp"
                    name="isStream"
                    disabled={isUpdate}
                  /> */}
                  <FormAutocomplete
                    disableClearable
                    control={control}
                    options={data?.streams || []}
                    labelOption={'name'}
                    keyOption={'token'}
                    label="Luồng stream"
                    name="streamEnabled"
                    disabled={isUpdate}
                    required={watch('functionEnabled')}
                  />
                  <FormSwitch
                    control={control}
                    label="Ghi hình"
                    name="isRecord"
                    disabled={isUpdate}
                  />
                  {/* <FormAutocomplete
                    disableClearable
                    control={control}
                    options={data?.streams || []}
                    labelOption={'name'}
                    keyOption={'token'}
                    label="Luồng stream"
                    name="recordEnabled"
                    disabled={isUpdate}
                    required={watch('isRecord')}
                  /> */}
                </>
              )}
            </Box>
          )}
        </Box>
      </Scrollbars>
      <Divider />
      <Box p={1} textAlign="right">
        <LoadingButton
          sx={{ mr: 1 }}
          type="button"
          variant="outlined"
          color="error"
          disabled={isUpdate}
          onClick={deleteCamera}
          startIcon={<Delete />}
          loading={isDelete}
        >
          Xóa camera
        </LoadingButton>

        <LoadingButton
          loading={isUpdate}
          type="submit"
          variant="outlined"
          startIcon={<SaveOutlined />}
        >
          Lưu
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CameraFunction;
