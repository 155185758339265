import { omit } from 'lodash';

import { IPagination } from 'src/types/common';
import {
  IPenalty,
  IPenaltyIndividualRePort,
  IPenaltyReport,
  IPenaltySearch,
  IPenaltyStatistical,
  IPenaltyStatisticalSearch,
  IPenaltyUpdate,
} from 'src/types/penalty';
import { createClient } from './axios_client';
import config from 'src/config';

const client = createClient('https://devmt.danateq.vn/api/v1');
const thirdPartyClient = createClient(`${config.apiThanhtra}/thanhtra/public/api`, true);

export const penaltyApi = {
  getPenalties: (params: IPenaltySearch) => {
    return client.get<IPagination<IPenalty>>(`/penalties`, { params });
  },

  updatePenalty: (id: string, data: IPenaltyUpdate) => client.put(`/penalties/${id}`, data),

  getStatistical: (params: IPenaltyStatisticalSearch) =>
    client.get<{ data: IPenaltyStatistical[] }>('/penalties/statistical', { params }),

  exportPenaltyStatisticsReport: (params: IPenaltyStatisticalSearch) =>
    client.post<any>(
      '/penalties/statistical/exportReport',
      { ...params },
      { responseType: 'blob' },
    ),

  deletePenalty: (id: string) => client.delete(`/penalties/${id}`),

  getBackupPenalty: (params: IPenaltySearch) =>
    client.get<IPagination<IPenalty>>('/penalty_backup', { params }),

  backupPenalty: (id: string) => client.post<string>('/penalty_backup', { id }),
  deleteBackupPenalty: (id: string) => client.delete<string>(`/penalty_backup/${id}`),

  sendMinutes: (id: string, params: IPenaltyReport | IPenaltyIndividualRePort) => {
    const newParams = {
      ...omit(params, ['host_unit']),
      user: 'tainguyennuoc',
      password: 'Asdfgh123@',
    };

    client.put(`/penalties/${id}`, {
      lastRecordedAt: new Date(),
    });

    return thirdPartyClient.get(`/bbvphc/${params.host_unit}/${JSON.stringify(newParams)}`);
  },
};
