import { createAsyncThunk } from '@reduxjs/toolkit';

import { streamApi } from 'src/clients/http';
import { ICameraSearch } from 'src/types/camera';
import {
  IStreamCreate,
  IStreamFilterResponse,
  IRtcStream,
  IStreamFilterRequest,
} from 'src/types/stream';
import { toastMessage } from '../common/toast/toast_action';
import store from '..';

export const getLiveStreamingCams = createAsyncThunk<
  IStreamFilterResponse,
  { streamFilter: IStreamFilterRequest; cameraFilter?: ICameraSearch }
>('watchlist/getLiveStreamingCams', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await streamApi.getLiveStreamingCams(
      payload.streamFilter,
      payload.cameraFilter,
    );
    return data;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const addSingleCamToWatchlist = createAsyncThunk<IRtcStream, IStreamCreate>(
  'watchlist/addSingleCamToWatchlist',
  async (payload, { rejectWithValue }) => {
    try {
      const {
        toolbar: { groupId },
      } = store.getState().watchlistSlice;

      const { data } = await streamApi.addSingleCamToWatchlist({ ...payload, groupId });
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const removeSingleCamFromWatchlist = createAsyncThunk<
  any,
  { position: number; groupId: string }
>('watchlist/removeSingleCamFromWatchlist', async (payload, { rejectWithValue }) => {
  try {
    await streamApi.removeSingleCamFromWatchlist(payload.position, payload.groupId);
    return payload;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const getFollowGroups = createAsyncThunk<any, undefined>(
  'watchlist/getFollowGroups',
  async (_payload, { rejectWithValue }) => {
    try {
      const { data } = await streamApi.getFollowGroups();
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const addFollowGroup = createAsyncThunk<{ id: string; name: string }, string>(
  'watchlist/addFollowGroup',
  async (groupName, { rejectWithValue }) => {
    try {
      const { data } = await streamApi.addFollowGroup(groupName);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteFollowGroup = createAsyncThunk<string, string>(
  'watchlist/deleteFollowGroup',
  async (groupId, { rejectWithValue }) => {
    try {
      await streamApi.deleteFollowGroup(groupId);
      return groupId;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);
