import React, { Component } from 'react';

import Video from '../video';
import Loading from '../loading';
import Manager from '../../manager';
import CameraName from '../camera_name';
import ControlBar from '../control_bar';
import { mergeAndSortChildren } from '../../utils/video';
import { ICamProps, IManager, IPlayer, IPlayerProps } from '../../types/player';
import { VideoDanaPlayer } from './styles';

interface IPlayerComponentProps {
  cam: ICamProps;
  isFollowDisabled?: boolean;
  isTheaterMode?: boolean;
  isHiddenPtz?: boolean;
  isHideModeOption?: boolean
}

export default class Player extends Component<IPlayerComponentProps> {
  manager = new Manager();
  actions = this.manager.getActions();

  constructor(props: IPlayerComponentProps) {
    super(props);

    this.manager.subscribeToPlayerStateChange(this.handleStateChange.bind(this));
  }

  getChildren = (props: IPlayerProps) => {
    const { ...propsWithoutChildren } = props;

    const defaultChildren = this.getDefaultChildren();
    return mergeAndSortChildren(defaultChildren, [], propsWithoutChildren);
  };

  getDefaultChildren = () => {
    return [
      <Video
        key="video"
        ref={(c: Video) => {
          this.manager.video = c;
        }}
        order={0.0}
      />,
      <CameraName key="name" order={1.0} />,
      <Loading key="loading" order={2.0} />,
      <ControlBar key="control-bar" order={3.0} />,
    ];
  };

  handleStateChange = () => {
    this.forceUpdate();
  };

  render() {
    const playerSlice: IPlayer = this.manager.getState().playerSlice;

    const props: IPlayerProps = {
      ...this.props,
      player: playerSlice,
      actions: this.actions,
      manager: this.manager as IManager,
      store: this.manager.store,
    };

    const children = this.getChildren(props);

    return (
      <VideoDanaPlayer ref={(c: HTMLElement | null) => (this.manager.rootElement = c)}>
        {children}
      </VideoDanaPlayer>
    );
  }
}
