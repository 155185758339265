import { createAsyncThunk } from '@reduxjs/toolkit';
import { ptzApi } from 'src/clients/http';

export const initPTZControl = createAsyncThunk<any, string>(
  'ptz/initPTZ',
  async (cameraId, { rejectWithValue }) => {
    try {
      await ptzApi.initPTZ(cameraId);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const startPTZControl = createAsyncThunk<any, { id: string; data: any }>(
  'ptz/startPTZControl',
  async (payload, { rejectWithValue }) => {
    try {
      await ptzApi.startPTZControl(payload.id, payload.data);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const stopPTZControl = createAsyncThunk<any, string>(
  'ptz/stopPTZControl',
  async (id, { rejectWithValue }) => {
    try {
      await ptzApi.stopPTZControl(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const autoFocus = createAsyncThunk<any, string>(
  'ptz/autoFocus',
  async (id, { rejectWithValue }) => {
    try {
      await ptzApi.autoFocus(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const syncDatetime = createAsyncThunk<any, string>(
  'ptz/syncDatetime',
  async (id, { rejectWithValue }) => {
    try {
      await ptzApi.syncDatetime(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
