import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CloseOutlined, SaveOutlined } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import theme from 'src/theme';
import { ICameraConnect } from 'src/types/camera';
import { CameraConnectForm, schemaCameraConnect } from '../camera_form';
import { useStyles } from './styles';
import { useAppDispatch } from 'src/redux_store';
import { createCamera } from 'src/redux_store/camera/camera_action';
import { toastMessage } from 'src/utils/toast';
import { useGetStatus } from 'src/hooks';
import { LoadingButton } from '@mui/lab';

interface IProps {
  isSharedLink?: boolean;
  handleClose: () => void;
  refreshCameras?: () => void;
}

const CameraCreate = ({ handleClose, isSharedLink = false, refreshCameras }: IProps) => {
  const classes = useStyles();

  const [loading] = useGetStatus('camera', 'createCamera');

  const dispatch = useAppDispatch();

  const defaultValues = React.useMemo(() => {
    return {
      name: '',
      stationId: '',
      ...(isSharedLink && { rtspLinks: [{ name: 'Stream 1', rtspLink: '' }] }),
      ...(!isSharedLink && { ip: '', pass: '', user: '', portOnvif: '80', portRtsp: '554' }),
      isSharedLink: Boolean(isSharedLink),
    };
  }, [isSharedLink]);

  const { control, handleSubmit, reset, setError } = useForm<ICameraConnect>({
    resolver: yupResolver(schemaCameraConnect(isSharedLink)),
    defaultValues,
  });

  React.useEffect(() => {
    reset(defaultValues);
  }, [isSharedLink]);

  const onSubmit = (data: ICameraConnect) => {
    dispatch(createCamera(data))
      .unwrap()
      .then(() => {
        toastMessage.success('Thêm camera thành công');
        handleClose();
        if (refreshCameras) {
          refreshCameras();
        }
      })
      .catch((errors) => {
        toastMessage.setErrors(errors, setError);
      });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography color={theme.palette.secondary.main} className={classes.headerTitle}>
            Thêm mới camera
          </Typography>

          <Box pr={1}>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.content} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Scrollbars autoHide>
            <Box flex={1} p={1}>
              <CameraConnectForm control={control} isSharedLink={isSharedLink} disabled={loading} />
            </Box>
          </Scrollbars>
          <Divider />
          <Box p={1} textAlign="right">
            <LoadingButton
              loading={loading}
              type="submit"
              variant="outlined"
              startIcon={<SaveOutlined />}
            >
              Thêm mới
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CameraCreate;
