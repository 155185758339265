import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: '8px 0',
    height: 49,
  },
  headerTitle: {
    textTransform: 'uppercase',
    fontWeight: '600 !important',
    flex: '1',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));
