import { EFolderName } from 'src/types/enum';
import { IDocument } from 'src/types/file';
import { createClient } from './axios_client';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const fileApi = {
  getFolderStationById: (id: string) => {
    return client.get<{ folder: IDocument; files: IDocument[] }>(`/folder`, {
      params: {
        folderName: EFolderName.STATIONS,
        id,
      },
    });
  },

  getFilesByFolderId: (id: string) => {
    return client.get<{ folder: IDocument; files: IDocument[] }>(`/folder/${id}`);
  },

  createFolder: (parentId: string, folderName: string) => {
    return client.post<IDocument>(`/folder`, {
      parentId,
      folderName,
    });
  },

  renameFolder: (id: string, folderName: string) => {
    return client.put<IDocument>(`/folder/${id}`, { folderName });
  },

  deleteFolder: (id: string) => {
    return client.delete<string>(`/folder/${id}`);
  },

  getPenaltyFiles: (id: string) => client.get(`/folder/${id}/penalize`),

  uploadFile: (formData: FormData) => {
    return client.post<IDocument[]>(`/files/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },

  getFileImageOfStation: (stationId: string) => {
    return client.get<{ id: string; path: string }[]>('/folder/images', {
      params: {
        id: stationId,
        folderName: EFolderName.STATIONS,
      },
    });
  },
  downloadFile: (id: string) => {
    return client.get<Blob>(`/files/${id}`, {
      responseType: 'blob',
    });
  },

  renameFile: (id: string, fileName: string) => {
    return client.put<IDocument>(`/files/${id}`, { fileName });
  },

  deleteFile: (id: string) => {
    return client.delete<string>(`/files/${id}`);
  },

  deleteMultipleFile: (files: string) => {
    return client.delete<string>(`/files`, {
      params: {
        files,
      },
    });
  },
};
