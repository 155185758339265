import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { initCameraSearch } from 'src/constants/filter';

// import { initCameraSearch } from 'src/constants/camera';
import { initStreamFormToolbar } from 'src/constants/watchlist';
import { ICameraSearch } from 'src/types/camera';
// import { ICameraSearch } from 'src/types/camera';
import { IRtcStream, IStreamFormToolbar } from 'src/types/stream';
import {
  addFollowGroup,
  addSingleCamToWatchlist,
  deleteFollowGroup,
  getFollowGroups,
  getLiveStreamingCams,
  removeSingleCamFromWatchlist,
} from './watchlist_action';

interface IWatchlistSlice {
  cameras: any[];
  cameraFollowed: IRtcStream[];
  totalPage: number;
  groups: { id: string; name: string }[];
  toolbar: IStreamFormToolbar;
  isAdding: boolean;
  cameraFilter: ICameraSearch;
  timeline: {
    dateTime: Date;
    posPixel: number;
  };
}

const initialState: IWatchlistSlice = {
  cameras: [],
  cameraFollowed: [],
  totalPage: 1,
  groups: [],
  toolbar: { ...initStreamFormToolbar },
  isAdding: false,
  cameraFilter: { ...initCameraSearch },
  timeline: {
    dateTime: new Date(new Date().setHours(0, 0, 0, 0)),
    posPixel: 0,
  },
};

const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    changeParamsFormToolbar: (state, action) => {
      state.toolbar = { ...state.toolbar, ...action.payload };
    },
    resetWatchlistState: (state) => {
      state.cameraFollowed = [];
      state.groups.length = 0;
      state.toolbar = { ...initStreamFormToolbar };
    },
    resetCameraFollowed: (state) => {
      state.cameraFollowed.length = 0;
      state.isAdding = false;
    },
    addTotalPage: (state, action) => {
      if (action.payload) {
        state.totalPage = action.payload;
      } else {
        state.totalPage += 1;
        state.toolbar.page = state.totalPage;
        state.isAdding = true;
      }
      state.toolbar.page = state.totalPage;
      state.cameraFollowed.length = 0;
    },
    changeParamsCameraFilter: (state, action) => {
      state.cameraFilter = isEmpty(action.payload)
        ? { ...initCameraSearch }
        : { ...state.cameraFilter, ...action.payload };
    },
    setTimeline(
      state,
      action: PayloadAction<{
        dateTime: Date;
        posPixel: number;
      }>,
    ) {
      state.timeline.dateTime = action.payload.dateTime;
      state.timeline.posPixel = action.payload.posPixel;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getLiveStreamingCams.fulfilled, (state, action) => {
        state.cameraFollowed = action.payload.data;
        state.totalPage = action.payload.totalPage;
        state.isAdding = false;
      })
      .addCase(addSingleCamToWatchlist.fulfilled, (state, action) => {
        state.cameraFollowed.push(action.payload);
        state.isAdding = false;
      })
      .addCase(removeSingleCamFromWatchlist.fulfilled, (state, action) => {
        state.cameraFollowed = state.cameraFollowed.filter(
          (camera) =>
            camera.position !== action.payload.position &&
            camera.classifiedListId === action.payload.groupId,
        );
      })
      .addCase(getFollowGroups.fulfilled, (state, action) => {
        state.groups = [{ id: 'all', name: 'Tất cả' }, ...action.payload];
      })
      .addCase(addFollowGroup.fulfilled, (state, action) => {
        state.groups.push(action.payload);
      })
      .addCase(deleteFollowGroup.fulfilled, (state, action) => {
        state.groups = state.groups.filter((group) => group.id !== action.payload);
      });
  },
});

const { actions, reducer } = watchlistSlice;
export const {
  changeParamsFormToolbar,
  resetWatchlistState,
  changeParamsCameraFilter,
  addTotalPage,
  setTimeline,
  resetCameraFollowed,
} = actions;
export default reducer;
