import { INotificationSetting, TAudioSoundType } from './../../types/notification';
import { createSlice } from '@reduxjs/toolkit';

import { initPayloadNotification } from 'src/constants/notification';
import { INotification, IPayloadNotification } from 'src/types/notification';
import {
  changeNotificationSound,
  changeNotificationSource,
  changePopupStatus,
  getAllNotification,
  getInfoNotification,
  getNotificationSetting,
  unseenNotification,
} from './notification_action';
import { formatNotificationItem, formatNotificationList } from 'src/utils/notification';
import _ from 'lodash';

interface INotificationState {
  payload: IPayloadNotification;
  mainPayload: IPayloadNotification;

  infoNotification: INotification;
  idNotificationSelected: string;
  notifications: {
    data: INotification[];
  };
  notificationList: INotification[];

  numberOfNotifications: number;

  infoNotificationToast: INotification;

  audioSoundType: TAudioSoundType;
  openViewDetail: boolean;

  notificationSetting: INotificationSetting;

  isLoadMoreNotification: boolean;
}

const initialState: INotificationState = {
  payload: initPayloadNotification,
  mainPayload: initPayloadNotification,
  notifications: {
    data: [],
  },
  infoNotification: {} as INotification,
  idNotificationSelected: '',
  notificationList: [],

  numberOfNotifications: 0,

  infoNotificationToast: {} as INotification,

  audioSoundType: 'info',
  openViewDetail: false,

  notificationSetting: {} as INotificationSetting,
  isLoadMoreNotification: true,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    selectNotificationItem: (state, action: { payload: string }) => {
      const id = action.payload;
      state.idNotificationSelected = id;
    },

    hiddenInfoSelect: (state) => {
      state.idNotificationSelected = '';
      state.infoNotification = {} as INotification;
    },
    // changeTotalPageAndTotalData: (state, action: { payload: 'minus' | 'plus' }) => {
    //   const typeChange = action.payload;
    //   const currentTotalData = state.pagination.totalData;
    //   const limit = state.payload.limit;
    //   let newTotalData: number;

    //   if (typeChange === 'minus') {
    //     newTotalData = currentTotalData - 1;
    //   } else {
    //     newTotalData = currentTotalData + 1;
    //   }
    //   const newTotalPage = Math.ceil(newTotalData / limit);

    //   state.pagination = { totalData: newTotalData, totalPage: newTotalPage };
    // },

    markNotification: (state, action: { payload: { status: boolean; id: string } }) => {
      const { id, status } = action.payload;
      const currentDataMain = [...state.notifications.data];
      const currnentData = [...state.notificationList];

      if (id === 'all') {
        if (currnentData.length > 0) {
          const newData = markAllNotification(currnentData);
          state.notificationList = newData;
        }

        if (currentDataMain.length > 0) {
          const newData = markAllNotification(currentDataMain);
          state.notifications.data = newData;
        }
      } else {
        if (currnentData.length > 0) {
          state.notificationList = markNotificationById(currnentData, id, status);
        }
        if (currentDataMain.length > 0) {
          state.notifications.data = markNotificationById(currentDataMain, id, status);
        }
      }
    },

    resetNotificationState: (state) => {
      state.idNotificationSelected = '';
      state.infoNotification = {} as INotification;

      state.notificationList = [];
      state.infoNotificationToast = {} as INotification;
      state.payload = initPayloadNotification;
      state.isLoadMoreNotification = true;
    },

    changePayload: (state, action: { payload: IPayloadNotification }) => {
      state.payload = action.payload;
    },
    changeMainPayload: (state, action: { payload: IPayloadNotification }) => {
      state.mainPayload = action.payload;
    },

    incrementalNotificationList: (
      state,
      action: { payload: { isReset?: boolean; data: INotification[] } },
    ) => {
      const { isReset, data } = action.payload;
      const currentNotificationList = [...state.notificationList];

      const newData = formatNotificationList(data);
      if (isReset) {
        state.notificationList = newData;
      } else {
        state.notificationList = currentNotificationList.concat(newData);
      }
    },

    addNewNotificationItem: (state, action: { payload: INotification }) => {
      const currentNotificationList = [...state.notificationList];

      const notification = action.payload;
      const newDescription = formatNotificationItem(notification.description);
      const newTitle = formatNotificationItem(notification.title);

      const newNotification: INotification = {
        ...notification,
        title: newTitle,
        description: newDescription,
      };

      const newNotificationList = [newNotification, ...currentNotificationList];

      state.notificationList = newNotificationList;
    },
    setNumberAndInfoOfNotification: (
      state,
      action: {
        payload: {
          numberNotification: number;
          data: INotification;
        };
      },
    ) => {
      const { data, numberNotification } = action.payload;
      state.numberOfNotifications = numberNotification;
      console.log({ data, numberNotification });

      if (!_.isEmpty(data)) {
        const newDescription = formatNotificationItem(data.description);
        const newTitle = formatNotificationItem(data.title);

        const newNotification: INotification = {
          ...data,
          title: newTitle,
          description: newDescription,
        };
        state.infoNotificationToast = newNotification;
      } else {
        state.infoNotificationToast = {} as INotification;
      }
    },

    changeAudioSoundType: (state, action: { payload: TAudioSoundType }) => {
      state.audioSoundType = action.payload;
    },

    toggleViewDetail: (state, action: { payload: { open: boolean; type?: string } }) => {
      const { open } = action.payload;
      state.openViewDetail = open;
    },

    deleteNotificationLocal: (state, action: { payload: string }) => {
      const id = action.payload;
      const currentNotification = [...state.notificationList];
      if (currentNotification.length > 0) {
        const indexNotification = currentNotification.findIndex(
          (notification) => notification.id === id,
        );

        if (indexNotification !== -1) {
          currentNotification.splice(indexNotification, 1);
          state.notificationList = currentNotification;
        }
      }
    },
    resetLoadMore: (state) => {
      state.isLoadMoreNotification = true;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getAllNotification.fulfilled, (state, action) => {
        // const limit = state.payload.limit;
        const limit = action.meta.arg.limit;
        const data = action.payload;

        state.isLoadMoreNotification = data.length === limit;
      })
      .addCase(getAllNotification.rejected, (state) => {
        state.notificationList = [];
      })
      .addCase(getInfoNotification.fulfilled, (state, action) => {
        const data = action.payload;
        state.infoNotification = data;
      })
      .addCase(getNotificationSetting.fulfilled, (state, action) => {
        state.notificationSetting = action.payload;
      })
      .addCase(changeNotificationSound.fulfilled, (state, action) => {
        const data = action.meta.arg;
        const currentNotificationLevels = { ...state.notificationSetting.notificationLevels };
        const itemSoundChange = data.data.notificationLevel;
        const newNotificationLevels = { ...currentNotificationLevels, ...itemSoundChange };

        state.notificationSetting.notificationLevels = newNotificationLevels;
      })
      .addCase(changeNotificationSource.fulfilled, (state, action) => {
        const data = action.meta.arg;
        const itemSourceChange = data.data.notificationSource;
        const cloneNotificationSources = [...state.notificationSetting.notificationSources];

        const indexItemChange = cloneNotificationSources.findIndex(
          (source) => source.source === itemSourceChange.source,
        );

        if (indexItemChange !== -1) {
          cloneNotificationSources.splice(indexItemChange, 1, itemSourceChange);
          state.notificationSetting.notificationSources = cloneNotificationSources;
        }
      })
      .addCase(unseenNotification.fulfilled, (state, action) => {
        const { numberOfNotifications } = action.payload;
        state.numberOfNotifications = numberOfNotifications;
      })
      .addCase(changePopupStatus.fulfilled, (state, action) => {
        const popupStatus = action.meta.arg.data.popupStatus;
        state.notificationSetting.popupStatus = popupStatus;
      });
  },
});

const markNotificationById = (notificationList: INotification[], id: string, status: boolean) => {
  const index = notificationList.findIndex((notification) => notification.id === id);

  if (index !== -1) {
    notificationList[index].isRead = status;
  }

  return notificationList;
};

const markAllNotification = (notificationList: INotification[]) => {
  const newData = notificationList.map((data) => {
    if (data.isRead) return data;
    else return { ...data, isRead: true };
  });

  return newData;
};

const { actions, reducer } = notificationSlice;

export const {
  selectNotificationItem,
  hiddenInfoSelect,
  resetNotificationState,
  markNotification,
  changePayload,
  incrementalNotificationList,
  changeMainPayload,
  addNewNotificationItem,
  setNumberAndInfoOfNotification,
  changeAudioSoundType,
  // changeTotalPageAndTotalData,
  toggleViewDetail,
  deleteNotificationLocal,
} = actions;

export default reducer;
