import { IFirmware, IFirmwareFilter } from 'src/types/firmware';
import { createClient } from './axios_client';

const client = createClient('http://10.49.46.53:5333/api/v1');

export const firmwareApi = {
  getList: (params: IFirmwareFilter) =>
    client.get<{ data: IFirmware[]; totalData: number }>(`/firmware`, {
      params,
    }),
  create: (data: FormData) =>
    client.post<IFirmware>(`/firmware`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  updateById: (
    id: string,
    data: Pick<IFirmware, 'active' | 'name' | 'version' | 'monitoringTypeId'>,
  ) => client.put<IFirmware>(`/firmware/${id}`, data),
  getById: (id: string) => client.get<IFirmware>(`/firmware/${id}`),
  deleteById: (id: string) => client.delete<string>(`/firmware/${id}`),
  downloadById: (id: string) =>
    client.post<Blob>(`/firmware/download/${id}`, {}, { responseType: 'blob' }),
  clipboardById: (id: string) => client.get<string>(`/firmware/clipboard/${id}`),
  syncFirmware: (data: {
    link: string;
    devEui: string[];
    applicationId: string;
    timeOut: number;
    schedule: number;
  }) => client.post('/sync_firmware', data),
};
