import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ArrowLeftOutlined } from '@mui/icons-material';

import { useStyles } from './styles';
import theme from 'src/theme';

interface ICameraHeader {
  setExpanded: any;
  title?: string;
}

const CameraHeader = ({ setExpanded, title = 'Danh sách camera' }: ICameraHeader) => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Typography className={classes.headerTitle}>{title}</Typography>
      <Box display="flex">
        <IconButton
          onClick={setExpanded}
          size="small"
          style={{ color: theme.palette.primary.main }}
        >
          <ArrowLeftOutlined fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CameraHeader;
