import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IStation,
  IStationLogs,
  TStationHostUnit,
  TStationIndicators,
  TStationInformation,
  TStationMethod,
} from 'src/types/station';
import {
  getStationHostById,
  getStationIndicatorsById,
  getStationInfoById,
  getStationLogs,
  getStationMap,
  getStationMethodById,
  getStations,
} from './station_action';
import config from 'src/config';

interface IState {
  createData: Omit<IStation, 'id'>;
  stationId: string;
  stationList: IStation[];
  stationMap: IStation[];
  totalData: number;
  detail: {
    information: TStationInformation;
    hostUnit: TStationHostUnit;
    method: TStationMethod;
    indicators: TStationIndicators;
  };

  stationLogsList: IStationLogs[];
  isLoadMoreStationLogs: boolean;
}

const initialState: IState = {
  createData: {
    name: '',
    monitoringTypeId: '',
    monitoringGroupId: '',
    provinceCode: config.systemName === 'STNMT' ? 48 : '',
    districtCode: '',
    symbol: '',
    address: '',
    lat: null,
    lng: null,
    stationImages: [],
    files: [],
    // status
    enablePublishDataStatus: false,
    activeStatus: false,
    autoApproveDataStatus: false,
    confirmationStatus: false,
    overThresholdAlertStatus: false,
    wrongStructureAlertStatus: false,
    disconnectionStatus: false,
    hasOrganicPollutionConcern: false,
    calculateEnvIndex: false,
    hostUnit: {
      hostUnitType: '',
    },
    sendingMethod: {
      status: false,
    },
    statusSendDataToMinistry: false,
    sendDataToMinistry: [],
    indicators: [],
    envIndex: null,
    syncDataInfo: {
      status: true,
      startAt: null,
      endAt: null,
    },
  },
  stationId: '',
  stationList: [],
  totalData: 0,
  stationMap: [],
  detail: {
    information: <TStationInformation>{},
    hostUnit: <TStationHostUnit>{},
    method: <TStationMethod>{
      sendingMethod: {
        status: false,
      },
      statusSendDataToMinistry: false,
      sendDataToMinistry: [],
    },
    indicators: <TStationIndicators>{},
  },
  stationLogsList: [],
  isLoadMoreStationLogs: true,
};

const stationSlice = createSlice({
  name: 'station',
  initialState,
  reducers: {
    selectStation: (state, action: PayloadAction<string>) => {
      state.detail = initialState.detail;
      state.stationId = action.payload;
    },
    setCreateData: (state, action: PayloadAction<Omit<IStation, 'id'> | 'reset'>) => {
      if (action.payload === 'reset') {
        state.createData = initialState.createData;
      } else {
        state.createData = action.payload;
      }
    },

    resetStationState: (state) => {
      state.detail = initialState.detail;
      state.stationList = initialState.stationList;
      state.stationId = initialState.stationId;
      state.totalData = initialState.totalData;
      state.createData = initialState.createData;
    },
    resetStationLogsState: (state) => {
      state.stationLogsList = initialState.stationLogsList;
      state.isLoadMoreStationLogs = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStations.fulfilled, (state, action) => {
      state.stationList = action.payload.data;
      state.totalData = action.payload.totalData;
    });

    builder.addCase(getStationMap.fulfilled, (state, action) => {
      state.stationMap = action.payload;
      state.stationId = '';
    });

    builder.addCase(getStationInfoById.fulfilled, (state, action) => {
      state.detail.information = action.payload;
    });
    builder.addCase(getStationHostById.fulfilled, (state, action) => {
      state.detail.hostUnit = action.payload;
    });
    builder.addCase(getStationMethodById.fulfilled, (state, action) => {
      state.detail.method = action.payload;
    });
    builder.addCase(getStationIndicatorsById.fulfilled, (state, action) => {
      state.detail.indicators = action.payload;
    });
    builder.addCase(getStationLogs.fulfilled, (state, action) => {
      let currentData = [...state.stationLogsList];
      const dataPayload = action.payload;
      currentData = currentData.concat(dataPayload);
      state.stationLogsList = currentData;
      state.isLoadMoreStationLogs = dataPayload.length === 20;
    });
  },
});

export const { resetStationState, setCreateData, selectStation, resetStationLogsState } =
  stationSlice.actions;

export default stationSlice.reducer;
