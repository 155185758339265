import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Collapse, Typography } from '@mui/material';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { FilterListOutlined, DoneAllOutlined, SettingsOutlined } from '@mui/icons-material';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';

import {
  useAppDispatch,
  useAppSelector,
  useIsRequestError,
  useIsRequestPending,
  useToggle,
} from 'src/hooks';
import theme from 'src/theme';
import { openModal } from 'src/redux_store/common/modal_slice';
import { CModalIds } from 'src/constants/modal';
import { INotification, IPayloadNotification } from 'src/types/notification';
import { initPayloadNotification } from 'src/constants/notification';
import {
  getAllNotification,
  getNotificationSetting,
} from 'src/redux_store/notification/notification_action';
import {
  changePayload,
  incrementalNotificationList,
  resetNotificationState,
} from 'src/redux_store/notification/notification_slice';
import websocket from 'src/clients/websocket';
import { markAllNotification } from 'src/utils/notification';

import { useStyles } from './styles';
import SkeletonLoading from './skeleton_loading';
import TypeTabNotification from './tab_type_notification';
import NotificationSetting from './notification_setting';
import NotificationItem from './notification_item';
import IconButtonTooltip from '../tooltip/icon_button_tooltip';
import NotificationFilterType from '../filter_form/notification_filter_type';
import ErrorMessage from '../error_message';
import Loading from '../loading';

const Notification = () => {
  const scrollRef = useRef<Scrollbars | null>(null);

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isErrorGetNotifications = useIsRequestError('notification', 'getAll');
  const isLoadingGetNotifications = useIsRequestPending('notification', 'getAll');

  const [expandedFilter, setExpandedFilter] = useToggle(false);
  const [expendedId, setExpandedId] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [idItemHover, setIdItemHover] = useState<string>('');

  const { me } = useAppSelector((state) => state.myAccountSlice);
  const { isPin } = useAppSelector(({ topBarSlice }) => topBarSlice);
  const { payload, notificationList, isLoadMoreNotification } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const isLoadingGetNotificationSetting = useIsRequestPending(
    'notification',
    'getNotificationSetting',
  );
  const asyncGetALlNotification = (newPayload: IPayloadNotification, isReset?: boolean) => {
    let timerLoading: any;
    setIsLoading(true);

    dispatch(getAllNotification(newPayload))
      .unwrap()
      .then((data) => {
        const notifications = data;

        timerLoading = setTimeout(() => {
          setIsLoading(false);
          dispatch(incrementalNotificationList({ data: notifications, isReset }));
        }, 500);
      })
      .catch(() => {
        setIsLoading(false);
      });

    return timerLoading;
  };

  useEffect(() => {
    const timerLoading = asyncGetALlNotification(payload);

    return () => {
      clearTimeout(timerLoading);
      dispatch(resetNotificationState());
    };
  }, []);

  const handelScrollToTop = () => {
    const current: any = scrollRef.current;
    if (!current) return;

    current.scrollToTop();
  };

  const onFilter = (source: string[]) => {
    dispatch(incrementalNotificationList({ isReset: true, data: [] }));

    const newPayload: IPayloadNotification = { ...payload, placeOfOrigin: source, after: '' };
    dispatch(changePayload(newPayload));

    if (_.isEmpty(source) && _.isEmpty(payload.type)) {
      if (me) {
        websocket.syncNumberOfNotification(me.id);
      }
    }

    asyncGetALlNotification(newPayload, true);

    handelScrollToTop();
  };

  const handleOpenNotificationSetting = () => {
    dispatch(getNotificationSetting());
    dispatch(
      openModal({
        modalId: CModalIds.notificationSetting,
        modalComponent: <NotificationSetting />,
      }),
    );
  };

  const handleSelectType = (type: string) => {
    dispatch(incrementalNotificationList({ isReset: true, data: [] }));

    let currentTypeSelected = payload.type;

    if (currentTypeSelected === type) {
      currentTypeSelected = '';
    } else {
      currentTypeSelected = type;
    }

    const newPayload: IPayloadNotification = { ...payload, type: currentTypeSelected };

    if (_.isEmpty(payload.placeOfOrigin) && _.isEmpty(currentTypeSelected)) {
      if (me) {
        websocket.syncNumberOfNotification(me.id);
      }
    }
    asyncGetALlNotification(newPayload, true);
    dispatch(changePayload(newPayload));
    handelScrollToTop();
  };

  const handleScroll = () => {
    const current: any = scrollRef.current;

    // 0 <= top <= 1;
    const { top } = current.getValues();

    const roundingTop = Math.ceil(top * 100);

    if (roundingTop === 100 && !isLoadingGetNotifications && isLoadMoreNotification) {
      setIsLoading(true);
    }
  };

  const handleScrollStop = () => {
    if (notificationList.length > 0) {
      const current: any = scrollRef.current;

      // 0<=top<=1
      const { top } = current.getValues();
      const roundingTop = Math.ceil(top * 100);

      if (roundingTop <= 5) {
        if (me) {
          websocket.syncNumberOfNotification(me.id);
        }
      }

      if (roundingTop === 100 && !isLoadingGetNotifications) {
        if (isLoadMoreNotification) {
          const lastNotification = notificationList[notificationList.length - 1];
          const afterId = lastNotification?.id || '';
          asyncGetALlNotification({ ...payload, after: afterId });
          dispatch(changePayload({ ...payload, after: afterId }));
        }
      }
    }
  };

  const handleMarkAllNotification = () => {
    if (payload.type.includes('UNREAD')) {
      dispatch(incrementalNotificationList({ data: [], isReset: true }));
    }
    markAllNotification(dispatch);
  };

  const handleRetryReload = () => {
    dispatch(asyncGetALlNotification(initPayloadNotification, true));
    // dispatch(incrementalNotificationList({ isReset: true, data: [] }));
  };

  const loadmoreItem = () => {
    // let newPage = payload.page + 1;
    // const actualPage = Math.floor(notificationList.length / payload.limit);
    // if (actualPage > payload.page) {
    //   newPage = actualPage + 1;
    // }
    // if (newPage < pagination.totalPage) {
    //   asyncGetALlNotification({ ...payload, page: newPage });
    //   dispatch(changePayload({ ...payload, page: newPage }));
    // }
  };

  const renderContent = () => {
    if (!notificationList.length) {
      if (isLoading) {
        return <SkeletonLoading numberShow={14} />;
      } else
        return (
          <Box textAlign="center" p={1}>
            <Typography>Không có thông báo</Typography>
          </Box>
        );
    } else {
      return (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadmoreItem}
            loader={<SkeletonLoading numberShow={2} />}
            useWindow={false}
            hasMore={isLoadMoreNotification}
            initialLoad={true}
          >
            {notificationList.map((notification: INotification, index) => {
              return (
                <NotificationItem
                  key={index}
                  notification={notification}
                  expandedId={expendedId}
                  setExpandedId={setExpandedId}
                  idItemHover={idItemHover}
                  setIdItemHover={setIdItemHover}
                />
              );
            })}
          </InfiniteScroll>
        </>
      );
    }
  };

  return (
    <Box className={classes.root}>
      <Box p={1}>
        <Grid className={classes.buttonControl} container justifyContent="flex-end">
          <Grid item>
            <IconButtonTooltip
              title="Lọc thông báo"
              icon={<FilterListOutlined fontSize="medium" />}
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={setExpandedFilter}
            />
          </Grid>
          <Grid item>
            {/* Done all */}
            <IconButtonTooltip
              title="Đánh dấu tất cả đã đọc"
              icon={<DoneAllOutlined fontSize="medium" />}
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={handleMarkAllNotification}
            />
          </Grid>

          {/* setting notification */}
          <Grid item>
            <IconButtonTooltip
              title="Cài đặt thông báo "
              icon={<SettingsOutlined fontSize="medium" />}
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={handleOpenNotificationSetting}
            />
          </Grid>
        </Grid>
        <Collapse in={expandedFilter}>
          <Box sx={{ background: '#fff', padding: 1 }}>
            <TypeTabNotification handleSelectType={handleSelectType} type={payload.type} />

            {isLoadingGetNotificationSetting ? (
              <Loading />
            ) : (
              <NotificationFilterType onFilter={onFilter} />
            )}
          </Box>
        </Collapse>
      </Box>
      <Box flex={1} display="flex" flexDirection="column">
        {isErrorGetNotifications ? (
          <ErrorMessage onClick={handleRetryReload} />
        ) : (
          <Scrollbars
            style={{ height: isPin ? '100%' : '95%' }}
            ref={scrollRef}
            onScrollStop={handleScrollStop}
            onScroll={handleScroll}
            autoHide
          >
            <Grid container flexDirection="column" className={classes.container}>
              {renderContent()}
            </Grid>
          </Scrollbars>
        )}
      </Box>
    </Box>
  );
};

export default Notification;
