import {
  Map,
  MapOutlined,
  SettingsInputComposite,
  PinDrop,
  VideoSettings,
  Groups2Outlined,
  Groups,
  AnnouncementOutlined,
  HomeOutlined,
  Home,
  LocalDrink,
  LocalDrinkOutlined,
  CreateOutlined,
  Create,
  RequestPageOutlined,
  RequestPage,
  SyncAlt,
  SaveAlt,
  Tune,
  UpgradeOutlined,
  Upgrade,
  ReportProblemOutlined,
  ReportProblem,
} from '@mui/icons-material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

import { CPath } from './path';
import { PermissionSchemes } from './role';
import { IMenuRoute } from 'src/types/route';

import { ReactComponent as UserLog } from 'src/assets/svg/user_log.svg';
import { ReactComponent as UserLogActive } from 'src/assets/svg/user_log_active.svg';
import { ReactComponent as Api } from 'src/assets/svg/api.svg';
import { ReactComponent as ApiActive } from 'src/assets/svg/api_active.svg';
import { isSTNMT, isSiTechSystem } from 'src/utils/function';
import config from 'src/config';
import { IRole } from 'src/types/role';
import { ERoleLevel } from 'src/types/enum';

export const CMenuList: IMenuRoute[] = [
  {
    title: 'Dữ liệu mới nhất',
    path: CPath.home,
    icon: HomeOutlined,
    activeIcon: Home,
    isShow: true,
    isExpandSetting: false,
    permission: [PermissionSchemes.getListNewestData],
  },
  {
    title: 'Bản đồ',
    path: CPath.map,
    icon: MapOutlined,
    activeIcon: Map,
    permission: [PermissionSchemes.getListMap],
    isShow: true,
    isExpandSetting: false,
  },
  {
    title: 'Báo cáo thống kê',
    path: CPath.statistic,
    icon: LibraryBooksOutlinedIcon,
    activeIcon: LibraryBooksOutlinedIcon,
    isShow: true,
    isExpandSetting: false,
    permission: [PermissionSchemes.getViewGeneralReport],
  },
  {
    title: 'Biên tập dữ liệu',
    path: CPath.dataManagement,
    icon: CreateOutlined,
    activeIcon: Create,
    isShow: config.systemName !== 'STTTT',
    isExpandSetting: false,
    permission: [PermissionSchemes.editData],
  },
  {
    title: 'Quản lý trạm quan trắc',
    path: CPath.station,
    icon: PinDrop,
    activeIcon: PinDrop,
    permission: [PermissionSchemes.getListStation],
    isShow: true,
    isExpandSetting: false,
  },
  {
    title: 'Quản lý sự cố',
    icon: ReportProblemOutlined,
    activeIcon: ReportProblem,
    path: CPath.problemManagement,
    isShow: isSTNMT,
    isExpandSetting: false,
    permission: [PermissionSchemes.getListStation],
  },
  {
    title: 'Quản lý xử phạt',
    icon: RequestPageOutlined,
    activeIcon: RequestPage,
    path: CPath.penaltyManagement,
    isShow: isSTNMT,
    isExpandSetting: false,
    permission: [PermissionSchemes.getListPenalties],
  },
  {
    title: 'Lấy mẫu tự động',
    path: CPath.autoSample,
    icon: LocalDrinkOutlined,
    activeIcon: LocalDrink,
    permission: [PermissionSchemes.autoSample],
    isShow: isSTNMT,
    isExpandSetting: false,
  },

  // {
  //   title: 'Danh sách theo dõi',
  //   path: CPath.watchlist,
  //   icon: AppsOutlined,
  //   activeIcon: Apps,
  //   permission: [PermissionSchemes.getListStation],
  // },
  {
    title: 'Quản lý camera',
    path: CPath.camera,
    icon: VideoSettings,
    activeIcon: VideoSettings,
    permission: [PermissionSchemes.configCamera],
    isShow: isSTNMT,
    isExpandSetting: false,
  },
  {
    path: CPath.synchronizedData,
    title: 'Đồng bộ một cửa',
    icon: SyncAlt,
    activeIcon: SyncAlt,
    permission: [PermissionSchemes.getListStation],
    isShow: isSTNMT,
    isExpandSetting: false,
  },
  {
    title: 'Quản lý chỉ số',
    path: CPath.indicator,
    icon: SettingsInputComposite,
    activeIcon: SettingsInputComposite,
    permission: [
      PermissionSchemes.getListMonitoringType,
      PermissionSchemes.getListMonitoringGroup,
      PermissionSchemes.getListIndicator,
    ],
    isShow: true,
    isExpandSetting: false,
  },
  {
    title: 'Quản lý người dùng',
    path: CPath.userManagement,
    icon: Groups2Outlined,
    activeIcon: Groups,
    permission: [PermissionSchemes.getListUser],
    isShow: true,
    isExpandSetting: false,
  },
  {
    title: 'Nhật ký người dùng',
    path: CPath.userLogs,
    icon: UserLog,
    activeIcon: UserLogActive,
    permission: [PermissionSchemes.getUserLog],
    isShow: true,
    isExpandSetting: true,
  },
  {
    title: 'Quản lý bài đăng',
    path: CPath.postManagement,
    icon: AnnouncementOutlined,
    activeIcon: AnnouncementOutlined,
    permission: [PermissionSchemes.getListPost],
    isShow: isSTNMT,
    isExpandSetting: true,
  },
  {
    title: 'Quản lý API',
    path: CPath.apiManagement,
    icon: Api,
    activeIcon: ApiActive,
    permission: [PermissionSchemes.getApi],
    isShow: isSTNMT,
    isExpandSetting: true,
  },
  {
    title: 'Quản lý Firmware',
    path: CPath.firmware,
    icon: SaveAlt,
    activeIcon: SaveAlt,
    permission: [PermissionSchemes.getListStation],
    isShow: isSiTechSystem,
    isExpandSetting: true,
  },

  {
    title: 'Tiếp nhận dữ liệu',
    path: CPath.collectData,
    icon: UpgradeOutlined,
    activeIcon: Upgrade,
    permission: [PermissionSchemes.editData],
    isShow: isSiTechSystem,
    isExpandSetting: true,
  },

  {
    title: 'Cấu hình hệ thống',
    path: CPath.systemSetting,
    icon: Tune,
    activeIcon: Tune,
    permission: [PermissionSchemes.updateSystemSetting],
    isShow: true,
    isExpandSetting: true,
  },
];

const permissionsExpanded = CMenuList.reduce<string[]>((result, item) => {
  if (item.isExpandSetting) {
    const permission = item?.permission || [];
    return result.concat(permission);
  } else {
    return result;
  }
}, []);

export const checkPermissionExpanded = (role: IRole | null) => {
  if (config.systemName === 'STTTT') return false;

  if (!role) return false;

  if (role.level === ERoleLevel.SUPER_ADMIN || role.level === ERoleLevel.SYSTEM_ADMIN) return true;

  return (
    role.permissions.filter((permission) => permissionsExpanded.includes(permission)).length > 0
  );
};
