import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { IGetData, IPayloadGetData } from 'src/types/data';
import { approveData, editData, getData } from './data_actions';

interface IState {
  dataList: IGetData;
  payloadData: IPayloadGetData;
}

const initialState: IState = {
  dataList: {
    data: [],
    totalData: 0,
  },
  payloadData: {
    limit: 20,
    dataType: 'MINUTE',
    page: 1,
    stationId: '',
    startTime: moment().subtract(7, 'days'),
    endTime: moment(),
  },
};

const dataSlice = createSlice({
  name: 'myData',
  initialState,
  reducers: {
    changePayload: (state, action: { payload: IPayloadGetData }) => {
      state.payloadData = action.payload;
    },
    resetDataList: (state) => {
      state.dataList = {
        data: [],
        totalData: 0,
      };
    },
    editDataList: (state, action) => {
      const index = state.dataList.data.findIndex((item) => item.id === action.payload);
      state.dataList.data.splice(index, 1);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.dataList = action.payload;
      })
      .addCase(approveData.fulfilled, (state, action) => {
        const { dataId, approved } = action.meta.arg;

        const cloneData = [...state.dataList.data];

        const index = cloneData.findIndex((item) => item.id === dataId);

        if (index !== -1) {
          cloneData.splice(index, 1, {
            ...cloneData[index],
            approved,
          });

          state.dataList.data = cloneData;
        }
      })
      .addCase(editData.fulfilled, (state, action) => {
        const { dataId, details } = action.meta.arg;
        const newDataIndicator: { [x: string]: number } = {};

        details.map((item) => {
          newDataIndicator[item.indicator] = item.value;
        });
        const cloneData = [...state.dataList.data];
        const index = cloneData.findIndex((item) => item.id === dataId);
        if (index !== -1) {
          cloneData.splice(index, 1, {
            ...cloneData[index],
            data: newDataIndicator,
          });
          state.dataList.data = cloneData;
        }
      });
  },
});

export const { changePayload, resetDataList, editDataList } = dataSlice.actions;

export default dataSlice.reducer;
