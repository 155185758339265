import axios from 'axios';
import { IChartData, IGetChartData } from 'src/types/station_chart';

const token = 'GYVZS1e1xWdIah6X2BZCCT4d2GLN1Ov5Yzvkp9jS';

export const stationChartApi = {
  getStationChart: ({ endAt, limit, startAt, userId, stationId }: IGetChartData) => {
    return axios.get<IChartData>(`/dulieubieudo/getData/${userId}`, {
      params: {
        startAt,
        endAt,
        limit,
        stationId,
      },
      headers: {
        Authorization: token,
      },
    });
  },
};
