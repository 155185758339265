import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { ControlCamera } from '@mui/icons-material';

import store from 'src/redux_store';
import { togglePTZ } from 'src/redux_store/stream/stream_slice';
import { toastMessage } from 'src/redux_store/common/toast/toast_action';
import { IPlayerProps } from 'src/components/player_hls/types/player';

const dispatch = store.dispatch;

function PTZToggle(props: any) {
  const slice = props as IPlayerProps;
  const { cam, isHiddenPtz } = slice;

  const handleClick = () => {
    if (!cam.isSupportPtz) return toastMessage.error('Camera không hỗ trợ PTZ');
    dispatch(togglePTZ());
  };

  if (cam.classifiedListId || cam.position || isHiddenPtz) return null;

  const controlText = 'Điều khiển PTZ';

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top-start"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <ControlCamera />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default PTZToggle;
