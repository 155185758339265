import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  fullHeight: {
    overflow: 'initial !important',
    height: '100% !important',
    width: '100% !important',
    margin: '0px !important',
    maxWidth: '100% !important',
  },
  body: {
    display: 'flex',
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  tabLayout: {
    height: '100%',
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(3),
    background: theme.palette.secondary.light,
  },
  closeButton: {
    position: 'absolute',
    top: 15,
    right: '20%',
    zIndex: 100000,
    [theme.breakpoints.down('md')]: {
      right: '5%',
    },
    [theme.breakpoints.up('md')]: {
      right: '15%',
    },
    [theme.breakpoints.up('lg')]: {
      right: '20%',
    },
  },

  tabLabel: {
    alignItems: 'start',
    textTransform: 'inherit',
  },
}));
