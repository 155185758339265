import React from 'react';
import { Add } from '@mui/icons-material';
import { Box, Fab, Fade, Menu, MenuItem } from '@mui/material';
import { ECameraAction } from 'src/types/enum';
import CameraManage from './camera_manage';
import { ICamera, ICameraSearch } from 'src/types/camera';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getCameras } from 'src/redux_store/camera/camera_action';
import { CameraCompound } from 'src/components/camera_compound';
import { CLimit } from 'src/constants';
import { resetCameraState } from 'src/redux_store/camera/camera_slice';
import { useGetStatus } from 'src/hooks';

const CameraManagement = () => {
  const { cameras, totalData } = useAppSelector((state) => state.cameraSlice);
  const [actionType, setActionType] = React.useState<ECameraAction>(ECameraAction.CLOSE);
  const [filter, setFilter] = React.useState<ICameraSearch>({
    searchKeyword: '',
    station: '',
    page: 1,
    limit: CLimit,
  });
  const [camera, setCamera] = React.useState<ICamera | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, error] = useGetStatus('camera', 'getCameras');

  const dispatch = useAppDispatch();

  const handleClickFab = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setActionType(ECameraAction.CLOSE);
    setCamera(null);
    setAnchorEl(null);
  };

  const handleCreate = () => {
    setActionType(ECameraAction.CREATE);
    setCamera(null);
    setAnchorEl(null);
  };

  const handleShareLink = () => {
    setActionType(ECameraAction.SHARE_LINK);
    setCamera(null);
    setAnchorEl(null);
  };

  const selectCamera = (cam: ICamera) => {
    if (camera && cam.id === camera.id) return
    setActionType(ECameraAction.UPDATE);
    setCamera(cam);
    setAnchorEl(null);
  };

  const handleFilter = (filter: ICameraSearch) => {
    setFilter({
      station: filter.station,
      searchKeyword: filter.searchKeyword,
      page: 1,
      limit: CLimit,
    });
  };

  const handleChangePage = (page: number) => {
    setFilter({
      ...filter,
      page,
    });
  };

  const fetchCameraApi = () => {
    dispatch(getCameras(filter));
  };

  React.useEffect(() => {
    fetchCameraApi();
  }, [filter]);

  React.useEffect(() => {
    return () => {
      dispatch(resetCameraState());
    };
  }, []);

  return (
    <Box display="flex" flex={1}>
      <Box position="relative">
        <CameraCompound>
          <CameraCompound.Filter disabled={loading} handleFilter={handleFilter} />
          <CameraCompound.List
            isLoading={loading}
            isError={error}
            cameras={cameras}
            onRefresh={fetchCameraApi}
            selectCamera={selectCamera}
            selectedId={camera?.id}
          />
          <CameraCompound.Pagination
            disabled={loading}
            page={filter.page || 1}
            totalPage={Math.ceil(totalData / CLimit)}
            handleChangePage={handleChangePage}
          />
        </CameraCompound>

        <Box position="absolute" sx={{ bottom: 0, right: 0 }} mr={2} mb={8}>
          <Fab size="small" onClick={handleClickFab} color="primary">
            <Add />
          </Fab>
          <Menu
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleCreate}>Camera</MenuItem>
            <MenuItem onClick={handleShareLink}>Share Link</MenuItem>
          </Menu>
        </Box>
      </Box>
      <CameraManage
        actionType={actionType}
        camera={camera}
        handleClose={handleClose}
        refreshCameras={fetchCameraApi}
      />
    </Box>
  );
};

export default CameraManagement;
