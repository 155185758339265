import { createClient } from './axios_client';

const client = createClient('http://localhost:5050/api/v1');

export const ptzApi = {
  initPTZ: (id: string) => client.put(`/camera/ptz/${id}/init`),
  startPTZControl: (id: string, { pan, tilt, zoom }: { pan: string; tilt: string; zoom: string }) =>
    client.put(`/camera/ptz/${id}/start?pan=${pan}&tilt=${tilt}&zoom=${zoom}`),
  stopPTZControl: (id: string) => client.put(`/camera/ptz/${id}/stop`),
  autoFocus: (id: string) => client.put(`/camera/ptz/${id}/autofocus`),
  syncDatetime: (id: string) => client.put(`/camera/ptz/${id}/datetime`),
};
