import qs from 'query-string';

import { IShareVideo, IVideoDownload, IVideoHls, IVideoHlsBody } from 'src/types/video';
import { createClient } from './axios_client';
// import HttpClient, { HTTP_URL } from '.';

const client = createClient('https://dev.danateq.vn/api/v1');

export const videoApi = {
  getVideoHls: (data: IVideoHlsBody) => {
    return client.post<IVideoHls[]>(`/video/hls`, data);
  },

  shareVideo: (data: IShareVideo) => client.post<string>('/video/share', data),

  checkExportVideo: (params: IVideoDownload) =>
    client.get<string>(`/video/checkexport?${qs.stringify(params)}`),

  downloadVideo: (params: IVideoDownload) => {
    const href = `${client}/video/export?${qs.stringify(params)}`;

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'video.flv');
    link.setAttribute('target', '_blank');

    document.body.appendChild(link);
    link.click();
    link.remove();

    return;
  },
};
