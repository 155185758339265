import qs from 'query-string';
import { IPost, IPostSearch } from 'src/types/post';
import { createClient } from './axios_client';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const postApi = {
  // Post
  getAllPosts: (params: IPostSearch) => {
    return client.get<{
      data: IPost[];
      totalData: number;
    }>(`/news?${qs.stringify(params)}`);
  },

  addNewPosts: (data: Omit<IPost, 'id' | 'createdAt' | 'updatedAt'>) => {
    return client.post<IPost>(`/news`, data);
  },

  getPostById: (id: string) => {
    return client.get<IPost>(`/news/${id}`);
  },

  editPostById: (newData: IPost) => {
    return client.put<IPost>(`/news/${newData.id}`, newData);
  },

  deletePostById: (id: string) => {
    return client.delete<string>(`/news/${id}`);
  },

  uploadImage: (data: FormData) => {
    return client.post<{ id: string; path: string }[]>(`/files/upload/article`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
};
