import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

import Loading from 'src/components/loading';
import { ICamera } from 'src/types/camera';
import ErrorMessage from 'src/components/error_message';
import CameraItem from 'src/components/card/camera_item';

interface ICameraList {
  isLoading?: boolean;
  isError?: boolean;
  cameras: ICamera[];
  selectedId?: string;
  icon?: ReactNode;
  isStation?: boolean;
  onRefresh?: () => void;
  selectCamera?: (camera: ICamera) => void;
}

const CameraList = ({
  isLoading,
  isError,
  cameras,
  selectCamera,
  onRefresh,
  selectedId,
  isStation,
  icon,
}: ICameraList) => {
  const renderCamera = () => {
    if (isLoading) {
      return (
        <Box width="100%">
          <Loading />
        </Box>
      );
    }

    if (isError) {
      return <ErrorMessage sx={{ flex: 1 }} textAlign="center" onClick={onRefresh} />;
    }

    return (
      <Scrollbars>
        <Box pl={1} pr={1.5}>
          {cameras.length === 0 ? (
            <Box>
              <Typography textAlign="center">Không có dữ liệu!</Typography>
            </Box>
          ) : (
            <Box>
              {cameras.map((camera, index) => {
                return (
                  <CameraItem
                    key={index}
                    selectedId={selectedId}
                    selectCamera={selectCamera}
                    camera={camera}
                    icon={icon}
                    isStation={isStation}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Scrollbars>
    );
  };

  return (
    <Box display="flex" flex="1">
      {renderCamera()}
    </Box>
  );
};

export default CameraList;
