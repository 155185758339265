import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCameraLogs, getCameras } from './camera_action';
import { ICamera, ICameraLogs } from 'src/types/camera';

interface IState {
  cameras: ICamera[];
  totalData: number;
  selectedCamera: ICamera | null;
  cameraLogsList: ICameraLogs[];
  isLoadMoreCameraLogs: boolean;
  isLoading: boolean;
}

const initialState: IState = {
  cameras: [],
  totalData: 0,
  selectedCamera: null,
  cameraLogsList: [],
  isLoadMoreCameraLogs: true,
  isLoading: true,
};

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setSelectedCamera: (state: IState, action: PayloadAction<ICamera | null>) => {
      state.selectedCamera = action.payload;
    },
    resetCameraState: (state) => {
      state.cameras = initialState.cameras;
      state.totalData = initialState.totalData;
    },
    resetCameraLogsState: (state) => {
      state.cameraLogsList = initialState.cameraLogsList;
      state.isLoadMoreCameraLogs = true;
      state.isLoading = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCameras.fulfilled, (state, action) => {
      state.cameras = action.payload.data;
      state.totalData = action.payload.totalData;
    });
    builder.addCase(getCameraLogs.fulfilled, (state, action) => {
      let currentData = [...state.cameraLogsList];
      const dataPayload = action.payload;
      currentData = currentData.concat(dataPayload);
      state.cameraLogsList = currentData;
      state.isLoadMoreCameraLogs = dataPayload.length === 20;
      state.isLoading = false;
    });
  },
});

export const { setSelectedCamera, resetCameraState, resetCameraLogsState } = cameraSlice.actions;

export default cameraSlice.reducer;
