import React, { useEffect } from 'react';
import * as yup from 'yup';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ConfirmationDialog from 'src/components/modal/confirm_dialog';
import { Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { FormInput } from 'src/components/hook_form';
import { updateUser } from 'src/redux_store/user/user_actions';
import { toastMessage } from 'src/utils/toast';
import { closeModal } from 'src/redux_store/common/modal_slice';
import { changeName } from 'src/redux_store/my_account/my_account_slice';

interface IProps {
  modalId: string;
}

interface INumberName {
  name: string;
}

const defaultValues = {
  name: '',
};

const validationInput = yup.object().shape({
  name: yup
    .string()
    .min(5, 'Tên người dùng ít nhất 5 ký tự')
    .max(30, 'Tên người dùng nhiều nhất 30 ký tự')
    .required('Tên người dùng không được để trống'),
});

const ModalEditName = ({ modalId }: IProps) => {
  const dispatch = useAppDispatch();
  const { me } = useAppSelector((state) => state.myAccountSlice);

  const { control, handleSubmit, reset } = useForm<INumberName>({
    defaultValues,
    resolver: yupResolver(validationInput),
  });

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
  };

  const handleGetDataName = (data: INumberName) => {
    dispatch(
      updateUser({
        user: {
          name: data.name,
        },
        userId: 'me',
      }),
    )
      .unwrap()
      .then(() => {
        toastMessage.success('Cập nhật người dùng thành công');
        dispatch(changeName(data.name));
        handleClose();
      });
  };

  const renderForm = () => {
    return (
      <Box component="form" sx={{ padding: '0 20px' }} onSubmit={handleSubmit(handleGetDataName)}>
        <FormInput control={control} name="name" label="Nhập tên người dùng" />
      </Box>
    );
  };

  useEffect(() => {
    if (!me) return;
    reset({
      name: me.name,
    });
  }, [me]);

  return (
    <Box>
      <ConfirmationDialog
        modalId={modalId}
        functionName="updateUser"
        sliceName="user"
        describe={<b>Thay đổi họ tên người dùng</b>}
        callback={handleSubmit(handleGetDataName)}
        icon={<BadgeOutlinedIcon color="primary" />}
      >
        {renderForm()}
      </ConfirmationDialog>
    </Box>
  );
};

export default ModalEditName;
