import React from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { KeyOutlined } from '@mui/icons-material';

import ConfirmationDialog from 'src/components/modal/confirm_dialog';
import { useAppDispatch } from 'src/redux_store';
import { FormInput } from 'src/components/hook_form';
import { updateMyPassword } from 'src/redux_store/my_account/my_account_actions';
import { toastMessage } from 'src/utils/toast';
import { closeModal } from 'src/redux_store/common/modal_slice';

interface IProps {
  modalId: string;
}

interface IChangePassword {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

const defaultValues = {
  oldPassword: '',
  password: '',
  confirmPassword: '',
};

const validationInput = yup.object().shape({
  oldPassword: yup
    .string()
    .min(8, 'Mật khẩu ít nhất 8 ký tự')
    .required('Mật khẩu củ không được để trống'),
  password: yup
    .string()
    .min(8, 'Mật khẩu ít nhất 8 ký tự')
    .required('Mật khẩu mới không được để trống'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Xác thực mật khẩu không chính xác')
    .required('Xác thực mật khẩu củ không được để trống'),
});

const ModalEditPassword = ({ modalId }: IProps) => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<IChangePassword>({
    defaultValues,
    resolver: yupResolver(validationInput),
  });

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
  };

  const handleChangePassword = (data: IChangePassword) => {
    dispatch(
      updateMyPassword({
        userId: 'me',
        oldPassword: data.oldPassword,
        newPassword: data.password,
      }),
    )
      .unwrap()
      .then(() => {
        toastMessage.success('Thay đổi mật khẩu thành công');
        handleClose();
      });
  };

  const renderForm = () => {
    return (
      <Box component="form" p={2} onSubmit={handleSubmit(handleChangePassword)}>
        <FormInput control={control} name="oldPassword" label="Mật khẩu cũ" type="password" />
        <FormInput
          control={control}
          name="password"
          label="Mật khẩu mới"
          type="password"
          disabled={!isDirty && !isValid}
        />
        <FormInput
          control={control}
          name="confirmPassword"
          label="Xác thực mật khẩu"
          type="password"
          disabled={!isDirty && !isValid}
        />
      </Box>
    );
  };

  return (
    <Box>
      <ConfirmationDialog
        modalId={modalId}
        functionName="updateMyPassword"
        sliceName="myAccount"
        describe={
          <Box>
            <b>Thay đổi mật khẩu</b>
            <Typography>Nhâp mật khẩu hiện tại của bạn và mật khẩu mới</Typography>
          </Box>
        }
        callback={handleSubmit(handleChangePassword)}
        icon={<KeyOutlined color="primary" />}
        disabled={!isDirty && !isValid}
      >
        {renderForm()}
      </ConfirmationDialog>
    </Box>
  );
};

export default ModalEditPassword;
