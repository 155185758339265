import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';

import { penaltyApi } from 'src/clients/http';
import { CModalIds } from 'src/constants';
import { IPagination } from 'src/types/common';
import {
  IPenalty,
  IPenaltyIndividualRePort,
  IPenaltyReport,
  IPenaltySearch,
  IPenaltyStatistical,
  IPenaltyStatisticalSearch,
  IPenaltyUpdate,
} from 'src/types/penalty';
import { toastMessage } from 'src/utils/toast';
import { closeModal } from '../common/modal_slice';

export const getPenalties = createAsyncThunk<IPagination<IPenalty>, IPenaltySearch>(
  'penalty/getPenalties',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await penaltyApi.getPenalties(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updatePenalty = createAsyncThunk<
  any,
  {
    id: string;
    data: IPenaltyUpdate;
  }
>('penalty/updatePenalty', async (payload, { rejectWithValue }) => {
  try {
    await penaltyApi.updatePenalty(payload.id, payload.data);
    return payload;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const getStatistical = createAsyncThunk<
  { data: IPenaltyStatistical[] },
  IPenaltyStatisticalSearch
>('penalty/getStatistical', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await penaltyApi.getStatistical(payload);
    return data;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const exportPenaltyStatisticsReport = createAsyncThunk<any, IPenaltyStatisticalSearch>(
  'penalty/exportPenaltyStatisticsReport',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await penaltyApi.exportPenaltyStatisticsReport(payload);
      const blob = new Blob([data.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const filename = data?.headers?.['content-disposition'] || `${new Date()}.xls`;
      saveAs(blob, filename);
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deletePenalty = createAsyncThunk<string, string>(
  'penalty/deletePenalty',
  async (id, { rejectWithValue }) => {
    try {
      await penaltyApi.deletePenalty(id);
      return id;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

//backup
export const getBackupPenalty = createAsyncThunk<IPagination<IPenalty>, IPenaltySearch>(
  'penalty/getBackupPenalty',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await penaltyApi.getBackupPenalty(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const backupPenalty = createAsyncThunk<string, string>(
  'penalty/backupPenalty',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await penaltyApi.backupPenalty(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const deleteBackupPenalty = createAsyncThunk<string, string>(
  'penalty/deleteBackupPenalty',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await penaltyApi.deleteBackupPenalty(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message);
      return rejectWithValue(error);
    }
  },
);

export const sendMinutes = createAsyncThunk<
  any,
  { id: string; data: IPenaltyReport | IPenaltyIndividualRePort }
>('penalty/sendMinutes', async (payload, { rejectWithValue, dispatch }) => {
  try {
    await penaltyApi.sendMinutes(payload.id, payload.data);
    toastMessage.success('Gửi biên bản vi phạm thành công');
    dispatch(closeModal({ modalId: CModalIds.report }));

    return payload;
  } catch (error: any) {
    toastMessage.error(error?.message || error?.detail);
    return rejectWithValue(error);
  }
});
