import { createClient } from './axios_client';
import { ICommune, IDistrict, IProvince } from 'src/types/political';

const client = createClient('http://10.49.46.53:5000/api/v1');

export const politicalApi = {
  getProvinces: () => {
    return client.get<IProvince[]>('/political/provinces');
  },
  getDistrict: (province: string) => {
    return client.get<IDistrict[]>('/political/districts', {
      params: {
        province,
      },
    });
  },
  getCommunes: (district: string) => {
    return client.get<ICommune[]>('/political/communes', {
      params: {
        district,
      },
    });
  },
};
