import { createSlice } from '@reduxjs/toolkit';
import { IFilterUserLogs, IUserLog } from 'src/types/user_log';
import { getDataUserLog } from './user_log_action';

interface IState {
  dataUserLogs: IUserLog;
  payload: IFilterUserLogs;
}

const initialState: IState = {
  dataUserLogs: { totalData: 1, data: [] },
  payload: {
    limit: 20,
    page: 1,
    fromTime: String(new Date(new Date().setDate(new Date().getDate() - 7))),
    toTime: String(new Date()),
    type: '',
    userId: '',
  },
};

const userLogsSlice = createSlice({
  name: 'auditLog',
  initialState,
  reducers: {
    changePayload: (state, action: { payload: IFilterUserLogs }) => {
      const newPayload = action.payload;
      state.payload = newPayload;
    },
    resetPayload: (state) => {
      state.payload = {
        ...initialState.payload,
        fromTime: String(new Date(new Date().setDate(new Date().getDate() - 7))),
        toTime: String(new Date()),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataUserLog.fulfilled, (state, action) => {
      state.dataUserLogs = action.payload;
    });
  },
});

export const { changePayload, resetPayload } = userLogsSlice.actions;

export default userLogsSlice.reducer;
