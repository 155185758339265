import {
  getAdvanceDataOfMonitoringData,
  getDataSheet,
} from 'src/redux_store/statistic/statistic_action';
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  IPayloadDataSheet,
  IPayloadGetAdvanceData,
  IResponseGetAdvanceData,
  IResponseGetDataSheet,
  IResponseGetWrongStructureData,
  IStationFilter,
  IStationFilterForWrongStructure,
  IStatisticSearch,
  IStatisticStation,
  IStatisticSurfaceWater,
} from 'src/types/statistic';
import {
  getStatisticStation,
  getStatisticSurfaceWater,
  getWrongStructure,
} from './statistic_action';

interface IState {
  statisticStationList: IStatisticStation[];
  StatisticSurfaceWaterList: IStatisticSurfaceWater[];
  total: number;
  payload: IStatisticSearch;

  payloadAdvanceDataOfHour: IPayloadGetAdvanceData;
  payloadAdvanceDataOfMinute: IPayloadGetAdvanceData;
  stationFilter: IStationFilter;
  stationFilterForWrongStructure: IStationFilterForWrongStructure;
  advanceData: IResponseGetAdvanceData;

  wrongStructure: IResponseGetWrongStructureData;

  payloadDataSheet: IPayloadDataSheet;
  dataSheet: IResponseGetDataSheet;
}

const initialState: IState = {
  statisticStationList: [],
  StatisticSurfaceWaterList: [],
  total: 0,
  payload: {
    limit: 10,
    page: 1,
    search: '',
    // startTime: '',
    // endTime: '',
  },
  stationFilter: {
    page: 1,
    limit: 10,
    monitoringType: '',
    monitoringGroup: '',
    searchKeyword: '',
    district: '',
  },

  stationFilterForWrongStructure: {
    page: 1,
    limit: 20,
    stationId: '',
    startTime: moment().subtract(1, 'month').utc(true).format(),
    endTime: moment().utc(true).format(),
  },

  payloadAdvanceDataOfHour: {
    dataType: 'HOUR',
    endTime: moment(),
    startTime: moment().subtract(7, 'days'),
    limit: 15,
    page: 1,
    stationId: '',
  },

  payloadAdvanceDataOfMinute: {
    dataType: 'MINUTE',
    endTime: moment(),
    startTime: moment().subtract(7, 'days'),
    limit: 15,
    page: 1,
    stationId: '',
  },

  advanceData: {
    data: [],
    totalData: 0,
  },

  wrongStructure: {
    data: [],
    totalData: 0,
  },

  dataSheet: {
    data: [],
    totalData: 0,
  },

  payloadDataSheet: {
    startTime: moment().subtract(1, 'week'),
    endTime: moment(),
    limit: 15,
    page: 1,
    dataType: 'HOUR',
    indicators: [],
  },
};

const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    changePayload: (state, action: { payload: IStatisticSearch }) => {
      const newPayload = action.payload;
      state.payload = newPayload;
    },

    changeStationFilter: (state, action: { payload: IStationFilter }) => {
      const newStationFilter = action.payload;
      state.stationFilter = newStationFilter;
    },
    changeStationFilterForWrongStructure: (
      state,
      action: { payload: IStationFilterForWrongStructure },
    ) => {
      state.stationFilterForWrongStructure = action.payload;
    },

    changePayloadAdvanceDataOfHour: (state, action: { payload: IPayloadGetAdvanceData }) => {
      state.payloadAdvanceDataOfHour = action.payload;
    },

    changePayloadAdvanceDataOfMinute: (state, action: { payload: IPayloadGetAdvanceData }) => {
      state.payloadAdvanceDataOfMinute = action.payload;
    },

    resetAdvanceData: (state) => {
      state.advanceData = {
        data: [],
        totalData: 0,
      };
    },

    resetResponseWrongStructureData: (state) => {
      state.wrongStructure = {
        data: [],
        totalData: 0,
      };
    },

    changePayloadDataSheet: (state, action: { payload: IPayloadDataSheet }) => {
      state.payloadDataSheet = action.payload;
    },

    resetDataSheet: (state) => {
      state.dataSheet = initialState.dataSheet;
      state.payload = initialState.payloadDataSheet;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatisticStation.fulfilled, (state, action) => {
      const { data, totalData } = action.payload;
      state.statisticStationList = data;
      state.total = totalData;
    });
    builder
      .addCase(getStatisticSurfaceWater.fulfilled, (state, action) => {
        const { data, total } = action.payload;
        state.StatisticSurfaceWaterList = data;
        state.total = total;
      })
      .addCase(getAdvanceDataOfMonitoringData.fulfilled, (state, action) => {
        state.advanceData = action.payload;
      })
      .addCase(getWrongStructure.fulfilled, (state, action) => {
        const { data, totalData } = action.payload;
        state.wrongStructure.data = data;
        state.wrongStructure.totalData = totalData;
      })
      .addCase(getDataSheet.fulfilled, (state, action) => {
        state.dataSheet = action.payload;
      });
  },
});

export const {
  changePayload,
  changeStationFilter,
  changeStationFilterForWrongStructure,
  changePayloadAdvanceDataOfHour,
  resetAdvanceData,
  changePayloadAdvanceDataOfMinute,
  resetResponseWrongStructureData,
  changePayloadDataSheet,
  resetDataSheet,
} = statisticSlice.actions;

export default statisticSlice.reducer;
