import React, { ReactNode } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import { ArrowRightOutlined } from '@mui/icons-material';

import CameraHeader from './camera_header';
import CameraFilter from './camera_filter';
import CameraList from './camera_list';
import { useToggle } from 'src/hooks';
import { useStyles } from './styles';
import theme from 'src/theme';
import CameraPagination from './camera_pagination';
import StationList from './station_list';

interface ICameras {
  children: ReactNode;
  title?: string;
}

export const CameraCompound = ({ children, title }: ICameras) => {
  const classes = useStyles();

  const [toggle, setToggle] = useToggle(true);

  if (!toggle) {
    return (
      <Box className={classes.collapse}>
        <IconButton onClick={setToggle} size="small" sx={{ color: theme.palette.primary.main }}>
          <ArrowRightOutlined fontSize="large" />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <CameraHeader setExpanded={setToggle} title={title} />
        <Box className={classes.body}>{children}</Box>
      </Box>
      <Divider orientation="vertical" />
    </Box>
  );
};

CameraCompound.Filter = CameraFilter;
CameraCompound.List = CameraList;
CameraCompound.StationList = StationList;
CameraCompound.Pagination = CameraPagination;
