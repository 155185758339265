import { createSlice } from '@reduxjs/toolkit';
import { IDocument } from 'src/types/file';
import {
  createFolder,
  deleteFile,
  deleteFolder,
  getFilesByFolderId,
  getFolderStationById,
  getPenaltyFiles,
  renameFile,
  renameFolder,
  uploadFile,
} from './file_actions';

interface IState {
  filesList: { folder: IDocument; files: IDocument[] };
  breadcrumbList: { key: string; name: string }[];
}

const initialState: IState = {
  filesList: {
    folder: <IDocument>{},
    files: [],
  },
  breadcrumbList: [],
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    addBreadcrumb: (state, action) => {
      state.breadcrumbList.push(action.payload);
    },
    removeBreadcrumb: (state, action) => {
      const index = state.breadcrumbList.findIndex((e) => e.key === action.payload.key);
      state.breadcrumbList.splice(index + 1, state.breadcrumbList.length);
    },
    resetBreadcrumb: (state, action) => {
      state.breadcrumbList = [action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolderStationById.fulfilled, (state, action) => {
      state.filesList = action.payload;
    });
    builder.addCase(getFilesByFolderId.fulfilled, (state, action) => {
      state.filesList = action.payload;
    });
    builder.addCase(getPenaltyFiles.fulfilled, (state, action) => {
      state.filesList = action.payload;
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      state.filesList.files.unshift(action.payload);
    });
    builder.addCase(renameFolder.fulfilled, (state, action) => {
      const index = state.filesList.files.findIndex((e) => e.id === action.payload.id);
      if (index !== -1) {
        state.filesList.files.splice(index, 1, action.payload);
      }
    });
    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      state.filesList.files = state.filesList.files.filter((e) => e.id !== action.payload);
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      const files = action.payload.filter((file) => file.id !== null);
      state.filesList.files = files.concat(state.filesList.files);
    });
    builder.addCase(renameFile.fulfilled, (state, action) => {
      const index = state.filesList.files.findIndex((e) => e.id === action.payload.id);
      if (index !== -1) {
        state.filesList.files.splice(index, 1, action.payload);
      }
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.filesList.files = state.filesList.files.filter((e) => e.id !== action.payload);
    });
  },
});

export const { addBreadcrumb, removeBreadcrumb, resetBreadcrumb } = fileSlice.actions;

export default fileSlice.reducer;
