import { createClient } from './axios_client';
import {
  ICamera,
  ICameraConnect,
  ICameraFunction,
  ICameraParams,
  ICameraSearch,
  ICameraLogs,
} from 'src/types/camera';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const cameraApi = {
  getCameras: (params: ICameraSearch) => {
    return client.get<{
      data: ICamera[];
      totalData: number;
    }>('/camera', {
      params,
    });
  },
  createCamera: (data: ICameraConnect) => {
    return client.post<ICamera>('/camera', data);
  },
  getCameraConnect: (id: string) => {
    return client.get<ICameraConnect>(`/camera/${id}/connect`);
  },
  getCameraParams: (id: string) => {
    return client.get<ICameraParams[]>(`/camera/${id}/params`);
  },
  getCameraFunction: (id: string) => {
    return client.get<ICameraFunction>(`/camera/${id}/function`);
  },
  editCameraConnect: (id: string, data: ICameraConnect) => {
    return client.put<ICameraConnect>(`/camera/${id}/connect`, data);
  },
  editCameraParams: (id: string, data: ICameraParams) => {
    return client.put<ICameraParams>(`/camera/${id}/params`, data);
  },
  editCameraFunction: (id: string, data: ICameraFunction) => {
    return client.put<ICameraFunction>(`/camera/${id}/function`, data);
  },
  deleteCamera: (id: string) => {
    return client.delete<string>(`/camera/${id}`);
  },
  getCameraLogs: (
    id: string,
    params: {
      after: string;
      limit: number;
    },
  ) => {
    return client.get<ICameraLogs[]>(`/cameras/${id}/logs`, {
      params,
    });
  },
};
