import React, { useEffect, useState } from 'react';
import { Typography, Box, Grid } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import SwitchWithText from 'src/components/switch_with_text';
import theme from 'src/theme';

import {
  MainNotificationSetting,
  SubNotificationSetting,
  AccordionNotificationSetting,
} from './styles';
import {
  INotificationSource,
  INotINotificationSourceStatus,
  TNotificationSourceItem,
} from 'src/types/notification';
import { useAppDispatch } from 'src/hooks';
import { changeNotificationSource } from 'src/redux_store/notification/notification_action';
import { SOURCE_NOTIFICATION_ITEM } from 'src/constants/notification';

type Props = {
  settingItem: INotificationSource;
  expanded: string | false;
  index: number | string;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
};

function NotificationSettingSourceItem(props: Props) {
  const { settingItem, expanded, index, setExpanded } = props;
  const [status, setStatus] = useState<INotINotificationSourceStatus>({
    emailNotification: false,
    mobileNotification: false,
    sound: false,
    webNotification: false,
    zaloNotification: false,
    title: '',
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    const {
      sound,
      emailNotification,
      mobileNotification,
      webNotification,
      title,
      zaloNotification,
    } = settingItem;

    const cloneSettingItem = {
      sound,
      emailNotification,
      mobileNotification,
      webNotification,
      zaloNotification,
      title,
    };
    for (const key in cloneSettingItem) {
      setStatus((prev) => ({
        ...prev,
        [key]: cloneSettingItem[key as keyof INotINotificationSourceStatus],
      }));
    }

    // if (zaloNotification === undefined) {
    //   const newStatus = { ...status };
    //   delete newStatus.zaloNotification;

    //   setStatus(newStatus);
    // }
  }, []);

  const handleChangeExpanded = () => () => {
    if (expanded === `panel${index}`) {
      setExpanded(false);
    } else {
      setExpanded(`panel${index}`);
    }
  };

  const handleChangeStatusSource = (name: string, checked: boolean) => {
    const newStatus = { ...status, [name]: checked };

    setStatus(newStatus);

    const newSettingItem = { ...settingItem, [name]: checked };

    const payload = {
      data: {
        notificationSource: newSettingItem,
      },
    };

    dispatch(changeNotificationSource(payload));
  };

  const renderSettingItem = () => {
    const { sound, emailNotification, mobileNotification, webNotification, zaloNotification } =
      settingItem;
    const cloneSettingItem = {
      sound,
      emailNotification,
      mobileNotification,
      webNotification,
      zaloNotification,
    };

    const element = [];

    for (const key in cloneSettingItem) {
      const statusSwitch = status[key as keyof INotINotificationSourceStatus];

      console.log(statusSwitch);

      const newKey = key as TNotificationSourceItem;

      if (typeof statusSwitch === 'boolean') {
        const title = SOURCE_NOTIFICATION_ITEM[newKey];

        element.push(
          <Grid key={key} item borderBottom={`2px solid ${theme.palette.secondary.light}`}>
            <SwitchWithText
              title={title}
              statusSwitch={statusSwitch}
              name={key}
              onChange={handleChangeStatusSource}
            />
          </Grid>,
        );
      }
    }

    return element;
  };

  return (
    <AccordionNotificationSetting
      sx={{
        boxShadow: 'none',
        margin: '8px 0px',
      }}
      square
      expanded={expanded === `panel${index}`}
      onChange={handleChangeExpanded()}
    >
      <MainNotificationSetting
        expandIcon={<ExpandMore />}
        aria-controls={`panel${index}a-content`}
        id="panel1a-header"
      >
        <Typography>{settingItem.title}</Typography>
      </MainNotificationSetting>
      <SubNotificationSetting>
        <Box mb={theme.spacing(3)}>
          <Grid container flexDirection="column" spacing={1}>
            {renderSettingItem()}
          </Grid>
        </Box>
      </SubNotificationSetting>
    </AccordionNotificationSetting>
  );
}

export default NotificationSettingSourceItem;
