import { IResponseGetDataSheet } from './../../types/statistic';
import qs from 'query-string';
import {
  IStationFilter,
  IStatisticSearch,
  IStatisticStation,
  IStatisticSurfaceWater,
  IStationFilterForWrongStructure,
  IResponseGetWrongStructureData,
  IPayloadDataSheet,
  ISubmissionFilter,
  ISubmissionResponse,
} from 'src/types/statistic';

import { createClient } from './axios_client';

const client = createClient('http://10.49.46.53:5000/api/v1');
const client1 = createClient('http://10.49.46.33:9003/api/v1');

export const statisticApi = {
  getStatisticStation: (params: IStationFilter) => {
    return client.get<{
      data: IStatisticStation[];
      totalData: number;
    }>(`/report/station_info?${qs.stringify(params)} `);
  },

  exportStation: (params: IStationFilter) => {
    return client.get<any>(`/report/station_info/export?${qs.stringify(params)} `, {
      responseType: 'blob',
    });
  },

  getStatisticSurfaceWater: (params: IStatisticSearch) => {
    return client.get<{
      data: IStatisticSurfaceWater[];
      total: number;
    }>(`/test2?${qs.stringify(params)} `);
  },

  // getWrongStructureData: (params: IStationFilterForWrongStructure) => {
  //   return axios.get<{
  //     data: IStatisticWrongStructure[];
  //     totalData: number;
  //   }>(`wrongStructureData?${qs.stringify(params)}`);
  // },

  // Làm thêm 1 export cho wrong structure data

  // exportWrongStructureData: (params: IStationFilterForWrongStructure) => {
  //   return client.get
  // }

  getWrongStructureData: (data: IStationFilterForWrongStructure) => {
    const params = {
      startTime: data.startTime,
      endTime: data.endTime,
      limit: data.limit,
      page: data.page,
    };

    return client.get<IResponseGetWrongStructureData>(
      `/erroneous_data_logs/${data.stationId}?${qs.stringify(params)}`,
    );
  },

  exportWrongStructureData: (payload: Omit<IStationFilterForWrongStructure, 'page' | 'limit'>) => {
    const params = {
      startTime: payload.startTime,
      endTime: payload.endTime,
    };
    return client.get<any>(
      `/erroneous_data_logs/${payload.stationId}/export?${qs.stringify(params)}`,
      { responseType: 'blob' },
    );
  },

  getDataSheet: ({ stationId, payload }: { payload: IPayloadDataSheet; stationId: string }) => {
    return client1.post<IResponseGetDataSheet>(
      `/monitoring_data/${stationId}/get_indicator_data`,
      payload,
    );
  },

  exportDataSheet: ({
    stationId,
    payload,
  }: {
    payload: Omit<IPayloadDataSheet, 'page' | 'limit'>;
    stationId: string;
  }) => {
    return client1.post<any>(`/monitoring_data/${stationId}/get_indicator_data/export`, payload, {
      responseType: 'blob',
    });
  },

  getSubmissionsData: (params: ISubmissionFilter) =>
    client.get<ISubmissionResponse>('/report/submissions', { params }),
  exportSubmissionsData: (params: ISubmissionFilter) =>
    client.get<Blob>('/report/submissions/export', { params, responseType: 'blob' }),
};
