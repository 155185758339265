import React from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import Loading from '../loading';
import { Box } from '@mui/material';
import config from 'src/config';

interface IProps {
  children: JSX.Element;
}

const MapController = ({ children }: IProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.googleMapApiKey,
  });

  if (!isLoaded) {
    return (
      <Box width="100%">
        <Loading textAlign="center" />
      </Box>
    );
  }

  return children;
};

export default React.memo(MapController);
