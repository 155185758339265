import { IIndicator, IIndicatorChart } from './../../types/indicators';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { stationApi } from 'src/clients/http';
import {
  IStation,
  IStationLogs,
  IStationParams,
  TStationHostUnit,
  TStationIndicators,
  TStationInformation,
  TStationMethod,
} from 'src/types/station';

export const createStationInformation = createAsyncThunk<IStation, TStationInformation>(
  'station/createStationInformation',
  async (data, { rejectWithValue }) => {
    try {
      const res = await stationApi.createStationInformation(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createStationHostUnit = createAsyncThunk<IStation, TStationHostUnit>(
  'station/createStationHostUnit',
  async (data, { rejectWithValue }) => {
    try {
      const res = await stationApi.createStationHostUnit(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createStationMethod = createAsyncThunk<IStation, TStationMethod>(
  'station/createStationMethod',
  async (data, { rejectWithValue }) => {
    try {
      const res = await stationApi.createStationMethod(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createStationIndicators = createAsyncThunk<IStation, Omit<IStation, 'id'>>(
  'station/createStationIndicators',
  async (data, { rejectWithValue }) => {
    try {
      const res = await stationApi.createStationIndicators(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStations = createAsyncThunk<
  {
    data: IStation[];
    totalData: number;
  },
  any
>('station/getStations', async (params, { rejectWithValue }) => {
  try {
    const res = await stationApi.getStations(params);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getStationMap = createAsyncThunk<IStation[], IStationParams>(
  'station/getStationMap',
  async (params, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationMap(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationCameras = createAsyncThunk<any, string>(
  'station/getStationCameras',
  async (stationId, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationCameras(stationId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationLatestData = createAsyncThunk<any, string>(
  'station/getStationLatestData',
  async (stationId, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationLatestData(stationId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationOptions = createAsyncThunk<IStation[], IStationParams>(
  'station/getStationOptions',
  async (params, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationOptions(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationBaseCreateById = createAsyncThunk<IStation, string>(
  'station/getStationBaseCreateById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationBaseCreateById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationInfoById = createAsyncThunk<TStationInformation, string>(
  'station/getStationInfoById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationInfoById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationHostById = createAsyncThunk<TStationHostUnit, string>(
  'station/getStationHostById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationHostById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationMethodById = createAsyncThunk<TStationMethod, string>(
  'station/getStationMethodById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationMethodById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationIndicatorsById = createAsyncThunk<TStationIndicators, string>(
  'station/getStationIndicatorsById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationIndicatorsById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateStationInfo = createAsyncThunk<
  TStationInformation,
  {
    id: string;
    data: TStationInformation;
  }
>('station/updateStationInfo', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await stationApi.updateStationInfo(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateStationHost = createAsyncThunk<
  TStationHostUnit,
  {
    id: string;
    data: TStationHostUnit;
  }
>('station/updateStationHost', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await stationApi.updateStationHost(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateStationMethod = createAsyncThunk<
  TStationMethod,
  {
    id: string;
    data: TStationMethod;
  }
>('station/updateStationMethod', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await stationApi.updateStationMethod(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateStationIndicators = createAsyncThunk<
  TStationIndicators,
  {
    id: string;
    data: TStationIndicators;
  }
>('station/updateStationIndicators', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await stationApi.updateStationIndicators(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteStation = createAsyncThunk<string, string>(
  'station/deleteStation',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.deleteStation(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

// export const getStationInfo = createAsyncThunk<IStationInfo, string>(
//   'station/deleteStation',
//   async (id, { rejectWithValue }) => {
//     try {
//       const res = await stationApi.getStationInfo(id);
//       return res.data;
//     } catch (error: any) {
//       return rejectWithValue(error);
//     }
//   },
// );

export const getStationLogs = createAsyncThunk<
  IStationLogs[],
  {
    id: string;
    payload: {
      after: string;
      limit: number;
    };
  }
>('station/getStationLogs', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const res = await stationApi.getStationLogs(id, payload);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getAllIndicatorOfStation = createAsyncThunk<IIndicator[], string>(
  'station/getAllIndicatorOfStation',
  async (stationId, { rejectWithValue }) => {
    try {
      const res = await stationApi.getAllIndicatorOfStation(stationId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAllIndicatorOfChart = createAsyncThunk<IIndicatorChart[], string>(
  'station/getAllIndicatorOfChart',
  async (stationId, { rejectWithValue }) => {
    try {
      const res = await stationApi.getAllIndicatorOfChart(stationId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
