import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Crop169Outlined, MapOutlined } from '@mui/icons-material';

import store from 'src/redux_store';
import { toggleTheaterMode } from 'src/redux_store/stream/stream_slice';
import { IPlayerProps } from 'src/components/player_hls/types/player';

const dispatch = store.dispatch;

function TheaterMode(props: any) {
  const slice = { ...props } as IPlayerProps;

  const controlText = !slice.isTheaterMode ? 'Chế độ rạp phim' : 'Thoát chế độ rạp phim';

  const handleClick = () => {
    dispatch(toggleTheaterMode());
  };

  if (slice.cam.classifiedListId || slice.cam.position) return null;

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top-start"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          {slice.isTheaterMode ? <MapOutlined /> : <Crop169Outlined />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default TheaterMode;
