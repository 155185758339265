import { IFilter } from 'src/types/common';
import { createClient } from './axios_client';

const client = createClient('https://qamt.danateq.vn/api/v1');
// const client = createClient('http://10.49.46.53:5000/api/v1');

export const filterApi = {
  getAllFilter: (query: string) => {
    return client.get<IFilter>(`/public/filter${query ? '?monitoringType=' + query : ''}`);
  },
  getFilterByMonitoringType: (monitoringType: string) => {
    return client.get<IFilter>(`/public/filter/${monitoringType}`);
  },
};
