import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.paper,
  },
}));
