import { useEffect, useState } from 'react';
import { signaling } from 'src/components/player_rtc/controller/signaling';

export const useSignaling = () => {
  const [wsOpen, setWsOpen] = useState(false);

  useEffect(() => {
    signaling.connect(false);
    if (signaling.ws) {
      signaling.ws.onopen = () => {
        setWsOpen(true);
        signaling.wsAuth();
      };
    }

    return () => {
      signaling.close();
      setWsOpen(false);
    };
  }, []);

  return wsOpen;
};
