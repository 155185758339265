import React, { useState } from 'react';

import moment from 'moment';
import TablePagination from 'src/components/table_pagination';
import TableLayout from 'src/layout/table_layout';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getData } from 'src/redux_store/data/data_actions';
import { changePayload, resetDataList } from 'src/redux_store/data/data_slice';
import { getAllIndicatorOfStation } from 'src/redux_store/station/station_action';
import { IPayloadGetData } from 'src/types/data';
import { IIndicator } from 'src/types/indicators';
import { toastMessage } from 'src/utils/toast';
import DataFilter from './data_filter/index';
import DataTable from './data_table/index';
import { formatTimeToUTC } from 'src/utils/function';

const DataManagement = () => {
  const [indicatorColumn, setIndicatorColumn] = useState<IIndicator[]>([]);
  const { payloadData, dataList } = useAppSelector((state) => state.dataSlice);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(resetDataList());
      dispatch(
        changePayload({
          dataType: 'MINUTE',
          startTime: moment().subtract(7, 'days'),
          endTime: moment(),
          limit: 20,
          page: 1,
          stationId: '',
        }),
      );
    };
  }, []);

  const getDataAndChangePayload = async (newPayload: IPayloadGetData) => {
    try {
      dispatch(getData(newPayload));
      dispatch(changePayload(newPayload));
    } catch (error) {
      toastMessage.error('Lỗi hệ thống');
    }
  };

  const getDataFilter = (filter: { stationId: string; startTime: string; endTime: string }) => {
    const stationId = filter.stationId;
    if (stationId !== payloadData.stationId) {
      dispatch(getAllIndicatorOfStation(stationId))
        .unwrap()
        .then((data) => {
          setIndicatorColumn(data);
        });
    }
    if (!stationId) return;
    const newPayload: IPayloadGetData = {
      ...payloadData,
      stationId: stationId,
      startTime: formatTimeToUTC(filter.startTime),
      endTime: formatTimeToUTC(filter.endTime),
      page: 1,
    };
    getDataAndChangePayload(newPayload);
  };
  const handleChangePage = (newPage: number) => {
    const newPayload: IPayloadGetData = {
      ...payloadData,
      page: newPage,
    };
    getDataAndChangePayload(newPayload);
  };

  return (
    <TableLayout
      isShowAllDetail={false}
      isResize
      filterPanel={<DataFilter getDataFilter={getDataFilter} />}
      tablePanel={<DataTable indicatorColumn={indicatorColumn} />}
      detailPanel={<></>}
      tablePaginationPanel={
        <TablePagination
          page={payloadData.page || 1}
          totalPage={Math.ceil(dataList.totalData / payloadData.limit)}
          totalData={dataList.totalData}
          handleChangePage={(page) => {
            handleChangePage(page);
          }}
        />
      }
      detailActionsPanel={null}
      isOpenDetailPanel={false}
    />
  );
};

export default DataManagement;
