import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from 'src/clients/http/post_api';
import { IPost, IPostSearch } from 'src/types/post';
import { toastMessage } from 'src/utils/toast';

export const getAllPosts = createAsyncThunk<
  {
    data: IPost[];
    totalData: number;
  },
  IPostSearch
>('post/getAllPosts', async (params, { rejectWithValue }) => {
  try {
    const { data } = await postApi.getAllPosts(params);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});

export const addNewPosts = createAsyncThunk<IPost, Omit<IPost, 'id' | 'createdAt' | 'updatedAt'>>(
  'news/addNewPosts',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postApi.addNewPosts(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const getPostById = createAsyncThunk<IPost, string>(
  'news/getPostById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await postApi.getPostById(id);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const deletePostById = createAsyncThunk<string, string>(
  'news/deletePostById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await postApi.deletePostById(id);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const editPostById = createAsyncThunk<IPost, IPost>(
  'news/editPostById',
  async (newData, { rejectWithValue }) => {
    try {
      const { data } = await postApi.editPostById(newData);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const uploadImage = createAsyncThunk<{ id: string; path: string }[], FormData>(
  'news/uploadImage',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await postApi.uploadImage(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);
