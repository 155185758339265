import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    height: '100%',
  },
}));
