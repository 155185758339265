import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';

import StationItem from 'src/components/card/station_item';
import ErrorMessage from 'src/components/error_message';
import Loading from 'src/components/loading';
import { IStation } from 'src/types/station';

interface IStationList {
  isLoading?: boolean;
  isError?: boolean;
  stations: IStation[];
  selectedId?: string;
  icon?: ReactNode;
  onRefresh?: () => void;
  selectStation?: (station: IStation) => void;
}

const StationList = ({
  isLoading,
  isError,
  stations,
  selectStation,
  onRefresh,
  selectedId,
  icon,
}: IStationList) => {
  const renderCamera = () => {
    if (isLoading) {
      return (
        <Box width="100%">
          <Loading />
        </Box>
      );
    }

    if (isError) {
      return <ErrorMessage sx={{ flex: 1 }} textAlign="center" onClick={onRefresh} />;
    }

    return (
      <Scrollbars>
        <Box pl={1} pr={1.5}>
          {stations.length === 0 ? (
            <Box>
              <Typography textAlign="center">Không có dữ liệu!</Typography>
            </Box>
          ) : (
            <Box>
              {stations.map((station, index) => {
                return (
                  <StationItem
                    key={index}
                    selectedId={selectedId}
                    selectStation={selectStation}
                    station={station}
                    icon={icon}
                  />
                );
              })}
            </Box>
          )}
        </Box>
      </Scrollbars>
    );
  };

  return (
    <Box display="flex" flex="1">
      {renderCamera()}
    </Box>
  );
};

export default StationList;
