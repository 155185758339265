import {
  IDefaultThresholdStation,
  IFormIndicator,
  IIndicator,
  IIndicatorParams,
  IMonitoringGroup,
  IMonitoringType,
} from 'src/types/indicators';
import { createClient } from './axios_client';

const client = createClient('http://10.49.46.53:5000/api/v1');

export const indicatorsApi = {
  getMonitoringTypes: () => {
    return client.get<IMonitoringType[]>('/monitoring_types');
  },
  getMonitoringTypeById: (id: string) => {
    return client.get<IMonitoringType>(`/monitoring_types/${id}`);
  },
  getMonitoringTypeOptions: () => {
    return client.get<IMonitoringType[]>('/monitoring_types/options');
  },
  getMonitoringGroups: (params: IIndicatorParams) => {
    return client.get<{
      data: IMonitoringGroup[];
      totalData: number;
    }>('/monitoring_group', {
      params,
    });
  },
  getMonitoringGroupOptions: (params: IIndicatorParams) => {
    return client.get<IMonitoringGroup[]>('/monitoring_group/options', {
      params,
    });
  },
  getMonitoringGroupById: (id: string) => {
    return client.get<IMonitoringGroup>(`/monitoring_group/${id}`);
  },
  getIndicators: (params: IIndicatorParams) => {
    return client.get<{
      data: IIndicator[];
      totalData: number;
    }>('/indicators', {
      params,
    });
  },
  getIndicatorOptions: (params: IIndicatorParams) => {
    return client.get<IIndicator[]>('/indicators/options', {
      params,
    });
  },
  getIndicatorInformationById: (id: string) => {
    return client.get<IIndicator>(`/indicators/${id}/information`);
  },

  getIndicatorThresholdById: (id: string) => {
    return client.get<IIndicator>(`/indicators/${id}/threshold`);
  },

  createMonitoringType: (data: Pick<IFormIndicator, 'name' | 'description' | 'symbol'>) => {
    return client.post<IMonitoringType>('/monitoring_types', data);
  },

  updateMonitoringType: (
    id: string,
    data: Pick<IFormIndicator, 'name' | 'description' | 'symbol'>,
  ) => {
    return client.put<IMonitoringType>(`/monitoring_types/${id}`, data);
  },

  deleteMonitoringType: (id: string) => {
    return client.delete<string>(`/monitoring_types/${id}`);
  },

  deleteMonitoringTypeArchive: (id: string) => {
    return client.delete<string>(`/monitoring_types/${id}/archive`);
  },

  createMonitoringGroup: (
    data: Pick<IFormIndicator, 'name' | 'description' | 'symbol' | 'monitoringTypeId'>,
  ) => {
    return client.post<IMonitoringGroup>('/monitoring_group', data);
  },

  updateMonitoringGroup: (
    id: string,
    data: Pick<IFormIndicator, 'name' | 'description' | 'symbol' | 'monitoringTypeId'>,
  ) => {
    return client.put<IMonitoringGroup>(`/monitoring_group/${id}`, data);
  },

  deleteMonitoringGroup: (id: string) => {
    return client.delete<string>(`/monitoring_group/${id}`);
  },

  deleteMonitoringGroupArchive: (id: string) => {
    return client.delete<string>(`/monitoring_group/${id}/archive`);
  },

  createIndicator: (data: IFormIndicator) => {
    return client.post<IIndicator>(`/indicators`, data);
  },

  createIndicatorInfo: (data: Omit<IFormIndicator, 'thresholds'>) => {
    return client.post<IIndicator>(`/indicators/information`, data);
  },

  updateIndicatorInformation: (id: string, data: IFormIndicator) => {
    return client.put<IIndicator>(`/indicators/${id}/information`, data);
  },

  updateIndicatorThreshold: (id: string, data: IFormIndicator) => {
    return client.put<IIndicator>(`/indicators/${id}/threshold`, data);
  },

  deleteIndicator: (id: string) => {
    return client.delete<string>(`/indicators/${id}`);
  },

  deleteIndicatorArchive: (id: string) => {
    return client.delete<string>(`/indicators/${id}/archive`);
  },

  getDefaultThresholdStation: (indicatorId: string, monitoringGroupId: string) => {
    return client.get<IDefaultThresholdStation>('/indicators/threshold_station', {
      params: {
        indicatorId,
        monitoringGroupId,
      },
    });
  },
};
