import { monitoringDataApi } from './../../clients/http/monitoring_data_api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { statisticApi } from 'src/clients/http/statistic_api';
import {
  IStatisticStation,
  IStatisticSearch,
  IStatisticSurfaceWater,
  IStationFilter,
  IResponseGetAdvanceData,
  IStationFilterForWrongStructure,
  IResponseGetDataSheet,
  IPayloadGetAdvanceData,
  IResponseGetWrongStructureData,
  IPayloadDataSheet,
} from 'src/types/statistic';
import { toastMessage } from 'src/utils/toast';
import { saveAs } from 'file-saver';

export const getStatisticStation = createAsyncThunk<
  {
    data: IStatisticStation[];
    totalData: number;
  },
  IStationFilter
>('statistic/getStatisticStation', async (params, { rejectWithValue }) => {
  try {
    const { data } = await statisticApi.getStatisticStation(params);
    return data;
  } catch (error: any) {
    console.log(error);
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});

export const getStatisticSurfaceWater = createAsyncThunk<
  {
    total: number;
    data: IStatisticSurfaceWater[];
  },
  IStatisticSearch
>('statistic/getStatisticSurfaceWater', async (params, { rejectWithValue }) => {
  try {
    const { data } = await statisticApi.getStatisticSurfaceWater(params);
    return data;
  } catch (error: any) {
    console.log(error);
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});

export const exportStation = createAsyncThunk<any, IStationFilter>(
  'statistic/exportStation',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await statisticApi.exportStation(params);
      return data;
    } catch (error: any) {
      console.log(error);
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const getAdvanceDataOfMonitoringData = createAsyncThunk<
  IResponseGetAdvanceData,
  IPayloadGetAdvanceData
>('statistic/getAdvanceDataOfMonitoringData', async (params, { rejectWithValue }) => {
  try {
    const { data } = await monitoringDataApi.getData(params);
    return data;
  } catch (error: any) {
    console.log(error);
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});

export const exportDataAdvanceMonitoringData = createAsyncThunk<
  any,
  Omit<IPayloadGetAdvanceData, 'page' | 'limit'>
>('statistic/exportDataAdvanceMonitoringData', async (payload, { rejectWithValue }) => {
  try {
    const data = await monitoringDataApi.exportData(payload);
    const dataType = payload.dataType === 'HOUR' ? 'gio' : 'phut';
    const blob = new Blob([data.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const filename = `baocao_theo ${dataType}_${payload.stationId}.xlsx`;

    saveAs(blob, filename);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});

// export const getWrongStructure = createAsyncThunk<
//   {
//     data: IStatisticWrongStructure[];
//     totalData: number;
//   },
//   IStationFilterForWrongStructure
// >('statistic/getWrongStructureData', async (params, { rejectWithValue }) => {
//   try {
//     const { data } = await statisticApi.getWrongStructureData(params);
//     return data;
//   } catch (error: any) {
//     console.log(error);
//     toastMessage.error(error?.message || 'Lỗi hệ thống ');
//     return rejectWithValue(error);
//   }
// });

export const getWrongStructure = createAsyncThunk<
  IResponseGetWrongStructureData,
  IStationFilterForWrongStructure
>('statistic/getWrongStructureData', async (data, { rejectWithValue }) => {
  try {
    const response = await statisticApi.getWrongStructureData(data);
    return response.data;
  } catch (error: any) {
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});

//dataSheet
export const getDataSheet = createAsyncThunk<
  IResponseGetDataSheet,
  { stationId: string; payload: IPayloadDataSheet }
>('statistic/getDataSheet', async (payload, { rejectWithValue }) => {
  try {
    const response = await statisticApi.getDataSheet(payload);
    return response.data;
  } catch (error: any) {
    console.log(error);
    toastMessage.error(error?.message || 'Lỗi hệ thống ');
    return rejectWithValue(error);
  }
});
export const exportDataSheet = createAsyncThunk<
  any,
  { stationId: string; payload: Omit<IPayloadDataSheet, 'page' | 'limit'> }
>('statistic/exportDataSheet', async (params, { rejectWithValue }) => {
  try {
    const { payload, stationId } = params;
    const data = await statisticApi.exportDataSheet(params);
    const dataType = payload.dataType === 'HOUR' ? 'gio' : 'ngay';
    const blob = new Blob([data.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const filename = `baocao_bieumau_theo ${dataType}_${stationId}.xlsx`;

    saveAs(blob, filename);
    return data;
  } catch (error: any) {
    toastMessage.error(error?.message);
    return rejectWithValue(error);
  }
});
