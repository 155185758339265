import React from 'react';
import { Grid } from '@mui/material';
import { Control, useFieldArray } from 'react-hook-form';
import { FormInput, FormSelect } from 'src/components/hook_form';
import { IRTSPLink } from 'src/types/camera';
import { useAppDispatch } from 'src/redux_store';
import { IStation } from 'src/types/station';
import { getStationOptions } from 'src/redux_store/station/station_action';
import { useGetStatus } from 'src/hooks';
import Loading from 'src/components/loading';

interface IProps {
  control: Control<any, any>;
  isSharedLink?: boolean;
  disabled?: boolean;
  isEdit?: boolean;
  isLoading?: boolean;
}

export const CameraConnectForm = ({
  control,
  isSharedLink = false,
  disabled,
  isEdit = false,
  isLoading = false,
}: IProps) => {
  const { fields } = useFieldArray<{ rtspLinks: IRTSPLink[] }>({
    name: 'rtspLinks',
    control,
  });

  const [station, setStation] = React.useState<IStation[]>([]);
  const [loading] = useGetStatus('station', 'getStationOptions');

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getStationOptions({}))
      .unwrap()
      .then((data) => setStation(data));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormInput disabled={disabled} control={control} name="name" label="Tên camera" />
      </Grid>
      <Grid item xs={12}>
        <FormSelect
          disabled={disabled}
          loading={loading}
          options={station}
          labelOption="name"
          keyOption="id"
          control={control}
          name="stationId"
          label="Trạm"
        />
      </Grid>
      {isSharedLink ? (
        <Grid item xs={12}>
          {fields.map((item, index) => {
            return (
              <FormInput
                disabled={disabled}
                control={control}
                name={`rtspLinks.${index}.rtspLink`}
                label="Link rtsp"
                key={item.id}
              />
            );
          })}
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <FormInput
              disabled={isEdit || disabled}
              control={control}
              name="ip"
              label="Địa chỉ ip"
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={isEdit || disabled}
                control={control}
                name="portOnvif"
                label="Port onvif"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                disabled={isEdit || disabled}
                control={control}
                name="portRtsp"
                label="Port rtsp"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormInput
              disabled={isEdit || disabled}
              control={control}
              name="user"
              label="Tên đăng nhập"
            />
          </Grid>
          <Grid item xs={12}>
            <FormInput
              type="password"
              disabled={isEdit || disabled}
              control={control}
              name="pass"
              label="Mật khẩu"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
