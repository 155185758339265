import {
  IGetDataIndicatorOfStationPayload,
  IResponseGetDataIndicatorOfStation,
} from 'src/types/monitoring_data';
import { IPayloadGetAdvanceData, IResponseGetAdvanceData } from './../../types/statistic';
import { createClient } from './axios_client';
const client = createClient('https://qamt.danateq.vn/api/v1');

export const monitoringDataApi = {
  getData: (payload: IPayloadGetAdvanceData) => {
    const newPayload = {
      dataType: payload.dataType,
      startTime: payload.startTime,
      endTime: payload.endTime,
      limit: payload.limit,
      page: payload.page,
    };
    return client.post<IResponseGetAdvanceData>(
      `/monitoring_data/${payload.stationId}`,
      newPayload,
    );
  },

  exportData: (payload: Omit<IPayloadGetAdvanceData, 'page' | 'limit'>) => {
    const newPayload = {
      dataType: payload.dataType,
      startTime: payload.startTime,
      endTime: payload.endTime,
    };
    return client.post<any>(`/monitoring_data/${payload.stationId}/export`, newPayload, {
      responseType: 'blob',
    });
  },

  getDataIndicatorOfStation: ({
    payload,
    params,
  }: {
    payload: IGetDataIndicatorOfStationPayload;
    params: {
      stationId: string;
      indicatorSymbol: string;
    };
  }) => {
    return client.post<IResponseGetDataIndicatorOfStation[]>(
      `/monitoring_data/${params.stationId}/indicator/${params.indicatorSymbol}`,
      payload,
    );
  },
};
