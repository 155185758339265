import apiSlice from './api/api_slice';
import cameraSlice from './camera/camera_slice';
import modalSlice from './common/modal_slice';
import indicatorsSlice from './indicators/indicators_slice';
import ptzSlice from './ptz/ptz_slice';
import stationSlice from './station/station_slice';
import roleSlice from './role/role_slice';
import userSlice from './user/user_slice';
import myAccountSlice from './my_account/my_account_slice';
import autoSampleSlice from './sample/sample_slice';
import myDeviceSlice from './my_device/my_device_slice';
import penaltySlice from './penalty/penalty_slice';
import videoSlice from './video/video_slice';
import latestDataSlice from './latest_data/latest_data_slice';
import dataSlice from './data/data_slice';
import watchlistSlice from './watchlist/watchlist_slice';
import streamSlice from './stream/stream_slice';
import postSlice from './post/post_slice';
import topBarSlice from './common/topbar/topbar_slice';
import notificationSlice from './notification/notification_slice';
import stationChartSlice from './station_chart/station_chart_slice';
import statisticSlice from './statistic/statistic_slice';
import apisManagementSlice from './apis_management/apis_management_slice';
import fileSlice from './file/file_slide';
import userLogsSlice from './user_log/user_log_slice';
import systemSettingSlice from './system_setting/system_setting_slice';

export const reducer = {
  modalSlice,
  indicatorsSlice,
  cameraSlice,
  ptzSlice,
  stationSlice,
  roleSlice,
  userSlice,
  myAccountSlice,
  autoSampleSlice,
  latestDataSlice,
  watchlistSlice,
  streamSlice,
  postSlice,
  topBarSlice,
  notificationSlice,
  myDeviceSlice,
  dataSlice,
  stationChartSlice,
  penaltySlice,
  statisticSlice,
  apisManagementSlice,
  fileSlice,
  videoSlice,
  userLogsSlice,
  systemSettingSlice,
  // last reducer require
  apiSlice,
};
