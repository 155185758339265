import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Drawer, FormControl, Grid, IconButton, List, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'src/components/hook_form';
import { FormDateTimePicker } from 'src/components/hook_form/form_datetimepicker';
import { useAppDispatch } from 'src/redux_store';
import { getAllFilter } from 'src/redux_store/indicators/indicators_action';
import { getStationOptions } from 'src/redux_store/station/station_action';
import { toastMessage } from 'src/utils/toast';
import { useStyles } from './styles';
import config from 'src/config';

interface IFiterOption {
  id: string;
  name: string;
}

type TForm =
  | 'startTime'
  | 'endTime'
  | 'monitoringType'
  | 'monitoringGroup'
  | 'stationId'
  | 'district';

type TProps = {
  getDataFilter: (newFilter: any) => void;
  window?: () => Window;
};

const ALL_DEFAULT = [{ id: 'ALL', name: 'Tất cả' }];
const drawerWidth = 400;
const DataFilter = (props: TProps) => {
  const { window } = props;
  const { getDataFilter } = props;

  const [monitoringTypeOptions, setMonitoringTypeOptions] = useState<IFiterOption[]>(ALL_DEFAULT);
  const [monitoringGroupOptions, setMonitoringGroupOptions] = useState<IFiterOption[]>(ALL_DEFAULT);
  const [districtOptions, setDistrictOptions] = useState<IFiterOption[]>(ALL_DEFAULT);
  const [stationOptions, setStationOptions] = useState<IFiterOption[]>([]);

  const { control, handleSubmit, setValue, getValues } = useForm<any>({
    defaultValues: {
      startTime: moment().subtract(7, 'days'),
      endTime: moment().format(),
      monitoringType: 'ALL',
      monitoringGroup: 'ALL',
      stationId: '',
      district: 'ALL',
    },
  });

  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllFilter(''))
      .unwrap()
      .then((data) => {
        const { monitoringType, district, monitoringGroup } = data;

        getStationOptionAndSetValueFilter('', '', '', true);

        setMonitoringTypeOptions((prev) => [...prev, ...monitoringType]);
        setMonitoringGroupOptions((prev) => [...prev, ...monitoringGroup]);
        setDistrictOptions((prev) => [...prev, ...district]);
      });
  }, []);

  const getStationOptionAndSetValueFilter = (
    monitoringTypeId: string,
    monitoringGroupId: string,
    districtId: string,

    isGetData: boolean,
  ) => {
    dispatch(
      getStationOptions({
        district: districtId,
        monitoringGroup: monitoringGroupId,
        monitoringType: monitoringTypeId,
      }),
    )
      .unwrap()
      .then((station) => {
        const stationId = station[0]?.id || '';

        const { startTime, endTime } = getValues();
        if (isGetData) {
          getDataFilter({ stationId, startTime, endTime });
        }
        setValue('stationId', stationId);
        setStationOptions(station);
      });
  };

  const returnValueOfAll = (value: 'ALL' | string) => {
    if (value === 'ALL') return '';
    return value;
  };

  const getFullOptionByMonitoringType = (monitoringTypeId: string, isGetData = false) => {
    dispatch(getAllFilter(monitoringTypeId))
      .unwrap()
      .then((data) => {
        const { district, monitoringGroup } = data;

        const monitoringGroupId = 'ALL';
        const districtId = 'ALL';

        const fieldList: { fieldName: TForm; fieldValue: string }[] = [
          {
            fieldName: 'monitoringGroup',
            fieldValue: monitoringGroupId,
          },
          { fieldName: 'district', fieldValue: districtId },
        ];

        setValueAndOptionsFilter(fieldList);

        setMonitoringGroupOptions([...ALL_DEFAULT, ...monitoringGroup]);
        setDistrictOptions([...ALL_DEFAULT, ...district]);

        getStationOptionAndSetValueFilter(
          returnValueOfAll(monitoringTypeId),
          returnValueOfAll(monitoringGroupId),
          returnValueOfAll(districtId),
          isGetData,
        );
      });
  };

  const setValueAndOptionsFilter = (fieldSetList: { fieldName: TForm; fieldValue: string }[]) => {
    if (!fieldSetList.length) return;

    fieldSetList.forEach((field) => {
      setValue(field.fieldName, field.fieldValue);
    });
  };

  const handleFilter = (name: string, value: string) => {
    const {
      monitoringGroup: monitoringGroupId,
      district: districtId,
      monitoringType: monitoringTypeId,
    } = getValues();

    const newValue = value === 'ALL' ? '' : value;

    const filterStation = {
      monitoringGroup: returnValueOfAll(monitoringGroupId),
      monitoringType: returnValueOfAll(monitoringTypeId),
      district: returnValueOfAll(districtId),
    };

    if (name === 'monitoringGroup') {
      filterStation.monitoringGroup = newValue;
    }

    if (name === 'district') {
      filterStation.district = newValue;
    }

    getStationOptionAndSetValueFilter(
      filterStation.monitoringType,
      filterStation.monitoringGroup,
      filterStation.district,
      false,
    );
  };

  const handleChangeMonitoringType = (name: string, value: string) => {
    const newValue = value === 'ALL' ? '' : value;
    getFullOptionByMonitoringType(newValue);
  };

  const onSubmit = (data: any) => {
    if (!data.stationId) {
      toastMessage.error('Vui lòng chọn trạm quan trắc trước khi tìm kiếm');
    }

    getDataFilter({ stationId: data.stationId, startTime: data.startTime, endTime: data.endTime });
    setMobileOpen(false);
  };
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <Box>
      <List
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            borderBottom: '1px solid #000',
            width: '100%',
          }}
        >
          <Typography variant="h4" pl={5} sx={{ display: 'inline-block' }}>
            Lọc
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ float: 'right' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 320 }}>
            <FormSelect
              keyOption="id"
              name="monitoringType"
              control={control}
              label="Loại quan trắc"
              labelOption="name"
              options={monitoringTypeOptions}
              handleChange={handleChangeMonitoringType}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 320 }}>
            <FormSelect
              keyOption="id"
              name="monitoringGroup"
              control={control}
              label="Nhóm quan trắc"
              labelOption="name"
              options={monitoringGroupOptions}
              handleChange={handleFilter}
            />
          </FormControl>
        </Box>
        {config.systemName === 'STNMT' && (
          <Box>
            <FormControl sx={{ m: 1, minWidth: 320 }}>
              <FormSelect
                keyOption="id"
                name="district"
                control={control}
                label="Quận, huyên"
                labelOption="name"
                options={districtOptions}
                handleChange={handleFilter}
              />
            </FormControl>
          </Box>
        )}

        <Box>
          <FormControl sx={{ m: 1, minWidth: 320 }}>
            <FormSelect
              keyOption="id"
              name="stationId"
              control={control}
              label="Trạm quan trắc"
              labelOption="name"
              options={stationOptions}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 320 }}>
            <FormDateTimePicker
              control={control}
              name="startTime"
              label="Thời gian bắt đầu"
              size="small"
              minutesStep={60}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, minWidth: 320 }}>
            <FormDateTimePicker
              control={control}
              name="endTime"
              label="Thời gian kết thúc"
              size="small"
              minutesStep={60}
            />
          </FormControl>
        </Box>
        <Box sx={{ mt: 2 }}>
          <LoadingButton type="submit" variant="contained" startIcon={<SearchOutlinedIcon />}>
            Tìm kiếm
          </LoadingButton>
        </Box>
      </List>
    </Box>
  );
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.boxFilter}
        sx={{ display: { xs: 'none', lg: 'flex' } }}
      >
        <Grid container justifyContent="flex-end" alignItems="center" columnSpacing={1}>
          <Grid item md={1.5}>
            <FormSelect
              keyOption="id"
              name="monitoringType"
              control={control}
              label="Loại quan trắc"
              labelOption="name"
              options={monitoringTypeOptions}
              handleChange={handleChangeMonitoringType}
            />
          </Grid>
          <Grid item md={1.5}>
            <FormSelect
              keyOption="id"
              name="monitoringGroup"
              control={control}
              label="Nhóm quan trắc"
              labelOption="name"
              options={monitoringGroupOptions}
              handleChange={handleFilter}
            />
          </Grid>
          {config.systemName === 'STNMT' && (
            <Grid item md={1.5}>
              <FormSelect
                keyOption="id"
                name="district"
                control={control}
                label="Quận, huyên"
                labelOption="name"
                options={districtOptions}
                handleChange={handleFilter}
              />
            </Grid>
          )}
          <Grid item md={1.5}>
            <FormSelect
              keyOption="id"
              name="stationId"
              control={control}
              label="Trạm quan trắc"
              labelOption="name"
              options={stationOptions}
            />
          </Grid>
          <Grid item md={1.5}>
            <FormDateTimePicker
              control={control}
              name="startTime"
              label="Thời gian bắt đầu"
              size="small"
              minutesStep={60}
            />
          </Grid>

          <Grid item md={1.5}>
            <FormDateTimePicker
              control={control}
              name="endTime"
              label="Thời gian kết thúc"
              size="small"
              minutesStep={60}
            />
          </Grid>

          <Grid item>
            <LoadingButton type="submit" variant="contained" startIcon={<SearchOutlinedIcon />}>
              Tìm kiếm
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ float: 'right', display: { xs: 'block', lg: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { lg: 'block', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default DataFilter;
