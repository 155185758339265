import { createSlice } from '@reduxjs/toolkit';
import { IPagination } from 'src/types/common';
import { IPenalty, IPenaltyStatistical } from 'src/types/penalty';
import {
  getBackupPenalty,
  getPenalties,
  getStatistical,
  sendMinutes,
  updatePenalty,
} from './penalty_action';

interface IPenaltySlice {
  penaltyDetail: IPenalty | null;
  penaltyId: string;
  penaltyData: IPagination<IPenalty>;
  statistical: {
    data: IPenaltyStatistical[];
  };
  penaltyBackupData: IPagination<IPenalty>;
}

const initialState: IPenaltySlice = {
  penaltyData: { data: [], totalData: 0 },
  penaltyBackupData: { data: [], totalData: 0 },
  penaltyId: '',
  penaltyDetail: null,
  statistical: {
    data: [],
  },
};

const penaltySlice = createSlice({
  name: 'penalty',
  initialState,
  reducers: {
    selectPenalty: (state, action: { payload: { id: string; isBackup?: boolean } }) => {
      const { isBackup, id } = action.payload;
      const key = isBackup ? 'penaltyBackupData' : 'penaltyData';

      state.penaltyId = id;
      state.penaltyDetail = { ...state[key]?.data.filter((item) => item.id === id)[0] } || null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPenalties.fulfilled, (state, action) => {
        state.penaltyData = action.payload;
        state.penaltyDetail = null;
        state.penaltyId = '';
      })
      .addCase(updatePenalty.fulfilled, (state, action) => {
        const { id, data } = action.payload;
        const index = state.penaltyData.data.findIndex((item) => item.id === id);

        state.penaltyData.data[index] = { ...state.penaltyData.data[index], ...data };

        if (state.penaltyDetail) {
          state.penaltyDetail = { ...state.penaltyDetail, ...data };
        }
      })
      .addCase(getStatistical.fulfilled, (state, action) => {
        state.statistical.data = action.payload.data;
      })
      .addCase(getBackupPenalty.fulfilled, (state, action) => {
        state.penaltyBackupData = action.payload;
      })
      .addCase(sendMinutes.fulfilled, (state, action) => {
        const index = state.penaltyData.data.findIndex((item) => item.id === action.payload.id);

        if (index !== -1) {
          state.penaltyData.data[index].lastRecordedAt = new Date();
        }
      });
  },
});

const { actions, reducer } = penaltySlice;

export const { selectPenalty } = actions;
export default reducer;
