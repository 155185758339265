export const CModalIds = {
  createMonitoringType: 'createMonitoringType',
  deleteMonitoringType: 'deleteMonitoringType',
  createMonitoringGroup: 'createMonitoringGroup',
  deleteMonitoringGroup: 'deleteMonitoringGroup',
  createIndicator: 'createIndicator',
  deleteIndicator: 'deleteIndicator',
  updateIndicator: 'updateIndicator',

  createStation: 'createStation',
  deleteStation: 'deleteStation',

  addRole: 'addRole',
  deleteRole: 'deleteRole',
  addUser: 'addUser',
  deleteUser: 'deleteUser',

  verify_password: 'verify_password',

  logout: 'logout',
  changePassword: 'change_password',

  deleteHistorySample: 'deleteHistorySample',
  addSample: 'addSample',
  deleteSample: 'deleteSample',
  settingShowLatestData: 'settingShowLatestData',

  getUser: 'getUser',

  changePasswordForMyAccount: 'changePasswordForMyAccount',
  changePhoneForMyAccount: 'changePhoneForMyAccount',
  changeNameForMyAccount: 'changeNameForMyAccount',

  addFollowGroup: 'addFollowGroup',

  deletePost: 'deletePost',
  notificationSetting: 'notificationSetting',
  deleteFollowGroup: 'deleteFollowGroup',

  deleteCamera: 'deleteCamera',

  addGroup: 'addGroup',
  deleteGroup: 'deleteGroup',
  updateGroup: 'updateGroup',
  addRequest: 'addRequest',
  deleteRequest: 'deleteRequest',
  updateRequest: 'updateRequest',

  createFileDocument: 'createFileDocument',
  renameFileDocument: 'renameFileDocument',
  deleteFileDocument: 'deleteFileDocument',

  deletePenalty: 'deletePenalty',

  player: {
    exportVideo: 'exportVideo',
  },

  deleteData: 'deleteData',

  noteIndicator: 'noteIndicator',

  report: 'report',
  deleteUserLog: 'deleteUserLog',
};
