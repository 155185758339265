import { Box, Typography } from '@mui/material';
import React from 'react';
import DialogWrapper from 'src/components/modal/dialog_wrapper';
import { CModalIds } from 'src/constants';
import theme from 'src/theme';
import normalImage from 'src/assets/images/normal.png';
import dangerImage from 'src/assets/images/danger.png';
import { useStyles } from './styles';
import Scrollbars from 'react-custom-scrollbars-2';
import { isSiTechSystem } from 'src/utils/function';

const listColorIndicator: { color: string; title: string }[] = [
  { color: theme.palette.error.main, title: 'Chỉ số vượt ngưỡng' },
  {
    color: theme.palette.success.main,
    title: 'Chỉ số bình thường hoặc chỉ số không vượt ngưỡng',
  },
  {
    color: theme.palette.warning.main,
    title: 'Chỉ số truyền về sai đơn vị',
  },
  { color: theme.palette.info.main, title: 'Chỉ số chưa được cấu hình ngưỡng' },
  { color: theme.palette.grey[500], title: 'Thiết bị đo chỉ số bị hỏng' },
];

const listColorIndicatorAQI: { color: string; title: string; threshold: string }[] = [
  {
    color: isSiTechSystem ? 'rgb(0,228,0)' : 'rgb(46 125 50)',
    title: 'Tốt (Tự do thực hiện các hoạt động ngoài trời)',
    threshold: '0 - 50',
  },
  {
    color: isSiTechSystem ? 'rgb(255,255,0)' : '#f8c12c',
    title: 'Trung bình (Tự do thực hiện các hoạt động ngoài trời)',
    threshold: '51 - 100',
  },
  {
    color: isSiTechSystem ? 'rgb(255,126,0)' : '#ff954d',
    title:
      'Kém (Những người thấy có triệu chứng đau mắt, ho hoặc đau họng nên cân nhắc giảm các hoạt động ngoài trời. Đối với học sinh, có thể hoạt động bên ngoài, nhưng nên giảm bớt việc tập thể dục kéo dài)',
    threshold: ' 101 - 150',
  },
  {
    color: isSiTechSystem ? 'rgb(255,0,0)' : 'rgb(214 48 50)',
    title:
      'Xấu (Mọi người nên giảm các hoạt động mạnh khi ở ngoài trời, tránh tập thể dục kéo dài)',
    threshold: '151 - 200',
  },
  {
    color: isSiTechSystem ? 'rgb(143,63,151)' : '#8c63b8',
    title:
      'Rất xấu (Mọi người hạn chế tối đa các hoạt động ngoài trời và chuyển tất cả các hoạt động vào trong nhà. Nếu cần thiết phải ra ngoài, hãy đeo khâu trang đạt tiêu chuẩn)',
    threshold: '201 - 300',
  },
  {
    color: isSiTechSystem ? 'rgb(126,0,35)' : '#8d4d66',
    title:
      'Nguy hại (Mọi người nên ở trong nhà, đóng cửa ra vào và cửa sổ. Nếu cần thiết phải ra ngoài, hãy đeo khẩu trang đạt tiêu chuẩn)',
    threshold: '301 - 500',
  },
];

const listColorStation: { icon: string; title: string }[] = [
  { icon: dangerImage, title: 'Trạm có chỉ số vượt ngưỡng.' },
  {
    icon: normalImage,
    title: 'Trạm hoạt động bình thường.',
  },
];

const listColorIndicatorWQI: { color: string; title: string; threshold: string }[] = [
  {
    color: isSiTechSystem ? 'rgb(126,0,35)' : '#9c5973',
    title: 'Ô nhiễm rất nặng (Nước nhiễm độc, cần có biện pháp khắc phục và xử lý)',
    threshold: '< 10',
  },
  {
    color: isSiTechSystem ? 'rgb(255,0,0)' : 'rgb(214 48 50)',
    title: 'Kém (Nước ô nhiễm nặng, cần các biện pháp xử lý trong tương lai)',
    threshold: '10 - 25',
  },
  {
    color: isSiTechSystem ? 'rgb(255,126,0)' : '#ff954d',
    title: 'Xấu (Sử dụng cho giao thông thuỷ và các mục đích tương đương khác)',
    threshold: '26 - 50',
  },
  {
    color: isSiTechSystem ? 'rgb(255,255,0)' : '#f8c12c',
    title: 'Trung bình (Sử dụng cho mục đích tưới tiêu và các mục đích tương đương khác)',
    threshold: '51 - 75',
  },
  {
    color: isSiTechSystem ? 'rgb(0,228,0)' : 'rgb(46 125 50)',
    title: 'Tốt (Sử dụng cho mục đích cấp nước sinh hoạt nhưng cần các biện pháp xử lý phù hợp)',
    threshold: '76-90',
  },
  {
    color: isSiTechSystem ? 'rgb(51,51,255)' : '#5acede',
    title: 'Rất tốt (Sử dụng cho mục đích cấp nước sinh hoạt)',
    threshold: '91 - 100',
  },
];

const ModalNoteIndicator = () => {
  const classes = useStyles();

  return (
    <DialogWrapper
      isFullHeight
      maxWidth={700}
      minWidth={700}
      sx={{ height: '100%', display: 'flex' }}
      modalId={CModalIds.noteIndicator}
    >
      <Box flex={1} display="flex" flexDirection="column">
        <Typography className={classes.titleModal}>Chú Thích</Typography>
        <Scrollbars>
          <Box sx={{ display: 'flex', p: 2, flex: 1, gap: '15px', flexDirection: 'column' }}>
            <Box>
              <Typography className={classes.content}>- Chú thích màu chỉ số</Typography>
              {listColorIndicator.map((item, index) => {
                return (
                  <>
                    <Box className={classes.boxNoteIndicator} key={index} alignItems={'center'}>
                      <Box
                        sx={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: item.color,
                          borderRadius: '50%',
                        }}
                      ></Box>
                      <Typography flex={1}>{item.title}</Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box className={classes.boxAQIAndWQI}>
              <Typography className={classes.content}>- Chú thích AQI</Typography>
              {listColorIndicatorAQI.map((item, index) => {
                return (
                  <>
                    <Box className={classes.boxNoteIndicator} key={index}>
                      <Box
                        sx={{
                          width: '100px',
                          backgroundColor: item.color,
                          height: '20px',
                          padding: '0 5px',
                        }}
                      >
                        <Typography color={'white'} align="center">
                          {item.threshold}
                        </Typography>
                      </Box>
                      <Typography flex={1}>{item.title}</Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box className={classes.boxAQIAndWQI}>
              <Typography className={classes.content}>- Chú thích WQI</Typography>
              {listColorIndicatorWQI.map((item, index) => {
                return (
                  <>
                    <Box className={classes.boxNoteIndicator} key={index}>
                      <Box
                        sx={{
                          width: '100px',
                          backgroundColor: item.color,
                          height: '20px',
                          padding: '0 5px',
                        }}
                      >
                        <Typography color={'white'} align="center">
                          {item.threshold}
                        </Typography>
                      </Box>
                      <Typography flex={1}>{item.title}</Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
            <Box sx={{ width: '100%' }}>
              <Typography className={classes.content}>
                - Chú thích trạm khi không hiển thị AQI/WQI
              </Typography>
              {listColorStation.map((item, index) => {
                return (
                  <>
                    <Box className={classes.boxNoteIndicator} key={index} alignItems={'center'}>
                      <Box
                        component="img"
                        src={item.icon}
                        alt=""
                        sx={{
                          width: '40px',
                          height: '40px',
                        }}
                      />
                      <Typography flex={1}>{item.title}</Typography>
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </Scrollbars>
      </Box>
    </DialogWrapper>
  );
};

export default ModalNoteIndicator;
