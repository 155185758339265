import React from 'react';
import { Box } from '@mui/material';
import { ICamera } from 'src/types/camera';
import { ECameraAction } from 'src/types/enum';
import { useSignaling } from 'src/hooks';
import CameraCreate from './camera_create';
import CameraMap from './camera_map';
import CameraStream from './camera_stream';
import CameraDetail from './camera_detail';
import { useStyles } from './styles';

interface IProps {
  actionType: ECameraAction;
  camera: ICamera | null;
  handleClose: () => void;
  refreshCameras?: () => void;
}

const CameraManage = ({ actionType, camera, handleClose, refreshCameras }: IProps) => {
  const classes = useStyles();
  const wsOpen = useSignaling();

  const renderActionCamera = (type: ECameraAction) => {
    const Action: { [key in ECameraAction]: React.ReactNode } = {
      [ECameraAction.CREATE]: (
        <CameraCreate
          refreshCameras={refreshCameras}
          isSharedLink={false}
          handleClose={handleClose}
        />
      ),
      [ECameraAction.SHARE_LINK]: (
        <CameraCreate
          refreshCameras={refreshCameras}
          isSharedLink={true}
          handleClose={handleClose}
        />
      ),
      [ECameraAction.UPDATE]: camera && (
        <CameraDetail camera={camera} handleClose={handleClose} refreshCameras={refreshCameras} />
      ),
      [ECameraAction.CLOSE]: null,
    };

    return Action[type] || null;
  };

  const renderContentCamera = () => {
    if (actionType === ECameraAction.UPDATE) {
      return <CameraStream camera={camera} wsOpen={wsOpen} />;
    }

    return <CameraMap />;
  };

  return (
    <Box className={classes.root}>
      {renderActionCamera(actionType)}
      {renderContentCamera()}
    </Box>
  );
};

export default CameraManage;
