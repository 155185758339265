import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { getMe } from 'src/redux_store/my_account/my_account_actions';
import { openModal } from 'src/redux_store/common/modal_slice';
import { CModalIds } from 'src/constants/modal';

import { useStyles } from './styles';
import ModalEditPhone from '../modal_edit_phone';
import ModalEditName from '../modal_edit_name';

const BoxInformationUser = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { me } = useAppSelector((state) => state.myAccountSlice);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  const handleOpenModalChangePhone = () => {
    dispatch(
      openModal({
        modalId: CModalIds.changePhoneForMyAccount,
        modalComponent: <ModalEditPhone modalId={CModalIds.changePhoneForMyAccount} />,
      }),
    );
  };

  const handleOpenModalChangeName = () => {
    dispatch(
      openModal({
        modalId: CModalIds.changeNameForMyAccount,
        modalComponent: <ModalEditName modalId={CModalIds.changeNameForMyAccount} />,
      }),
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Box className={classes.avatar}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '80%',
              height: 'auto',
            }}
          >
            <Box className={classes.avatarImage}>
              <Typography> {me?.name.slice(0, 1)} </Typography>
              <Box className={classes.boxOnline}></Box>
            </Box>
            <Typography className={classes.name}>{me?.email}</Typography>
          </Box>
        </Box>
        <Box className={classes.bodyDetail}>
          <Box className={classes.informationDetail}>
            <Box sx={{ margin: '0 10px' }}>
              <Typography sx={{ fontWeight: '600' }}>Email</Typography>
              <Typography sx={{ fontSize: '14px' }}>{me?.email}</Typography>
            </Box>
          </Box>

          <Box className={classes.informationDetail}>
            <Box sx={{ margin: '0 10px' }}>
              <Typography sx={{ fontWeight: '600' }}>Họ và tên</Typography>
              <Typography sx={{ fontSize: '14px' }}>{me?.name}</Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ height: '40px', marginRight: '10px' }}
              onClick={handleOpenModalChangeName}
            >
              <EditOutlinedIcon />
              <Typography sx={{ paddingLeft: '8px', fontWeight: '600' }}>Chỉnh sửa</Typography>
            </Button>
          </Box>

          <Box className={classes.informationDetail}>
            <Box sx={{ margin: '0 10px' }}>
              <Typography sx={{ fontWeight: '600' }}>Số điện thoại</Typography>
              <Typography sx={{ fontSize: '14px' }}>{me?.phoneNumber}</Typography>
            </Box>
            <Button
              variant="contained"
              sx={{ height: '40px', marginRight: '10px' }}
              onClick={handleOpenModalChangePhone}
            >
              <EditOutlinedIcon />
              <Typography sx={{ paddingLeft: '8px', fontWeight: '600' }}>Chỉnh sửa</Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BoxInformationUser;
