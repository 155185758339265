import { monitoringDataApi } from './../../clients/http/monitoring_data_api';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { stationChartApi } from 'src/clients/http/station_chart_api';
import {
  IGetDataIndicatorOfStationPayload,
  IResponseGetDataIndicatorOfStation,
} from 'src/types/monitoring_data';
import { IChartData, IGetChartData } from 'src/types/station_chart';
import { toastMessage } from 'src/utils/toast';

export const getStationChart = createAsyncThunk<IChartData, IGetChartData>(
  'chart/getStationChart',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await stationChartApi.getStationChart(payload);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getDataIndicatorOfStation = createAsyncThunk<
  IResponseGetDataIndicatorOfStation[],
  {
    payload: IGetDataIndicatorOfStationPayload;
    params: { stationId: string; indicatorSymbol: string };
  }
>('chart/getDataIndicatorOfStation', async (payload, { rejectWithValue }) => {
  try {
    const res = await monitoringDataApi.getDataIndicatorOfStation(payload);
    return res.data;
  } catch (error: any) {
    toastMessage.error(error.message || 'Lỗi hệ thống');
    return rejectWithValue(error);
  }
});
