import { IPagination } from 'src/types/common';
import { createClient } from './axios_client';
import {
  IPayloadFormSample,
  IStationsSample,
  IPayloadGetSample,
  IDataCreateSample,
  IUpdateSample,
  IPayloadGetHistorySample,
} from 'src/types/sample';
import { IStation, IStationParams } from 'src/types/station';

const client = createClient('https://devmt.danateq.vn/api/v1/');

export const sampleApi = {
  getSampleList: (params: IPayloadGetSample) => {
    return client.get<IPagination<IStationsSample>>(`/automatic_sampling`, {
      params,
    });
  },

  createSample: (payload: IPayloadFormSample) => {
    return client.post<IDataCreateSample>(`/automatic_sampling`, payload);
  },

  getInfoStationSampleById: (id: string) => {
    return client.get<IPayloadFormSample>(`/automatic_sampling/${id}`);
  },

  editInfoStationSample: (id: string, payload: IUpdateSample) => {
    return client.put<IPayloadFormSample>(`/automatic_sampling/${id}`, payload);
  },

  deleteInfoStationSample: (id: string) => {
    return client.delete<any>(`/automatic_sampling/${id}`);
  },

  getHistorySampleByStation: (id: string, params: IPayloadGetHistorySample) => {
    return client.get<any>(`/automatic_sampling/history/${id}`, {
      params,
    });
  },

  getHistorySampleBySampleIds: (params: string[]) => {
    return client.get<any>(`/automatic_sampling/history/list?history=${params}`);
  },

  deleteHistoryById: (id: string) => {
    return client.delete<any>(`/automatic_sampling/history/${id}`);
  },

  createAutoSample: (id: string) => {
    return client.post<any>(`/automatic_sampling/get_sample/${id}`, {}, { timeout: 60000 });
  },
  getStationOptions: (params: IStationParams) => {
    return client.get<IStation[]>('/stations/options', {
      params,
    });
  },
};
