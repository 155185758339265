import { createAsyncThunk } from '@reduxjs/toolkit';
import { fileApi } from 'src/clients/http';
import { IDocument } from 'src/types/file';
import { toastMessage } from 'src/utils/toast';

export const getFolderStationById = createAsyncThunk<
  { folder: IDocument; files: IDocument[] },
  string
>('file/getFolderStationById', async (id, { rejectWithValue }) => {
  try {
    const { data } = await fileApi.getFolderStationById(id);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getFilesByFolderId = createAsyncThunk<
  { folder: IDocument; files: IDocument[] },
  string
>('file/getFilesByFolderId', async (id, { rejectWithValue }) => {
  try {
    const { data } = await fileApi.getFilesByFolderId(id);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getPenaltyFiles = createAsyncThunk<{ folder: IDocument; files: IDocument[] }, string>(
  'file/getPenaltyFiles',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.getPenaltyFiles(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error.message);
      return rejectWithValue(error);
    }
  },
);

export const createFolder = createAsyncThunk<IDocument, { parentId: string; folderName: string }>(
  'file/createFolder',
  async ({ parentId, folderName }, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.createFolder(parentId, folderName);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const renameFolder = createAsyncThunk<IDocument, { id: string; folderName: string }>(
  'file/renameFolder',
  async ({ id, folderName }, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.renameFolder(id, folderName);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteFolder = createAsyncThunk<string, string>(
  'file/deleteFolder',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.deleteFolder(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const uploadFile = createAsyncThunk<IDocument[], FormData>(
  'file/uploadFile',
  async (data, { rejectWithValue }) => {
    try {
      const res = await fileApi.uploadFile(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const renameFile = createAsyncThunk<IDocument, { id: string; fileName: string }>(
  'file/renameFile',
  async ({ id, fileName }, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.renameFile(id, fileName);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteFile = createAsyncThunk<string, string>(
  'file/deleteFile',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.deleteFile(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const downloadFile = createAsyncThunk<Blob, string>(
  'file/downloadFile',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.downloadFile(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMultipleFile = createAsyncThunk<string, string>(
  'file/deleteMultipleFile',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.deleteMultipleFile(params);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
