import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useRef } from 'react';
import { useIsRequestPending } from 'src/hooks';
import { ICamera } from 'src/types/camera';
import { useStyles } from './styles';
import { useAppDispatch } from 'src/redux_store';
import { useAppSelector } from 'src/redux_store';
import { getCameraLogs } from 'src/redux_store/camera/camera_action';
import { HighlightOffOutlined, InfoOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { MdOutlineNewReleases } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroller';
import SkeletonLoading from 'src/components/notification/skeleton_loading';
import Scrollbars from 'react-custom-scrollbars-2';
import parse from 'html-react-parser';
import { resetCameraLogsState } from 'src/redux_store/camera/camera_slice';

interface IProps {
  camera: ICamera;
}

const CameraLogs = ({ camera }: IProps) => {
  const classes = useStyles();
  const { cameraLogsList, isLoadMoreCameraLogs, isLoading } = useAppSelector(
    (state) => state.cameraSlice,
  );
  const isLoadingGetCameraLogs = useIsRequestPending('camera', 'getCameraLogs');
  const dispatch = useAppDispatch();
  const scrollRef = useRef<Scrollbars | null>(null);
  const renderIconNotification = (level: string) => {
    if (level === 'ERROR') return <HighlightOffOutlined fontSize="large" color="error" />;
    if (level === 'INFO') return <InfoOutlined fontSize="large" color="primary" />;
    if (level === 'CRITICAL') return <MdOutlineNewReleases size={36} color="#ac430c" />;
    return <WarningAmberOutlined fontSize="large" color="warning" />;
  };
  const formatCameraLog = (objectCurrent: { data: { cameraName: string }; content: string }) => {
    const { data, content } = objectCurrent;
    let cloneText = content;
    for (const key in data) {
      const index = cloneText.indexOf(`{${key}}`);
      const newText = `<b>${data.cameraName}</b>`;
      if (index !== -1) {
        cloneText = cloneText.replace(`{${key}}`, newText);
      }
    }
    return cloneText;
  };
  const SIZE = 20;
  const asyncGetData = (newPayload: any) => {
    dispatch(
      getCameraLogs({
        id: camera.id,
        payload: newPayload,
      }),
    );
  };

  const handleScrollStop = () => {
    if (cameraLogsList.length > 0) {
      const current: any = scrollRef.current;
      const { top } = current.getValues();
      const roundingTop = Math.ceil(top * 100);
      if (roundingTop === 100 && !isLoadingGetCameraLogs) {
        if (isLoadMoreCameraLogs) {
          const afterId = cameraLogsList[cameraLogsList.length - 1]?.id || '';
          asyncGetData({ after: afterId, limit: SIZE });
        }
      }
    }
  };

  React.useEffect(() => {
    const newPayload = {
      after: '',
      limit: SIZE,
    };
    asyncGetData(newPayload);
    return () => {
      dispatch(resetCameraLogsState());
    };
  }, [camera.id]);

  const loadMoreItem = () => {
    // console.log("")
  };

  const renderContent = () => {
    if (!cameraLogsList.length) {
      if (isLoading) {
        return <SkeletonLoading numberShow={14} width="100%" />;
      } else
        return (
          <Box textAlign="center" p={1}>
            <Typography>Không có thông báo</Typography>
          </Box>
        );
    } else {
      return (
        <>
          <InfiniteScroll
            pageStart={0}
            loadMore={loadMoreItem}
            loader={
              <SkeletonLoading numberShow={cameraLogsList.length ? 2 : 15} width="100%" key={0} />
            }
            useWindow={false}
            hasMore={isLoadMoreCameraLogs}
            initialLoad={true}
          >
            {cameraLogsList.map((item, index) => (
              <Box className={classes.LogItems} key={index}>
                <Box
                  sx={{
                    minHeight: '50px',
                    position: 'relative',
                  }}
                >
                  <Grid
                    container
                    className={classes.LogWrap}
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Grid
                      item
                      md={11}
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <Grid
                        container
                        className={classes.LogWrap}
                        sx={{
                          alignItems: 'center',
                        }}
                      >
                        <Grid item md={2}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            {renderIconNotification(item.level)}
                          </Box>
                        </Grid>
                        <Grid item md={10}>
                          <Box>
                            <Typography variant="body1">
                              {parse(formatCameraLog(item.title) || '')}
                            </Typography>
                          </Box>
                          <Grid container className={classes.LogTime}>
                            <Grid
                              item
                              sx={{
                                boxSizing: 'border-box',
                                margin: '0px',
                                flexDirection: 'row',
                              }}
                            >
                              <Typography variant="caption">18-11-2022 15:47:16</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </InfiniteScroll>
        </>
      );
    }
  };

  return (
    <Box
      sx={{
        height: ' 100%',
        flex: 1,
        backgroundColor: '#f3f2f1',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Scrollbars ref={scrollRef} onScrollStop={handleScrollStop} autoHide>
        <Grid container flexDirection="column" className={classes.container}>
          {renderContent()}
        </Grid>
      </Scrollbars>
    </Box>
  );
};

export default CameraLogs;
