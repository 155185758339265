import { createClient } from './axios_client';
import {
  IApiRequest,
  IApis,
  IGroup,
  IParams,
  IPayloadUpdateBody,
  IHeader,
  IPayloadUpdateRequest,
  IResponse,
  IGroupSearch,
} from 'src/types/apis';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const apis_management = {
  getGroups: (params: IGroupSearch) => {
    return client.get<IGroup[]>('/apis/groups', { params });
  },

  createGroup: (data: IGroup) => {
    return client.post<IGroup>('/apis/groups', data);
  },
  getGroupById: (id: string) => {
    return client.get<IGroup>(`/apis/groups/${id}`);
  },

  deleteGroupById: (id: string) => {
    return client.delete<any>(`/apis/groups/${id}`);
  },

  updateGroupById: (id: string, data: IGroup) => {
    return client.put<IGroup>(`/apis/groups/${id}`, data);
  },

  // api request
  createRequest: (data: IApiRequest) => {
    return client.post<IApis>('/apis', data);
  },

  getRequestById: (id: string) => {
    return client.get<IApis>(`/apis/${id}`);
  },

  deleteRequestById: (id: string) => {
    return client.delete<any>(`/apis/${id}`);
  },
  updateRequestById: (id: string, data: IPayloadUpdateRequest) => {
    return client.patch<any>(`/apis/${id}/general`, data);
  },

  // api update type
  updateParamsByIdRequest: (id: string, params: IParams[]) => {
    return client.patch<IApis>(`/apis/${id}/params`, {
      params,
    });
  },

  updateHeadersByIdRequest: (id: string, headers: IHeader[]) => {
    return client.patch<any>(`/apis/${id}/headers`, {
      headers,
    });
  },

  updateQueryParamsByIdRequest: (id: string, queryParams: IParams[]) => {
    return client.patch<any>(`/apis/${id}/queryParams`, { queryParams });
  },

  updateBodyByIdRequest: (id: string, body: IPayloadUpdateBody) => {
    return client.patch<any>(`/apis/${id}/body`, { body });
  },

  updateResponseByIdRequest: (id: string, response: IResponse[]) => {
    return client.patch<any>(`/apis/${id}/response`, { response });
  },
};
