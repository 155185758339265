import { yupResolver } from '@hookform/resolvers/yup';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Box, Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';

import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { FormInput, FormTextarea } from 'src/components/hook_form';
import { FormDateTimePicker } from 'src/components/hook_form/form_datetimepicker';
import { CPath } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { deleteMultipleFile } from 'src/redux_store/file/file_actions';
import {
  addNewPosts,
  editPostById,
  getPostById,
  uploadImage,
} from 'src/redux_store/post/post_action';
import { addIdImage, deleteAllImageId, resetPayload } from 'src/redux_store/post/post_slice';
import { IPost, IPostData } from 'src/types/post';
import { toastMessage } from 'src/utils/toast';
import * as yup from 'yup';
import FormEditor from '../form_editor';
import { useStyles } from './styles';

const defaultValues: IPostData = {
  title: '',
  description: '',
  author: '',
  publishedAt: new Date(),
};

const validationInput = yup.object().shape({
  title: yup.string().required('Tiêu đề không được để trống'),
  description: yup.string().required('Mô tả không được để trống'),
  author: yup.string().required('Tác giả không được để trống'),
  publishedAt: yup.date().required('Ngày đăng không được để trống'),
});

const AddNewPost = () => {
  const [urlImage, setUrlImage] = useState<string>('');
  const [postId, setPostId] = useState<string>('');
  const [height, setHeight] = useState<number>(450);
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { imageIdList } = useAppSelector((state) => state.postSlice);

  const [body, setBody] = useState('');

  const { control, handleSubmit, reset } = useForm<Omit<IPost, 'id' | 'createdAt' | 'updatedAt'>>({
    defaultValues,
    resolver: yupResolver(validationInput),
  });

  const handlePostDataToApi = (data: Omit<IPost, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (urlImage === '') {
      alert('Bạn chưa chọn ảnh đại điện !!!');
    } else {
      dispatch(
        addNewPosts({
          avatar: urlImage,
          title: data.title,
          description: data.description,
          content: body,
          author: data.author,
          tags: [],
          publishedAt: new Date(data.publishedAt),
          images: imageIdList,
        }),
      )
        .unwrap()
        .then(() => {
          toastMessage.success('Thêm bài viết thành công');
          reset(defaultValues);
          setUrlImage('');
          setBody('');
        });
    }
  };

  const handleEditDataToApi = (data: Omit<IPost, 'id' | 'createdAt' | 'updatedAt'>) => {
    if (urlImage === '') {
      alert('Bạn chưa chọn ảnh đại điện !!!');
    } else {
      dispatch(
        editPostById({
          id: postId,
          avatar: urlImage,
          title: data.title,
          description: data.description,
          content: body,
          author: data.author,
          tags: [],
          publishedAt: new Date(data.publishedAt),
          images: imageIdList,
        }),
      )
        .unwrap()
        .then(() => {
          toastMessage.success('Cập nhật bài viết thành công');
          reset(defaultValues);
          setUrlImage('');
          setBody('');
          navigate('/post_management');
        });
    }
  };

  const handleChangeFileImage = (e: any) => {
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('files', file);
    dispatch(uploadImage(formData))
      .unwrap()
      .then((data) => {
        data.forEach((item) => {
          setUrlImage(item.path);
          if (item.id) {
            dispatch(addIdImage(item.id));
          }
        });

        toastMessage.success('Thêm ảnh thành công');
      });
  };

  const moveBackPostManagement = () => {
    navigate(CPath.postManagement);
    dispatch(resetPayload());
  };

  useEffect(() => {
    if (id) {
      dispatch(getPostById(id))
        .unwrap()
        .then((data) => {
          reset(data);
          setUrlImage(data.avatar);
          setBody(data.content || '');
          setPostId(data.id);
        });
    }

    setHeight(Number(document.getElementById('boxFormEditor')?.offsetHeight));

    return () => {
      if (imageIdList.length > 0) {
        const files = imageIdList.join(',');
        dispatch(deleteMultipleFile(files));
        dispatch(deleteAllImageId());
      }
    };
  }, []);

  return (
    <Scrollbars>
      <Box className={classes.container}>
        <Box
          sx={{
            width: '100%',
            height: '45px',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#ffffff',
            borderRadius: '1px',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <IconButton>
            <ArrowBackOutlinedIcon onClick={moveBackPostManagement} />
          </IconButton>
        </Box>

        <Box className={classes.boxEditor}>
          <Box className={classes.formEditor} id="boxFormEditor">
            <FormEditor
              body={body}
              height={Number(height)}
              handleChangeBody={(value) => setBody(value)}
            />
          </Box>

          <Box className={classes.detailEditor}>
            <Scrollbars autoHide>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '0 5px',
                }}
              >
                <FormInput control={control} name="title" label="Tiêu đề" />
                <FormTextarea control={control} name="description" label="Mô tả" minRows={6} />
                <FormInput control={control} name="author" label="Tác giả" />

                <Box className={classes.boxImage}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddPhotoAlternateOutlinedIcon />}
                    sx={{ height: '40px' }}
                    component="label"
                  >
                    Ảnh cover
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(e) => handleChangeFileImage(e)}
                    />
                  </Button>
                  <Box
                    sx={{
                      width: '300px',
                      height: '180px',
                      overflow: 'hidden',
                    }}
                  >
                    <img
                      src={urlImage || 'https://fucoidannano.com/img/no_img.png'}
                      alt=""
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </Box>
                <FormDateTimePicker
                  control={control}
                  name="publishedAt"
                  minDate={new Date()}
                  label="Ngày đăng"
                  minutesStep={60}
                />
                <Box
                  sx={{ width: '100%', display: 'grid', placeItems: 'center', marginTop: '20px' }}
                >
                  {!id ? (
                    <Button variant="contained" onClick={handleSubmit(handlePostDataToApi)}>
                      THÊM MỚI
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleSubmit(handleEditDataToApi)}
                    >
                      CẬP NHẬT
                    </Button>
                  )}
                </Box>
              </Box>
            </Scrollbars>
          </Box>
        </Box>
      </Box>
    </Scrollbars>
  );
};

export default AddNewPost;
