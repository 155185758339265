import { createAsyncThunk } from '@reduxjs/toolkit';
import { userLogsApi } from 'src/clients/http/user_log_api';
import { IUserLog, IDataFilterUserLogs, IFilterUserLogs } from 'src/types/user_log';
import { toastMessage } from 'src/utils/toast';

export const getFilterUserLog = createAsyncThunk<IDataFilterUserLogs, void>(
  'userLog/getFilterUserLog',
  async (_, { rejectWithValue }) => {
    try {
      const res = await userLogsApi.getFilterUserLog();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
      toastMessage.error(error.message || 'Lỗi hệ thống');
    }
  },
);

export const getDataUserLog = createAsyncThunk<IUserLog, IFilterUserLogs>(
  'userLog/getDataUserLog',
  async (data, { rejectWithValue }) => {
    try {
      const res = await userLogsApi.getDataUserLog(data);
      return res.data;
    } catch (error: any) {
      toastMessage.error(error.message || 'Lỗi hệ thống');
      return rejectWithValue(error);
    }
  },
);

export const deleteUserLog = createAsyncThunk<string, string>(
  'userLog/deleteUserLog',
  async (userId, { rejectWithValue }) => {
    try {
      const res = await userLogsApi.deleteUserLog(userId);
      return res.data;
    } catch (error: any) {
      toastMessage.error(error.message || 'Lỗi hệ thống');
      return rejectWithValue(error);
    }
  },
);
