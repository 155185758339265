import { createSlice } from '@reduxjs/toolkit';
import { IGetDevice } from 'src/types/my_account';
import { findIndexItem } from 'src/utils/function';
import { revokeSession, getMyDevice } from './my_device_actions';

interface IState {
    device: IGetDevice[];
}

const initialState: IState = {
    device: [],
};

const myDeviceSlice = createSlice({
    name: 'myDevice',
    initialState,
    reducers: {
        resetState: (state) => {
            state.device = initialState.device;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getMyDevice.fulfilled, (state, action) => {
                state.device = action.payload;
            });
        builder.addCase(revokeSession.fulfilled, (state, action) => {
            const currentData = [...state.device]
            const { sessionId } = action.meta.arg
            const index = findIndexItem(currentData, sessionId)

            if (index !== -1) {
                currentData.splice(index, 1)

                state.device = currentData
            }
        });
    },

});

const { reducer } = myDeviceSlice;

export default reducer;
