import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    width: 300,
    height: '100%',
    flexShrink: 0,
    background: theme.palette.secondary.light,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  collapse: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.common.white,
    height: 49,
    borderWidth: 1,
    borderColor: 'transparent',
    borderRightColor: theme.palette.divider,
    borderBottomColor: theme.palette.divider,
    borderStyle: 'solid',
    zIndex: 10000,
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));
