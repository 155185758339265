import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(() => ({
  formSelect: {
    display: 'flex',
    flexFlow: ' row nowrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  boxFilter: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
}));
