import React, { useState, useEffect, useRef } from 'react';
import { Box, Divider } from '@mui/material';
import ErrorBoundary from 'src/components/error_boundaries';
import { useStyles } from './style';
import Topbar from 'src/components/topbar';
import { CPath, CRouteList } from 'src/constants';
import Sidebar from 'src/components/sidebar';
import SidebarSmall from 'src/components/sidebar_small';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import BoxRightContent from 'src/components/box_right_content';
import websocket from 'src/clients/websocket';
import {
  getNotificationSetting,
  unseenNotification,
  markAllAsSeenNotification,
} from 'src/redux_store/notification/notification_action';

import toast from 'react-hot-toast';
import { INotificationLevel } from 'src/types/notification';
import { soundList } from 'src/constants/media';
import NotificationItem from 'src/components/notification/notification_item';
import _ from 'lodash';
import { closeBoxRightContent } from 'src/redux_store/common/topbar/topbar_slice';

interface IProps {
  children: JSX.Element;
}

const AdminLayout = (props: IProps) => {
  const classes = useStyles();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { token, me } = useAppSelector((state) => state.myAccountSlice);
  const { openRightContent, typeContentShow } = useAppSelector((state) => state.topBarSlice);

  const { numberOfNotifications, infoNotificationToast, notificationSetting } = useAppSelector(
    (state) => state.notificationSlice,
  );

  const [count, setCount] = useState(0);
  const [tabHasFocus, setTabHasFocus] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const toggleSidebar = () => setIsOpen((prev) => !prev);
  const audioRef = useRef<HTMLAudioElement>(new Audio());

  const getTitle = () => {
    if (!location.pathname) return '';
    const path = location.pathname;
    const route = CRouteList.find((route) => route.path === path);
    const title = route?.name || '';

    return title;
  };

  if (!token) {
    return <Navigate to={CPath.login} state={{ from: location }} replace />;
  }

  useEffect(() => {
    if (token) {
      websocket.initialize(token);
    }
  }, [token]);

  useEffect(() => {
    if (!me?.id) return;
    dispatch(unseenNotification());
    dispatch(getNotificationSetting());
  }, []);

  useEffect(() => {
    return () => {
      if (websocket) {
        websocket.disconnect();
      }
      dispatch(closeBoxRightContent());
    };
  }, []);

  const handleResetTitle = () => {
    document.title = 'Hệ thống quản lý tài nguyên nước';
    setCount(0);
  };

  const handleFocus = () => {
    setTabHasFocus(true);
  };

  const handleBlur = () => {
    setTabHasFocus(false);
  };

  useEffect(() => {
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (tabHasFocus || !numberOfNotifications) return handleResetTitle();

    const timer: any = setInterval(() => {
      if (count % 2 === 0) {
        document.title = `Bạn có ${numberOfNotifications} thông báo mới`;
      } else {
        document.title = 'Hệ thống quản lý tài nguyên nước';
      }

      setCount(count + 1);
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [numberOfNotifications, count, tabHasFocus]);

  useEffect(() => {
    if (!_.isEmpty(infoNotificationToast)) {
      handleClickSound();
      handleShowPopup();
      if (openRightContent && typeContentShow === 'notification') {
        dispatch(markAllAsSeenNotification());
      }
    }
  }, [infoNotificationToast]);

  const checkPlaySound = (): boolean => {
    if (_.isEmpty(infoNotificationToast) || _.isEmpty(notificationSetting)) return false;

    const { placeOfOrigin } = infoNotificationToast;
    const { notificationSources } = notificationSetting;
    const notificationItemSource = notificationSources.find(
      (item) => item.source === placeOfOrigin,
    );

    if (!_.isEmpty(notificationItemSource)) {
      const sound = notificationItemSource.sound;

      return sound;
    }

    return true;
  };

  const handleShowPopup = () => {
    const { popupStatus } = notificationSetting;

    if (!popupStatus) return;

    toast(<NotificationItem notification={infoNotificationToast} isToastNotification />, {
      style: {
        paddingBottom: 0,
      },
    });
  };

  const handleClickSound = async () => {
    if (audioRef.current) {
      const levelNotification = infoNotificationToast.level.toLowerCase();
      const notificationLevels = notificationSetting.notificationLevels;
      const notificationLevelItem =
        notificationLevels[levelNotification as keyof INotificationLevel];

      if (
        notificationLevelItem.status &&
        notificationLevelItem.soundRingtone !== 'OFF' &&
        checkPlaySound()
      ) {
        const sound =
          soundList.find((item) => item.id === notificationLevelItem.soundRingtone) || soundList[0];
        audioRef.current.src = sound.source;
        await audioRef.current?.play();
      }
    }
  };

  return (
    <ErrorBoundary>
      <Box className={classes.root}>
        <Topbar title={getTitle()} />
        <Divider />
        <Box className={classes.container}>
          {isOpen ? (
            <Sidebar toggleSidebar={toggleSidebar} />
          ) : (
            <SidebarSmall toggleSidebar={toggleSidebar} />
          )}
          <Divider orientation="vertical" />
          <Box className={classes.wrapper}>
            <Box className={classes.page}>{props.children}</Box>
            {openRightContent && <BoxRightContent />}
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default AdminLayout;
