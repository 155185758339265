import { createSlice } from '@reduxjs/toolkit';
import { ILatestData, IStationInfo, IPayloadGetLatestData } from 'src/types/latest_data';
import {
  getFileImageOfStation,
  getLatestData,
  getStationDataLast,
  getStationDetailInfo,
} from './latest_data_actions';

interface ISettingShow {
  showAllIndicator: boolean;
  showEnvIndex: boolean;
  // showCaptions: boolean;
}

interface ILatestDataSlice {
  data: ILatestData[];
  totalData: number;
  settingShow: ISettingShow;
  stationDetail: {
    stationInfo: IStationInfo;
    stationDataLast: ILatestData;
    imageList: { id: string; path: string }[];
  };
  filter: IPayloadGetLatestData;
}

const initialState: ILatestDataSlice = {
  data: [],
  totalData: 0,
  settingShow: {
    showAllIndicator: false,
    showEnvIndex: true,
    // showCaptions: true,
  },

  filter: {
    district: '',
    monitoringGroup: '',
    monitoringType: '',
    limit: 12,
    page: 1,
    confirmationStatus: '',
    searchKeyword: '',
  },
  stationDetail: {
    stationInfo: {} as IStationInfo,
    stationDataLast: <ILatestData>{},
    imageList: [],
  },
};
const latestDataSlice = createSlice({
  name: 'latestData',
  initialState,
  reducers: {
    changeSettingShow: (state, action: { payload: ISettingShow }) => {
      state.settingShow = action.payload;
    },

    changePayloadLatestData: (state, action: { payload: IPayloadGetLatestData }) => {
      const newPayload = action.payload;
      state.filter = newPayload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getLatestData.fulfilled, (state, action) => {
      const { data, totalData } = action.payload;
      state.data = data;
      state.totalData = totalData;
    });
    builder.addCase(getStationDetailInfo.fulfilled, (state, action) => {
      state.stationDetail.stationInfo = action.payload;
    });
    builder
      .addCase(getStationDataLast.fulfilled, (state, action) => {
        state.stationDetail.stationDataLast = action.payload;
      })
      .addCase(getFileImageOfStation.fulfilled, (state, action) => {
        const imageList = action.payload;
        state.stationDetail.imageList = imageList;
      });
  },
});

const { actions, reducer } = latestDataSlice;

export const { changeSettingShow, changePayloadLatestData } = actions;
export default reducer;
