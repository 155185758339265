import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexShrink: 0,
    width: 400,
    backgroundColor: theme.palette.common.white,
  },
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    height: 49,
  },
  headerTitle: {
    flex: 1,
    textTransform: 'uppercase',
    fontWeight: '600 !important',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));
