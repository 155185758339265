import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { IChartData, IPayloadChartData } from 'src/types/station_chart';
import { getStationChart } from './station_chart_action';

const initPayload: IPayloadChartData = {
  limit: 60,
  startAt: moment().subtract(7, 'days'),
  endAt: moment(),
  stationId: '',
};

type State = {
  payload: IPayloadChartData;
  chartData: IChartData | null;
};

const initialState: State = {
  payload: initPayload,
  chartData: null,
};

const stationChartSlice = createSlice({
  name: 'stationChart',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getStationChart.fulfilled, (state, action) => {
      const data = action.payload;
      state.chartData = data;
    });
  },
});
// export const {} = stationChartSlice.actions;

export default stationChartSlice.reducer;
