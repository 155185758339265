import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Box } from '@mui/material';
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import ConfirmationDialog from 'src/components/modal/confirm_dialog';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { FormInput } from 'src/components/hook_form';
import { updateMyPhone } from 'src/redux_store/my_account/my_account_actions';
import { toastMessage } from 'src/utils/toast';
import { closeModal } from 'src/redux_store/common/modal_slice';
import { changePhone } from 'src/redux_store/my_account/my_account_slice';

interface IProps {
  modalId: string;
}

interface IChangePhoneNumberPayload {
  newPhoneNumber: string;
  currentPassword: string;
}

const defaultValues = {
  newPhoneNumber: '',
  currentPassword: '',
};

const validationInput = yup.object().shape({
  newPhoneNumber: yup.string().min(8).required('Số điện thoại không được để trống'),
  currentPassword: yup
    .string()
    .min(8, 'Mật khẩu ít nhất 8 ký tự')
    .required('Mật khẩu hiện tại không được để trống'),
});

const ModalEditPhone = ({ modalId }: IProps) => {
  const dispatch = useAppDispatch();
  const { me } = useAppSelector((state) => state.myAccountSlice);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
  } = useForm<IChangePhoneNumberPayload>({
    defaultValues,
    resolver: yupResolver(validationInput),
  });

  const handleGetDataPhoneNumber = (data: IChangePhoneNumberPayload) => {
    dispatch(
      updateMyPhone({
        userId: 'me',
        phoneNumber: data.newPhoneNumber,
        password: data.currentPassword,
      }),
    )
      .unwrap()
      .then(() => {
        toastMessage.success('Cập nhật người dùng thành công');
        dispatch(changePhone(data.newPhoneNumber));
        handleClose();
      });
  };

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
  };

  useEffect(() => {
    reset({
      newPhoneNumber: String(me?.phoneNumber),
    });
  }, [me]);

  const renderForm = () => {
    return (
      <Box
        component="form"
        sx={{ padding: '0 20px' }}
        onSubmit={handleSubmit(handleGetDataPhoneNumber)}
      >
        <FormInput
          control={control}
          name="newPhoneNumber"
          label="Số điện thoại của bạn"
          size="small"
        />

        <FormInput
          control={control}
          name="currentPassword"
          label="Mật khẩu hiện tại"
          type="password"
          disabled={!isDirty && !isValid}
        />
      </Box>
    );
  };

  return (
    <Box>
      <Box>
        <ConfirmationDialog
          modalId={modalId}
          functionName="updateMyPhone"
          sliceName="myAccount"
          describe={<b>Thay đổi số điện thoại</b>}
          callback={handleSubmit(handleGetDataPhoneNumber)}
          icon={<PhonelinkSetupOutlinedIcon color="primary" />}
          disabled={!isDirty && !isValid}
        >
          {renderForm()}
        </ConfirmationDialog>
      </Box>
    </Box>
  );
};

export default ModalEditPhone;
