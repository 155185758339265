import { ISystemSetting } from 'src/types/system_setting';
import { createClient } from './axios_client';

const client = createClient('http://10.49.46.53:4422/api/v1');

export const systemSettingApi = {
  getSystemSetting: () => client.get<ISystemSetting>(`/system_settings`),
  updateSystemSettingInfo: (id: string, data: Pick<ISystemSetting, 'generalInfo'>) =>
    client.put(`/system_settings/${id}/information`, data),
  updateSystemSettingService: (
    id: string,
    data: Omit<ISystemSetting, 'generalInfo' | 'updatedAt' | 'id'>,
  ) => client.put(`/system_settings/${id}/service`, data),
};
