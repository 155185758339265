import { IEgovSynchronized, IEgovSynchronizedFilter } from 'src/types/synchronized';
import { createClient } from './axios_client';

const client = createClient('http://localhost:5333/api/v1');

export const synchronizedApi = {
  getList: (params: IEgovSynchronizedFilter) =>
    client.get<{ data: IEgovSynchronized[]; totalData: number }>(`/synchronized_data`, {
      params,
    }),
  getTimeSync: () => client.get<number>(`/synchronized_data/sync`),
  sync: (data: { startTime: string; endTime: string }) =>
    client.post<string>(`/synchronized_data/sync`, data),
  getById: (id: string) => client.get<IEgovSynchronized>(`/synchronized_data/${id}`),
  export: (params: IEgovSynchronizedFilter) =>
    client.get<Blob>(`/synchronized_data/export`, {
      params,
      responseType: 'blob',
    }),
};
