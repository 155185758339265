import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal_slice';
import { CModalIds } from 'src/constants/modal';

import { useStyles } from './styles';
import BoxInformationUser from './box_information_user';
import ModalEditPassword from './modal_edit_password';

const AccountInformation = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleOpenModalChangePass = () => {
    dispatch(
      openModal({
        modalId: CModalIds.changePasswordForMyAccount,
        modalComponent: <ModalEditPassword modalId={CModalIds.changePasswordForMyAccount} />,
      }),
    );
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.body}>
        <Typography
          variant="h4"
          style={{
            textAlign: 'left',
            fontSize: '20px',
            marginTop: '20px',
            lineHeight: '32px',
            fontWeight: '500',
          }}
        >
          Thông tin tài khoản
        </Typography>
        <Box className={classes.box_Information}>
          <BoxInformationUser />
        </Box>
        <Box
          style={{
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Typography
            variant="h4"
            style={{ textAlign: 'left', fontSize: '18px', marginTop: '20px' }}
          >
            Mật khẩu và Xác thực
          </Typography>
          <Button
            variant="contained"
            className={classes.buttonPassword}
            onClick={handleOpenModalChangePass}
          >
            Thay đổi mật khẩu
          </Button>
          {/* <Box
            style={{
              marginTop: '5px',
              height: 'auto',
            }}
          >
            <Typography className={classes.text}>Xác thực hai yếu tố</Typography>
            <Typography className={classes.text}>
              Bảo vệ tài khoản của bạn bằng một lớp bảo mật bổ sung. Sau khi được định cấu hình, bạn
              sẽ được yêu cầu nhập cả mật khẩu và mã xác thực từ điện thoại di động của mình để đăng
              nhập.
            </Typography>
          </Box>
          <Button variant="contained" className={classes.buttonPassword}>
            Bật xác thực hai lớp
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default AccountInformation;
