import { createAsyncThunk } from '@reduxjs/toolkit';
import { filterApi, indicatorsApi, stationApi } from 'src/clients/http';
import { IFilter } from 'src/types/common';
import {
  IDefaultThresholdStation,
  IFormIndicator,
  IIndicator,
  IIndicatorParams,
  IMonitoringGroup,
  IMonitoringType,
} from 'src/types/indicators';
import { IStation } from 'src/types/station';

export const getMonitoringTypes = createAsyncThunk<IMonitoringType[], void>(
  'indicators/getMonitoringTypes',
  async (_, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getMonitoringTypes();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getMonitoringTypeById = createAsyncThunk<IMonitoringType, string>(
  'indicators/getMonitoringTypeById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getMonitoringTypeById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getMonitoringTypeOptions = createAsyncThunk<IMonitoringType[], void>(
  'indicators/getMonitoringTypeOptions',
  async (_, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getMonitoringTypeOptions();
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getMonitoringGroupOptions = createAsyncThunk<IMonitoringGroup[], IIndicatorParams>(
  'indicators/getMonitoringGroupOptions',
  async (parans, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getMonitoringGroupOptions(parans);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getMonitoringGroups = createAsyncThunk<
  {
    data: IMonitoringGroup[];
    totalData: number;
  },
  IIndicatorParams
>('indicators/getMonitoringGroups', async (params, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.getMonitoringGroups(params);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getMonitoringGroupById = createAsyncThunk<IMonitoringGroup, string>(
  'indicators/getMonitoringGroupById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getMonitoringGroupById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getIndicators = createAsyncThunk<
  {
    data: IIndicator[];
    totalData: number;
  },
  IIndicatorParams
>('indicators/getIndicators', async (params, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.getIndicators(params);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getIndicatorOptions = createAsyncThunk<IIndicator[], IIndicatorParams>(
  'indicators/getIndicatorOptions',
  async (params, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getIndicatorOptions(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getIndicatorInformationById = createAsyncThunk<IIndicator, string>(
  'indicators/getIndicatorInformationById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getIndicatorInformationById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getIndicatorThresholdById = createAsyncThunk<IIndicator, string>(
  'indicators/getIndicatorThresholdById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getIndicatorThresholdById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createMonitoringType = createAsyncThunk<
  IMonitoringType,
  Pick<IFormIndicator, 'name' | 'description' | 'symbol'>
>('indicators/createMonitoringType', async (data, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.createMonitoringType(data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateMonitoringType = createAsyncThunk<
  IMonitoringType,
  { id: string; data: Pick<IFormIndicator, 'name' | 'description' | 'symbol'> }
>('indicators/updateMonitoringType', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.updateMonitoringType(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteMonitoringType = createAsyncThunk<string, string>(
  'indicators/deleteMonitoringType',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteMonitoringType(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMonitoringTypeArchive = createAsyncThunk<string, string>(
  'indicators/deleteMonitoringTypeArchive',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteMonitoringTypeArchive(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createMonitoringGroup = createAsyncThunk<
  IMonitoringGroup,
  Pick<IFormIndicator, 'name' | 'description' | 'symbol' | 'monitoringTypeId'>
>('indicators/createMonitoringGroup', async (data, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.createMonitoringGroup(data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateMonitoringGroup = createAsyncThunk<
  IMonitoringGroup,
  {
    id: string;
    data: Pick<IFormIndicator, 'name' | 'description' | 'symbol' | 'monitoringTypeId'>;
  }
>('indicators/updateMonitoringGroup', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.updateMonitoringGroup(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteMonitoringGroup = createAsyncThunk<string, string>(
  'indicators/deleteMonitoringGroup',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteMonitoringGroup(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteMonitoringGroupArchive = createAsyncThunk<string, string>(
  'indicators/deleteMonitoringGroupArchive',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteMonitoringGroupArchive(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createIndicatorInfo = createAsyncThunk<IIndicator, Omit<IFormIndicator, 'thresholds'>>(
  'indicators/createIndicatorInfo',
  async (data, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.createIndicatorInfo(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createIndicator = createAsyncThunk<IIndicator, IFormIndicator>(
  'indicators/createIndicator',
  async (data, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.createIndicator(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateIndicatorInformation = createAsyncThunk<
  IIndicator,
  {
    id: string;
    data: IFormIndicator;
  }
>('indicators/updateIndicatorInformation', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.updateIndicatorInformation(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateIndicatorThreshold = createAsyncThunk<
  IIndicator,
  {
    id: string;
    data: IFormIndicator;
  }
>('indicators/updateIndicatorThreshold', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await indicatorsApi.updateIndicatorThreshold(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteIndicator = createAsyncThunk<string, string>(
  'indicators/deleteIndicator',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteIndicator(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteIndicatorArchive = createAsyncThunk<string, string>(
  'indicators/deleteIndicatorArchive',
  async (id, { rejectWithValue }) => {
    try {
      await indicatorsApi.deleteIndicatorArchive(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getDefaultThresholdStation = createAsyncThunk<
  IDefaultThresholdStation,
  {
    indicatorId: string;
    monitoringGroupId: string;
  }
>(
  'indicators/getDefaultThresholdStation',
  async ({ indicatorId, monitoringGroupId }, { rejectWithValue }) => {
    try {
      const res = await indicatorsApi.getDefaultThresholdStation(indicatorId, monitoringGroupId);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getAllFilter = createAsyncThunk<IFilter, string>(
  'indicators/getAllFilter',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await filterApi.getAllFilter(payload);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getFilterByMonitoringType = createAsyncThunk<IFilter, string>(
  'indicators/getFilterByMonitoringType',
  async (monitoringType, { rejectWithValue }) => {
    try {
      const res = await filterApi.getFilterByMonitoringType(monitoringType);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationsByMonitoringType = createAsyncThunk<
  {
    data: IStation[];
    totalData: number;
  },
  any
>('indicators/getStationsByMonitoringType', async (params, { rejectWithValue }) => {
  try {
    const res = await stationApi.getStationsListInfo(params);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
