import * as Icons from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'src/components/error_message';
import { FormAutocomplete } from 'src/components/hook_form';
import Loading from 'src/components/loading';
import { useIsRequestError, useIsRequestPending } from 'src/hooks';
import { useAppDispatch } from 'src/redux_store';
import { editCameraParams, getCameraParams } from 'src/redux_store/camera/camera_action';

import { ICamera, ICameraParams } from 'src/types/camera';
import { convertMinMaxToArray } from 'src/utils/function';
import { toastMessage } from 'src/utils/toast';
import PTZControl from './ptz_control';
import { useStyles } from './styles';

type TOptionValue = {
  label: string;
  value: number;
};

type TFormState = {
  stream: ICameraParams | null;
  resolution: { width: number; height: number; label: string } | null;
  quality: TOptionValue | null;
  fps: TOptionValue | null;
};

interface IProps {
  camera: ICamera;
  deleteCamera: () => void;
  refreshCameras?: () => void;
}

const CameraParams = ({ camera, deleteCamera, refreshCameras }: IProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const isError = useIsRequestError('camera', 'getCameraParams');
  const isUpdate = useIsRequestPending('camera', 'editCameraParams');
  const isDelete = useIsRequestPending('camera', 'deleteCamera');

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [streams, setStreams] = React.useState<ICameraParams[]>([]);
  const [resolutionRange, setResolutionRange] = React.useState<
    { width: number; height: number; label: string }[]
  >([]);
  const [qualityRange, setQualityRange] = React.useState<TOptionValue[]>([]);
  const [fpsRange, setFpsRange] = React.useState<TOptionValue[]>([]);

  const { control, handleSubmit, getValues, reset, setValue } = useForm<TFormState>({
    defaultValues: {
      stream: null,
      resolution: null,
      quality: null,
      fps: null,
    },
  });

  const setOptionsParams = (stream: ICameraParams) => {
    const quality = stream.qualityRange;
    const qualityRange = convertMinMaxToArray(quality.Min, quality.Max);
    setQualityRange(qualityRange);
    const fps = stream.fpsRange;
    const fpsRange = convertMinMaxToArray(fps.Min, fps.Max);
    setFpsRange(fpsRange);
    const resolutionRange =
      stream.resolutionRange.map((resolution) => ({
        width: resolution.width,
        height: resolution.height,
        label: `${resolution.width}x${resolution.height}`,
      })) || [];
    setResolutionRange(resolutionRange);

    setValue('fps', {
      label: String(stream.fps),
      value: stream.fps,
    });
    setValue('resolution', {
      width: stream.resolution.width,
      height: stream.resolution.height,
      label: `${stream.resolution.width}x${stream.resolution.height}`,
    });
    setValue('quality', {
      label: String(stream.quality),
      value: stream.quality,
    });
  };

  const fetchApi = async () => {
    if (!camera.id) return;
    setIsLoading(true);
    const params = await dispatch(getCameraParams(camera.id)).unwrap();

    setStreams(params || []);
    if (params.length > 0) {
      const stream = params.shift() || null;
      setValue('stream', stream);
      if (stream) {
        setOptionsParams(stream);
      }
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchApi();

    return () => {
      reset();
      setStreams([]);
      setResolutionRange([]);
      setFpsRange([]);
      setQualityRange([]);
    };
  }, [camera.id]);

  const handleChange = (name: string) => {
    const { stream } = getValues();

    if (name === 'stream' && !_.isEmpty(stream)) {
      setOptionsParams(stream);
    }
  };

  const onSubmit = (data: TFormState) => {
    const { stream, quality, resolution, fps } = data;
    if (camera.id && stream && quality && resolution && fps) {
      dispatch(
        editCameraParams({
          id: camera.id,
          data: {
            ...data.stream,
            name: stream.name,
            token: stream.token,
            resolutionRange: stream.resolutionRange,
            fps: fps.value,
            quality: quality.value,
            qualityRange: {
              Max: Number(stream.qualityRange.Max),
              Min: Number(stream.qualityRange.Min),
            },
            fpsRange: {
              Max: Number(stream.fpsRange.Max),
              Min: Number(stream.fpsRange.Min),
            },
            resolution: {
              width: resolution.width,
              height: resolution.height,
            },
          },
        }),
      )
        .unwrap()
        .then(() => {
          toastMessage.success('Cập nhật camera thành công');
          if (refreshCameras) {
            refreshCameras();
          }
        })
        .catch(() => toastMessage.error('Cập nhật camera không thành công'));
    }
  };

  const renderContent = () => {
    if (isLoading) return <Loading />;

    if (isError) return <ErrorMessage onClick={fetchApi} />;

    return (
      <Scrollbars>
        <Box p={1}>
          {camera.isSharedLink && (
            <Typography mb={1} fontWeight="500" component="h6" variant="body1">
              Đây là camera được chia sẻ thông qua link RTSP nên không thể cấu hình thông số camera
            </Typography>
          )}
          <Grid container>
            <Grid item xs={12}>
              <FormAutocomplete
                control={control}
                name="stream"
                label="Luồng stream"
                options={streams}
                labelOption="name"
                keyOption="token"
                disableClearable
                handleChange={handleChange}
                disabled={isUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                control={control}
                name="resolution"
                label="Độ phân giải"
                options={resolutionRange}
                labelOption="label"
                keyOption="label"
                disableClearable
                handleChange={handleChange}
                disabled={camera.isSharedLink || isUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutocomplete
                control={control}
                name="fps"
                label="FPS"
                options={fpsRange}
                labelOption="label"
                keyOption="value"
                disableClearable
                handleChange={handleChange}
                disabled={camera.isSharedLink || isUpdate}
              />
            </Grid>
            {!camera.isSharedLink && (
              <Grid item xs={12}>
                <FormAutocomplete
                  control={control}
                  name="quality"
                  label="Chất lượng"
                  options={qualityRange}
                  labelOption="label"
                  keyOption="value"
                  disableClearable
                  handleChange={handleChange}
                  disabled={camera.isSharedLink || isUpdate}
                />
              </Grid>
            )}
          </Grid>

          {camera.isSupportPtz && <PTZControl camera={camera} />}
        </Box>
      </Scrollbars>
    );
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <Box flex={1}>{renderContent()}</Box>
      <Divider />
      <Box className={classes.button}>
        <LoadingButton
          type="button"
          variant="outlined"
          color="error"
          disabled={isUpdate}
          onClick={deleteCamera}
          startIcon={<Icons.Delete />}
          loading={isDelete}
        >
          Xóa camera
        </LoadingButton>

        <LoadingButton
          disabled={camera.isSharedLink || isUpdate}
          type="submit"
          variant="outlined"
          startIcon={<Icons.SaveOutlined />}
        >
          Lưu
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CameraParams;
