import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(() => ({
  titleModal: {
    textAlign: 'center',
    fontSize: '20px !important',
    fontWeight: '600 !important',
    padding: '8px 0',
  },
  content: {
    fontSize: '16px !important',
    fontWeight: '600 !important',
  },
  boxNoteIndicator: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    marginTop: '4px',
  },
  boxAQIAndWQI: {
    width: '100%',
    gap: '3px',
    display: 'flex',
    flexDirection: 'column',
  },
}));
