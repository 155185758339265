import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  IApiRequest,
  IApis,
  IGroup,
  IHeader,
  IPayloadUpdateBody,
  IParams,
  IGroupSearch,
  IPayloadUpdateRequest,
  IResponse,
} from 'src/types/apis';
import { apis_management } from 'src/clients/http/apis_management_api';

export const getGroups = createAsyncThunk<IGroup[], IGroupSearch>(
  'apisManagementSlice/getGroups',
  async (params, { rejectWithValue }) => {
    try {
      const res = await apis_management.getGroups(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const createGroup = createAsyncThunk<IGroup, IGroup>(
  'apisManagementSlice/createGroup',
  async (data, { rejectWithValue }) => {
    try {
      const res = await apis_management.createGroup(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateGroupById = createAsyncThunk<IGroup, { id: string; data: IGroup }>(
  'apisManagementSlice/updateGroupById',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await apis_management.updateGroupById(id, data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getGroupById = createAsyncThunk<IGroup, string>(
  'apisManagementSlice/getGroupById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await apis_management.getGroupById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const deleteGroupById = createAsyncThunk<any, string>(
  'apisManagementSlice/deleteGroupById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await apis_management.deleteGroupById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const createRequest = createAsyncThunk<IApis, IApiRequest>(
  'apisManagementSlice/createRequest',
  async (data, { rejectWithValue }) => {
    try {
      const res = await apis_management.createRequest(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
export const getRequestById = createAsyncThunk<IApis, string>(
  'apisManagementSlice/getRequestById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await apis_management.getRequestById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const deleteRequestById = createAsyncThunk<IApis, string>(
  'apisManagementSlice/deleteRequestById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await apis_management.deleteRequestById(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateRequestById = createAsyncThunk<
  IApis,
  {
    id: string;
    data: IPayloadUpdateRequest;
  }
>('apisManagementSlice/updateRequestById', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await apis_management.updateRequestById(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

// update type request
export const updateParamsByIdRequest = createAsyncThunk<IApis, { id: string; data: IParams[] }>(
  'apisManagementSlice/updateParamsByIdRequest',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await apis_management.updateParamsByIdRequest(id, data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateQueryParamsByIdRequest = createAsyncThunk<
  IApis,
  { id: string; data: IParams[] }
>('apisManagementSlice/updateQueryParamsByIdRequest', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await apis_management.updateQueryParamsByIdRequest(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateHeadersByIdRequest = createAsyncThunk<IApis, { id: string; data: IHeader[] }>(
  'apisManagementSlice/updateHeadersByIdRequest',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await apis_management.updateHeadersByIdRequest(id, data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateBodyByIdRequest = createAsyncThunk<
  IApis,
  { id: string; data: IPayloadUpdateBody }
>('apisManagementSlice/updateBodyByIdRequest', async ({ id, data }, { rejectWithValue }) => {
  try {
    const res = await apis_management.updateBodyByIdRequest(id, data);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateResponseByIdRequest = createAsyncThunk<IApis, { id: string; data: IResponse[] }>(
  'apisManagementSlice/updateResponseByIdRequest',
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const res = await apis_management.updateResponseByIdRequest(id, data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
