import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
export const ButtonCustom = styled(Button)<ButtonProps>(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  minWidth: 'unset',
  '& .MuiButton-startIcon': {
    margin: 0,
  },
  '& .MuiButton-startIcon svg': {
    fontSize: 24,
  },
}));
