import { createSlice } from '@reduxjs/toolkit';
import { ISystemSetting } from 'src/types/system_setting';
import { getSystemSetting } from './system_setting_action';

interface IState {
  setting: ISystemSetting | null;
}

const initialState: IState = {
  setting: null,
};

const systemSettingSlice = createSlice({
  name: 'systemSetting',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSystemSetting.fulfilled, (state, action) => {
      state.setting = action.payload;
    });
  },
});

export default systemSettingSlice.reducer;
