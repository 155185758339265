import { CancelOutlined } from '@mui/icons-material';
import { Box, Dialog, Divider, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { closeModal } from 'src/redux_store/common/modal_slice';
import AccountDevice from './account_device';
import AccountInformation from './account_information';
import { useStyles } from './styles';

interface IProps {
  modalId: string;
}

const AccountManagement = ({ modalId }: IProps) => {
  const classes = useStyles();
  const modalState = useAppSelector((state) => state.modalSlice);
  const modal = modalState[modalId];
  const dispatch = useAppDispatch();

  const [tabValue, setTabValue] = React.useState<'information' | 'device'>('information');

  const handleChange = (event: React.SyntheticEvent, newValue: 'information' | 'device') => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    dispatch(
      closeModal({
        modalId,
      }),
    );
  };

  return (
    <Dialog
      disableRestoreFocus
      open={modal.open}
      scroll="body"
      onClose={handleClose}
      classes={{
        paper: classes.fullHeight,
      }}
    >
      <Box className={classes.body}>
        <Box className={classes.closeButton}>
          <IconButton onClick={handleClose}>
            <CancelOutlined fontSize="large" />
          </IconButton>
        </Box>

        <Box className={classes.tabLayout}>
          <Box>
            <Typography px={2} py={1.5} variant="h6" component="h2">
              Thiết lập tài khoản
            </Typography>
            <Box>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleChange}
              >
                <Tab
                  sx={{
                    alignItems: 'start',
                    textTransform: 'inherit',
                  }}
                  label="Thông tin"
                  value="information"
                />
                <Tab
                  sx={{
                    alignItems: 'start',
                    textTransform: 'inherit',
                  }}
                  className={classes.tabLabel}
                  label="Thiết bị"
                  value="device"
                />
              </Tabs>
            </Box>
          </Box>
        </Box>

        <Divider orientation="vertical" />

        <Box flex={1}>
          <Scrollbars>
            <Box width="67.5%" p={4}>
              {tabValue === 'information' && <AccountInformation />}
              {tabValue === 'device' && <AccountDevice />}
            </Box>
          </Scrollbars>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AccountManagement;