import { Box, Pagination } from '@mui/material';
import React from 'react';
import theme from 'src/theme';

interface IProps {
  totalPage: number;
  page: number;
  handleChangePage: (page: number) => void;
  disabled?: boolean;
}

const CameraPagination = ({ page, handleChangePage, totalPage, disabled }: IProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handleChangePage(value);
  };
  return (
    <Box p={1} bgcolor={theme.palette.background.paper}>
      <Pagination
        disabled={disabled}
        count={totalPage}
        color="primary"
        page={page}
        onChange={handleChange}
      />
    </Box>
  );
};

export default CameraPagination;
