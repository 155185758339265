import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  form: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  accordion: {
    background: 'none !important',
    boxShadow: 'none !important',
  },
  details: {
    padding: '0 !important',
  },
  summary: {
    padding: '0 !important',
  },
  summaryContent: {
    margin: '0 !important',
  },
}));
