import config from 'src/config';
import store from 'src/redux_store';
import RTC from './rtc';
import { IRequestMessage, IResponseMessage, TViewerResponseStatus } from '../types/message';
import { debugLog, initDebugLog } from '../utils/log';

// const SOCKET_SERVER_URL = 'wss://camera.danateq.vn/signaling';

class Signaling {
  ws?: WebSocket;
  rtcList: Map<string, RTC>;
  disconnectNumber: number;
  debug?: boolean;
  timeoutId?: NodeJS.Timeout;

  constructor() {
    this.rtcList = new Map<string, RTC>();
    this.disconnectNumber = 0;
    this.debug = false;
  }

  get authState() {
    const { me, token } = store.getState().myAccountSlice;
    return { userId: me?.id, token };
  }

  connect = (debug?: boolean) => {
    this.debug = debug;
    initDebugLog(debug);

    debugLog({ 'ws connect': config.signalingUrl });
    this.ws = new WebSocket(`wss://${config.signalingUrl}`);

    this.ws.onopen = this.wsOnOpen;
    this.ws.onmessage = this.wsOnMessage;
    //this.ws.onerror = this.wsOnError;
    // this.ws.onclose = this.wsOnClose;
  };

  addRtc = (viewerId: string, rtc: RTC) => {
    this.rtcList.set(viewerId, rtc);
  };

  wsAuth = () => {
    this.sendMessage({
      token: this.authState.token as string,
      id: 'authen',
    });
  };

  wsOnOpen = () => {
    // debugLog({ 'Signaling.wsOnOpen.disconnectNumber': this.disconnectNumber });
    // if (!this.disconnectNumber) return;
    const [rtc] = Array.from(this.rtcList.values());
    rtc.initTrack();

    // this.disconnectNumber = 0;
  };

  wsOnMessage = (message: any) => {
    const parsedMessage: IResponseMessage = JSON.parse(message.data);
    const viewerId = parsedMessage.viewerId;

    const rtc = this.rtcList.get(viewerId as string);
    if (!rtc) return;

    switch (parsedMessage.id) {
      case 'camera':
        debugLog({ 'Signaling.wsOnMessage.switch.case.camera': rtc });
        rtc.createPcPeerConnection();
        break;
      case 'viewerAccepted':
        this.viewerResponse(parsedMessage, 'accepted');
        break;
      case 'viewerRejected':
        this.viewerResponse(parsedMessage, 'rejected');
        break;
      case 'iceCandidate':
        if (parsedMessage.iceCandidate) {
          const candidate = parsedMessage.iceCandidate;
          if (candidate) {
            if (!rtc.pc) return;
            if (rtc.pc.remoteDescription) {
              rtc.pc.addIceCandidate({
                candidate: candidate.candidate,
                sdpMid: candidate.sdpMid,
                sdpMLineIndex: candidate.sdpMLineIndex,
              });
            } else {
              rtc.iceCandidateQueue.push(candidate);
            }
          }
        }
        break;
      default:
        console.warn('unrecognized message', parsedMessage);
        break;
    }
  };

  wsOnError = (ev: Event) => {
    debugLog({ 'Signaling.wsOnError': ev });
    if (this.disconnectNumber >= 5) return this.close();

    this.timeoutId = setTimeout(() => {
      console.log(`Signaling reconnect ${this.disconnectNumber}`);
      this.connect(this.debug);
    }, 5000);

    this.disconnectNumber++;
    // this.dispose();
    // this.close();
  };

  wsOnClose = (ev: CloseEvent) => {
    debugLog({ 'WS.wsOnClose': ev });
  };

  viewerResponse = (message: IResponseMessage, status: TViewerResponseStatus) => {
    if (status === 'rejected') {
      const errorMsg = message.error ? message.error : 'Unknown error';
      console.warn('viewerResponse rejected: ', errorMsg);

      this.dispose(message.viewerId as string);
      return;
    }

    if (status === 'accepted') {
      if (!message.sdpAnswer) return;

      const sdpAnswer = message.sdpAnswer;
      const viewerId = message.viewerId;

      const rtc = this.rtcList.get(viewerId as string);
      rtc?.acceptOffer('answer', sdpAnswer);
    }
  };

  sendMessage = (message: IRequestMessage) => {
    const jsonMessage = JSON.stringify(message);

    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      // debugLog({ sendMessage: jsonMessage });
      this.ws.send(jsonMessage);
    } else {
      console.warn('ws.readyState != WebSocket.OPEN');
    }
  };

  dispose = (viewerId: string) => {
    this.close();
    const rtc = this.rtcList.get(viewerId as string);
    rtc?.close();
  };

  close = () => {
    debugLog({ 'WS.close': 'closed', id: this.timeoutId });
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.disconnectNumber = 0;
      this.timeoutId = undefined;
    }

    this.rtcList.clear();

    if (!this.ws || this.ws.readyState === WebSocket.CLOSED) return;
    this.ws.close();
  };
}

export const signaling = new Signaling();
export default Signaling;
