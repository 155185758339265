import React from 'react';
import MapController from 'src/components/map_controller';
import { GoogleMap } from '@react-google-maps/api';
import { ConfigMapTypes, MapOptionStyle } from 'src/constants';
import { useAppSelector, useGetStatus } from 'src/hooks';
import CameraMarkers from './camera_markers';

const CameraMap = () => {
  const { cameras } = useAppSelector((state) => state.cameraSlice);
  const [loading, error] = useGetStatus('camera', 'getCameras');
  return (
    <MapController>
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '100%',
        }}
        center={ConfigMapTypes.location}
        options={{
          styles: MapOptionStyle,
        }}
        zoom={12}
      >
        {loading || error ? null : <CameraMarkers cameras={cameras} />}
      </GoogleMap>
    </MapController>
  );
};

export default CameraMap;
