import React, { useEffect } from 'react';
import { AccordionDetails, AccordionSummary, Badge, Box, Typography } from '@mui/material';
import { ExpandMoreOutlined, LogoutOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import 'moment/locale/vi'
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { getMyDevice, revokeSession } from 'src/redux_store/my_device/my_device_actions';
import { toastMessage } from 'src/utils/toast';
import { useIsRequestPending } from 'src/hooks';
import Loading from 'src/components/loading';
import { useAppDispatch, useAppSelector } from "../../../redux_store";
import { useStyles } from './styles';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const momentLocale = (inp?: moment.MomentInput, strict?: boolean) => {
  moment.updateLocale('vi', {})
  return moment(inp, strict)
}

const AccountDevice = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isLoading = useIsRequestPending('myDevice', 'getMyDevice');
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const [loading, setLoading] = React.useState(false);
  const { me, token } = useAppSelector((state) => state.myAccountSlice);
  const { device } = useAppSelector((state) => state.myDeviceSlice);
  useEffect(() => {
    dispatch(getMyDevice(String(me?.id)));
  }, [])

  const handleRevokeSession = (sessionId: string) => {
    if (!me) return;
    setLoading(true);
    dispatch(revokeSession({ userId: me.id, sessionId })).unwrap().then(() => {
      setExpanded(false);
      setLoading(false);
      toastMessage.success("Đăng xuất thiết bị thành công")
    });
  }

  const checkIsInDay = (date2: Date) => {
    const now = new Date();
    const date = new Date(date2);

    const offset = now.getTime() - date.getTime();
    const totalDays = Math.round(offset / 1000 / 60 / 60 / 24);
    if (totalDays < 1) {
      return true
    } return false
  }
  return (
    <Box className={classes.body}>
      <Typography variant="h6" gutterBottom >
        Thiết bị
      </Typography>
      <Typography variant="body1" gutterBottom >
        Bạn nên nhận dạng từng hoạt động trong các hoạt động gần đây. Nếu có hoạt động không quen thuộc, hãy đăng xuất khỏi thiết bị đó.
      </Typography>
      <Box sx={{ paddingBottom: '32px' }}>
        {
          isLoading ? <Loading /> : device.map((data, index) => {
            return (
              <Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} className={classes.accord} key={index} sx={{ borderTop: "1px solid #ccc" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography sx={{ width: '33%', flexShrink: 0, }}>
                    {data.props.os}
                  </Typography>
                  {
                    data.token === token ? <Box className={classes.boxBadge}>
                      <Badge color="success" overlap="circular" badgeContent=" " variant="dot">
                      </Badge>
                      <Typography className={classes.bodyStyle} sx={{ marginLeft: '10px', color: 'green' }} variant="body2">Đang hoạt động </Typography>
                    </Box> : <Box className={classes.boxBadge}>
                      <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot">
                      </Badge>
                      {
                        checkIsInDay(data.lastActivityAt) ? <Typography className={classes.bodyStyle} sx={{ marginLeft: '10px', }} variant="body2">
                          {momentLocale(data.lastActivityAt).fromNow()}
                        </Typography> : <Typography className={classes.bodyStyle} sx={{ marginLeft: '10px', }} variant="body2">
                          {moment(data.lastActivityAt).format("DD-MM-YYYY HH:mm:SS")}</Typography>
                      }
                    </Box>
                  }

                  <Typography sx={{ marginLeft: '10px' }}>{data.props.platform}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordDetail}>
                  <Box className={classes.boxDetail}>
                    {
                      data.token === token ? <></> :
                        <LoadingButton loading={loading} variant="contained" size="small" sx={{ textTransform: 'none', fontSize: '13px', padding: '5px', fontWeight: 'bold', width: "60%" }} onClick={() => handleRevokeSession(data.id)}>
                          <LogoutOutlined />Đăng xuất</LoadingButton>
                    }
                  </Box>

                  <Box className={classes.boxDetail} >
                    <Typography variant="body2" className={classes.bodyStyle}>
                      {data.props.browser}
                    </Typography>
                    <Typography variant="body2" className={classes.bodyStyle}>
                      {data.props.ip}
                    </Typography>
                  </Box>
                  <Box className={classes.boxDetail} >
                    <Typography variant="body2" className={classes.bodyStyle} sx={{ marginLeft: '10px' }}>
                      ID:
                    </Typography>
                    <Typography variant="body2" className={classes.bodyStyle} sx={{ marginLeft: '10px' }}>
                      {data.id}
                    </Typography>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )
          })
        }

      </Box>
    </Box >
  );
};

export default AccountDevice;
