import { createAsyncThunk } from '@reduxjs/toolkit';
import { sessionApi } from 'src/clients/http/session_api';
import { IGetDevice } from 'src/types/my_account';

export const getMyDevice = createAsyncThunk<IGetDevice[], string>('myDevice/getMyDevice', async (id, { rejectWithValue }) => {
    try {
        const { data } = await sessionApi.getSessionList(id);
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const revokeSession = createAsyncThunk<string, {
    userId: string,
    sessionId: string,
}>(
    'myDevice/revokeSession',
    async ({ userId, sessionId }, { rejectWithValue }) => {
        try {
            const { data } = await sessionApi.revokeSession(userId, sessionId);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);   