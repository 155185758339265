import { createSlice } from '@reduxjs/toolkit';
import { IPagination } from 'src/types/common';
import {
  IPayloadFormSample,
  IPayloadGetSample,
  ISampleHistory,
  IStationsSample,
} from 'src/types/sample';
import { findIndexItem } from '../../utils/function';
import {
  deleteHistoryById,
  deleteInfoStationSample,
  editInfoStationSample,
  getHistorySampleByStation,
  getSampleList,
  getStationOptions,
  getStationSampleById,
} from './sample_actions';

interface IAutoSampleSlice {
  sampleData: IPagination<IStationsSample>;
  selectedId: string;
  sampleInfo: IPayloadFormSample;
  historySample: {
    data: ISampleHistory[];
    totalPage: number;
  };
  payload: IPayloadGetSample;
  typeTabSelected: 'general' | 'history';
  selectedIdHistory: string;
  selectedStationId: string;
  stationOptions: { id: string; name: string }[];
}

const initialState: IAutoSampleSlice = {
  sampleData: {
    data: [],
    totalData: 0,
  },
  selectedId: '',
  selectedStationId: '',
  sampleInfo: {
    host: '',
    password: '',
    port: 0,
    stationId: '',
    username: '',
    id: '',
    status: false,
  },
  historySample: {
    data: [],
    totalPage: 0,
  },
  payload: {
    page: 1,
    limit: 15,
    searchKeyword: '',
  },
  typeTabSelected: 'general',
  selectedIdHistory: '',
  stationOptions: [],
};

const autoSampleSlice = createSlice({
  name: 'autoSample',
  initialState,
  reducers: {
    setSelectedId(state, action) {
      state.selectedId = action.payload.id;
      state.selectedStationId = action.payload.stationId;
    },

    setSelectedIdHistory(state, action) {
      state.selectedIdHistory = action.payload;
    },

    getSampleListDetail(state, action) {
      const index = findIndexItem(state.sampleData.data, action.payload);
      state.sampleInfo = state.sampleData.data[index];
    },

    changePayloadSample(state, action) {
      state.payload = action.payload;
    },

    changeTypeTabDetail: (state, action) => {
      state.typeTabSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSampleList.fulfilled, (state, action) => {
      state.sampleData = action.payload;
    });
    builder.addCase(getHistorySampleByStation.fulfilled, (state, action) => {
      state.historySample = action.payload;
    });
    builder.addCase(deleteHistoryById.fulfilled, (state) => {
      const currentData = [...state.historySample.data];
      const newData = currentData.filter((item) => item.id !== state.selectedIdHistory);
      state.historySample.data = newData;
    });
    builder.addCase(getStationOptions.fulfilled, (state, action) => {
      state.stationOptions = action.payload;
    });
    builder.addCase(deleteInfoStationSample.fulfilled, (state) => {
      const currentData = [...state.sampleData.data];
      const newData = currentData.filter((item) => item.id !== state.selectedId);
      state.sampleData.data = newData;
    });
    builder
      .addCase(getStationSampleById.fulfilled, (state, action) => {
        state.sampleInfo = action.payload;
      })
      .addCase(editInfoStationSample.fulfilled, (state, action) => {
        const newData = action.meta.arg;

        const { id, payload } = newData;
        const currentData = [...state.sampleData.data];
        const indexSample = findIndexItem(state.sampleData.data, id);
        const currentSample = currentData[indexSample];

        const indexStation = findIndexItem(state.stationOptions, payload.stationId + '');
        const stationName = state.stationOptions[indexStation].name;

        state.sampleData.data[indexSample] = {
          ...currentSample,
          ...payload,
          stationName: stationName,
        };
      });
  },
});

const { actions, reducer } = autoSampleSlice;
export const {
  setSelectedId,
  getSampleListDetail,
  changePayloadSample,
  changeTypeTabDetail,
  setSelectedIdHistory,
} = actions;
export default reducer;
