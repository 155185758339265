import { createAsyncThunk } from '@reduxjs/toolkit';

import { toastMessage } from 'src/utils/toast';
import { cameraApi } from 'src/clients/http';
import {
  ICamera,
  ICameraConnect,
  ICameraFunction,
  ICameraLogs,
  ICameraParams,
  ICameraSearch,
} from 'src/types/camera';

export const createCamera = createAsyncThunk<ICamera, ICameraConnect>(
  'camera/createCamera',
  async (data, { rejectWithValue }) => {
    try {
      const res = await cameraApi.createCamera(data);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCameras = createAsyncThunk<
  {
    data: ICamera[];
    totalData: number;
  },
  ICameraSearch
>('camera/getCameras', async (params, { rejectWithValue }) => {
  try {
    const res = await cameraApi.getCameras(params);
    return res.data;
  } catch (error: any) {
    toastMessage.error(error.message);
    return rejectWithValue(error);
  }
});

export const getCameraConnect = createAsyncThunk<ICameraConnect, string>(
  'camera/getCameraConnect',
  async (id, { rejectWithValue }) => {
    try {
      const res = await cameraApi.getCameraConnect(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCameraParams = createAsyncThunk<ICameraParams[], string>(
  'camera/getCameraParams',
  async (id, { rejectWithValue }) => {
    try {
      const res = await cameraApi.getCameraParams(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCameraFunction = createAsyncThunk<ICameraFunction, string>(
  'camera/getCameraFunction',
  async (id, { rejectWithValue }) => {
    try {
      const res = await cameraApi.getCameraFunction(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const editCameraConnect = createAsyncThunk<
  ICameraConnect,
  { id: string; data: ICameraConnect }
>('camera/editCameraConnect', async ({ id, data }, { rejectWithValue }) => {
  try {
    await cameraApi.editCameraConnect(id, data);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const editCameraParams = createAsyncThunk<
  ICameraParams,
  { id: string; data: ICameraParams }
>('camera/editCameraParams', async ({ id, data }, { rejectWithValue }) => {
  try {
    await cameraApi.editCameraParams(id, data);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const editCameraFunction = createAsyncThunk<
  ICameraFunction,
  { id: string; data: ICameraFunction }
>('camera/editCameraFunction', async ({ id, data }, { rejectWithValue }) => {
  try {
    await cameraApi.editCameraFunction(id, data);
    return data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const deleteCamera = createAsyncThunk<string, string>(
  'camera/deleteCamera',
  async (id, { rejectWithValue }) => {
    try {
      const res = await cameraApi.deleteCamera(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getCameraLogs = createAsyncThunk<
  ICameraLogs[], {
    id: string,
    payload: {
      after: string,
      limit: number,
    }
  }
>('camera/editCameraConnect', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const res = await cameraApi.getCameraLogs(id, payload);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
