import React from 'react';
import { FullscreenExitOutlined, FullscreenOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from '../../../types/player';

function FullscreenToggle(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { actions, player, manager } = slice;

  const controlText = player.isFullscreen ? 'Thoát khỏi toàn màn hình' : 'Toàn màn hình';

  const handleClick = () => {
    const { video, rootElement } = manager;

    if (player.error || !video?.hls) return;

    actions.toggleFullscreen(rootElement, player);
  };

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top-end"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          {player.isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default FullscreenToggle;
