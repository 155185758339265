import React from 'react';
import { Box, Grid, Typography, Collapse, Fab, Menu, MenuItem, ListItemIcon } from '@mui/material';
import parse from 'html-react-parser';
import {
  DeleteOutlineOutlined,
  DoneOutlined,
  // ShareOutlined,
  // AssignmentIndOutlined,
  HighlightOffOutlined,
  InfoOutlined,
  MoreHorizOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material/';
import moment from 'moment';
import classNames from 'classnames';
import { INotification } from 'src/types/notification';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import BadgeAvatar from 'src/components/avatar/badge_avatar';
import { markItemNotification } from 'src/utils/notification';
import {
  deleteNotification,
  getAllNotification,
} from 'src/redux_store/notification/notification_action';
import { toastMessage } from 'src/redux_store/common/toast/toast_action';
import {
  // changeTotalPageAndTotalData,
  deleteNotificationLocal,
  incrementalNotificationList,
} from 'src/redux_store/notification/notification_slice';
import { MdOutlineNewReleases } from 'react-icons/md';

import { useStyles } from './style';
import theme from 'src/theme';

interface INotificationProps {
  index?: number;
  notification: INotification;
  isToastNotification?: boolean;
  expandedId?: string;
  idItemHover?: string;
  setExpandedId?: React.Dispatch<React.SetStateAction<string>>;
  setIdItemHover?: React.Dispatch<React.SetStateAction<string>>;
  handleItemClick?: (notification: INotification) => void;
}

const NotificationItem = (props: INotificationProps) => {
  const {
    notification,
    isToastNotification,
    expandedId,
    setExpandedId,
    idItemHover,
    setIdItemHover,
  } = props;

  const { payload, notificationList } = useAppSelector((state) => state.notificationSlice);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const handleExpendedItem = () => {
    if (setExpandedId) {
      if (expandedId !== notification.id) {
        setExpandedId(notification.id);
      } else {
        setExpandedId('');
      }
    }

    if (!isToastNotification) {
      if (!notification.isRead && expandedId !== notification.id) {
        markItemNotification(dispatch, 'read', notification.id);
      }
    }
  };

  const renderIconNotification = () => {
    if (!notification) return;
    const { level } = notification;
    if (level === 'ERROR') return <HighlightOffOutlined fontSize="large" color="error" />;
    if (level === 'INFO') return <InfoOutlined fontSize="large" color="primary" />;
    if (level === 'CRITICAL') return <MdOutlineNewReleases size={36} color="#ac430c" />;
    return <WarningAmberOutlined fontSize="large" color="warning" />;
  };

  // const handleOpenViewDetailNotification = () => {
  //   dispatch(
  //     openModal({
  //       modalId: MODAL_IDS.viewDetailNotification,
  //       dialogComponent: <ViewDetailEventNotification />,
  //     }),
  //   );
  // };

  // const handleViewDetail = () => {
  //   dispatch(toggleViewDetail({ open: true, type: notification.type }));
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (setIdItemHover) {
      setIdItemHover('');
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    handleClose();
  };

  const handleMoveOver = () => {
    if (setIdItemHover) {
      setIdItemHover(notification.id);
    }
  };

  const handleMoveOut = () => {
    handleClose();
  };

  const handleDeleteNotification = (e: any) => {
    setAnchorEl(null);
    if (setExpandedId) {
      setExpandedId('');
    }
    e.stopPropagation();
    dispatch(deleteNotificationLocal(notification.id));
    dispatch(deleteNotification(notification.id))
      .unwrap()
      .then(() => {
        toastMessage.success('Xóa thông báo thành công');
        dispatch(
          getAllNotification({
            ...payload,
            limit: 1,
            after: notificationList[notificationList.length - 1]?.id,
          }),
        )
          .unwrap()
          .then((data) => {
            dispatch(incrementalNotificationList({ data }));
          });
        // dispatch(changeTotalPageAndTotalData('minus'));
      });
  };
  const handleToggleMarkNotification = () => {
    setAnchorEl(null);
    const { isRead, id } = notification;
    if (isRead) {
      markItemNotification(dispatch, 'unread', id);
    } else {
      markItemNotification(dispatch, 'read', id);
    }
  };

  return (
    <Box
      className={classNames(classes.root, classes.flex, isToastNotification && classes.toast)}
      onMouseOver={handleMoveOver}
      onMouseOut={handleMoveOut}
    >
      <Box minHeight={20} width="100%" position="relative">
        <Grid
          container
          // alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Grid item md={11} onClick={() => handleExpendedItem()}>
            <Grid container alignItems="center">
              <Grid
                item
                md={2}
                padding="5px"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              >
                {renderIconNotification()}
              </Grid>
              <Grid item md={10}>
                <Box
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      width: '90%',
                      paddingLeft: '15px',
                    },
                  }}
                >
                  <Typography
                    // fontWeight={notification.isRead ? '400' : 'bold'}
                    // color={theme.palette.secondary.main}
                    className={!notification.isRead ? classes.hightLightText : classes.normalText}
                  >
                    {parse(notification.title.content || '')}
                  </Typography>
                </Box>

                <Grid
                  container
                  display={'flex'}
                  alignItems="center"
                  wrap="nowrap"
                  columnSpacing={0.5}
                >
                  <Grid item>
                    <Typography
                      variant="caption"
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          paddingLeft: '15px',
                        },
                      }}
                    >
                      {moment(notification.time).format('DD-MM-yyyy HH:mm:ss')}
                    </Typography>
                  </Grid>
                  {notification.description.content && (
                    <Grid item>
                      <InfoOutlined fontSize="small" color="primary" />
                    </Grid>
                  )}
                  {/* {!isToastNotification && (
              <Grid item>
                {notification?.referenceId && (
                  <Button variant="text" size="small" onClick={handleOpenViewDetailNotification}>
                    <Typography variant="caption">{t('common.topbar.button.review')}</Typography>
                  </Button>
                )}
              </Grid>
            )} */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={1}>
            <Grid container alignItems="flex-end" flexDirection="column">
              <Grid item display="flex" sx={{ marginTop: '11px', marginRight: '15px' }}>
                {!notification.isRead && !isToastNotification && (
                  <BadgeAvatar className={classNames(classes.customBadge, classes['SUCCESS'])} />
                )}
              </Grid>
              <Grid
                item
                sx={{
                  position: 'absolute',
                  bottom: 5,
                  right: '7px',
                  [theme.breakpoints.down('md')]: {
                    right: '5px',
                  },
                }}
              >
                {idItemHover && idItemHover === notification.id && (
                  <>
                    <Fab
                      className={classes.fab}
                      color="secondary"
                      aria-describedby="moreAction"
                      size="small"
                      onClick={handleClick}
                    >
                      <MoreHorizOutlined fontSize="small" />
                    </Fab>
                    <Menu
                      anchorEl={anchorEl}
                      id="account-menu"
                      open={open}
                      onClose={() => {
                        handleClosePopover;
                      }}
                      onClick={handleClosePopover}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: 'visible',
                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                          mt: 1.5,
                          ml: 1.2,
                          '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: 1,
                            mr: 0.5,
                          },
                          '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                      <MenuItem onClick={() => handleToggleMarkNotification()}>
                        <ListItemIcon>
                          <DoneOutlined fontSize="small" />
                        </ListItemIcon>
                        {notification.isRead ? 'Đánh dấu là chưa đọc' : 'Đánh dấu là đã đọc'}
                      </MenuItem>
                      <MenuItem onClick={(e) => handleDeleteNotification(e)}>
                        <ListItemIcon>
                          <DeleteOutlineOutlined fontSize="small" />
                        </ListItemIcon>
                        Xóa thông báo
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!isToastNotification && notification.description.content && (
        <Collapse in={expandedId === notification.id}>
          <Grid
            container
            alignItems="flex-start"
            justifyContent="flex-start"
            className={classes.collapeContent}
          >
            <Grid item md={12}>
              <Box p={1}>
                <Typography
                  // color={theme.palette.secondary.main}
                  className={!notification.isRead ? classes.hightLightText : classes.normalText}
                >
                  {parse(notification.description.content || '')}
                </Typography>
              </Box>
              <Box>
                <Grid container columnSpacing={1} justifyContent="flex-end" alignItems="center">
                  {/* <MarkNotificationItem
                    id={notification.id}
                    isRead={notification.isRead}
                  /> */}
                  {/* <Grid item>
                    <IconButtonTooltip
                      title={t('notification.button.review')}
                      icon={<RemoveRedEyeOutlined fontSize="medium" />}
                      size="medium"
                      style={{ color: theme.palette.secondary.main }}
                      onClick={() => handleViewDetail()}
                    />
                  </Grid> */}

                  {/* {notification.referenceId && (
                    <Grid item>
                      <IconButtonTooltip
                        title={t('notification.button.review')}
                        icon={<RemoveRedEyeOutlined fontSize="medium" />}
                        size="medium"
                        style={{ color: theme.palette.secondary.main }}
                        onClick={handleOpenViewDetailNotification}
                      />
                    </Grid>
                  )} */}
                  {/* <Grid item>
                    <IconButtonTooltip
                      title={t('notification.button.share')}
                      icon={<ShareOutlined fontSize="medium" />}
                      size="medium"
                      style={{ color: theme.palette.secondary.main }}
                      // onClick={handleOpenNotificationSetting}
                    />
                  </Grid>
                  <Grid item>
                    <IconButtonTooltip
                      title={t('notification.button.assign')}
                      icon={<AssignmentIndOutlined fontSize="medium" />}
                      size="medium"
                      style={{ color: theme.palette.secondary.main }}
                      // onClick={handleOpenNotificationSetting}
                    />
                  </Grid> */}
                  {/* <DeleteNotification
                    setExpandedId={setExpandedId}
                    id={notification.id}
                  /> */}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Collapse>
      )}
    </Box>
  );
};

export default NotificationItem;
