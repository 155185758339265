import { IGetData, IPayloadApproveData, IPayloadEditData, IPayloadGetData } from 'src/types/data';


import { createClient } from './axios_client';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const dataApi = {
    getData: (payload: IPayloadGetData) => {
        const newPayload = {
            dataType: payload.dataType,
            startTime: payload.startTime,
            endTime: payload.endTime,
            limit: payload.limit,
            page: payload.page,
        };
        return client.post<IGetData>(
            `/monitoring_data/${payload.stationId}`,
            newPayload,
        );
    },
    approveData: (payload: IPayloadApproveData) => {
        return client.put<any>('/monitoring_data/approve_data', payload);
    },
    deleteData: (data: { bucketId: string, dataId: string }) => {
        return client.delete<any>('/monitoring_data', {
            data
        });
    },
    editData: (payload: IPayloadEditData) => {
        return client.put<any>('/monitoring_data', payload);
    },
};


