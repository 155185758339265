import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IFormIndicator,
  IIndicator,
  IMonitoringGroup,
  IMonitoringType,
} from 'src/types/indicators';
import { IStation } from 'src/types/station';

import {
  getIndicatorInformationById,
  getIndicators,
  getIndicatorThresholdById,
  getMonitoringGroupById,
  getMonitoringGroups,
  getMonitoringTypeById,
  getMonitoringTypes,
  getStationsByMonitoringType,
  updateIndicatorInformation,
  updateIndicatorThreshold,
  updateMonitoringGroup,
  updateMonitoringType,
} from './indicators_action';

interface IState {
  monitoringTypes: {
    data: IMonitoringType[];
    detail: IMonitoringType;
    selectedId: string;
    typeTabSelected: string;
  };
  monitoringGroup: {
    data: IMonitoringGroup[];
    detail: IMonitoringGroup;
    totalData: number;
    selectedId: string;
    isShowAction: boolean;
  };
  indicator: {
    data: IIndicator[];
    detail: IIndicator;
    totalData: number;
    selectedId: string;
    createData: IFormIndicator;
  };
  stations: {
    data: IStation[];
    totalData: number;
  };
  payload: {
    page: number;
    limit: number;
  };
}

const initialState: IState = {
  monitoringTypes: {
    data: [],
    detail: <IMonitoringType>{},
    selectedId: '',
    typeTabSelected: 'information',
  },
  monitoringGroup: {
    data: [],
    totalData: 0,
    detail: <IMonitoringGroup>{},
    selectedId: '',
    isShowAction: true,
  },
  indicator: {
    data: [],
    totalData: 0,
    detail: <IIndicator>{},
    selectedId: '',
    createData: {
      name: '',
      symbol: '',
      monitoringTypeId: '',
      description: '',
      unit: '',
      thresholds: [],
    },
  },
  stations: {
    data: [],
    totalData: 0,
  },
  payload: {
    page: 1,
    limit: 15,
  },
};

const indicatorsSlice = createSlice({
  name: 'indicators',
  initialState,
  reducers: {
    setCreateData: (state, action: PayloadAction<IFormIndicator | 'reset'>) => {
      if (action.payload === 'reset') {
        state.indicator.createData = initialState.indicator.createData;
      } else {
        state.indicator.createData = action.payload;
      }
    },

    setSelected: (
      state,
      action: PayloadAction<{
        key: 'monitoringTypes' | 'monitoringGroup' | 'indicator';
        id: string;
      }>,
    ) => {
      const { key, id } = action.payload;
      switch (key) {
        case 'monitoringTypes':
          state.monitoringTypes.selectedId = id;
          break;
        case 'monitoringGroup':
          state.monitoringGroup.selectedId = id;
          break;
        case 'indicator':
          state.indicator.selectedId = id;
          break;
      }
    },

    resetState: (
      state,
      action: PayloadAction<
        'monitoringTypes' | 'monitoringGroup' | 'indicator' | 'stations' | undefined
      >,
    ) => {
      const key = action.payload;
      switch (key) {
        case 'stations':
          state.stations = initialState.stations;
          break;

        case 'monitoringTypes':
          state.monitoringTypes = initialState.monitoringTypes;
          state.stations = initialState.stations;
          state.payload = initialState.payload;
          break;
        case 'monitoringGroup':
          state.monitoringGroup = initialState.monitoringGroup;
          state.stations = initialState.stations;
          state.payload = initialState.payload;
          break;
        case 'indicator':
          state.indicator = initialState.indicator;
          break;
        default: {
          state.monitoringTypes = initialState.monitoringTypes;
          state.monitoringGroup = initialState.monitoringGroup;
          state.indicator = initialState.indicator;
          state.stations = initialState.stations;
        }
      }
    },

    changeTypeTabDetail: (state, action) => {
      state.monitoringTypes = action.payload;
    },
    changePayloadTypes(state, action) {
      state.payload = action.payload;
    },
    setIsShowAction: (state, action) => {
      state.monitoringGroup.isShowAction = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMonitoringTypes.fulfilled, (state, action) => {
      state.monitoringTypes.data = action.payload;
    });

    builder.addCase(getMonitoringTypeById.fulfilled, (state, action) => {
      state.monitoringTypes.detail = action.payload;
    });

    builder.addCase(getMonitoringGroups.fulfilled, (state, action) => {
      const { data, totalData } = action.payload;
      state.monitoringGroup.data = data;
      state.monitoringGroup.totalData = totalData;
    });

    builder.addCase(getMonitoringGroupById.fulfilled, (state, action) => {
      state.monitoringGroup.detail = action.payload;
    });

    builder.addCase(getIndicators.fulfilled, (state, action) => {
      const { data, totalData } = action.payload;
      state.indicator.data = data;
      state.indicator.totalData = totalData;
    });

    builder.addCase(getIndicatorInformationById.fulfilled, (state, action) => {
      state.indicator.detail = action.payload;
    });

    builder.addCase(getIndicatorThresholdById.fulfilled, (state, action) => {
      state.indicator.detail = action.payload;
    });

    builder.addCase(updateMonitoringType.fulfilled, (state, action) => {
      const data = [...state.monitoringTypes.data];
      const index = data.findIndex((item) => item.id === action.payload.id);
      data.splice(index, 1, action.payload);

      state.monitoringTypes.data = data;
    });

    builder.addCase(updateMonitoringGroup.fulfilled, (state, action) => {
      const data = [...state.monitoringGroup.data];
      const index = data.findIndex((item) => item.id === action.payload.id);
      data.splice(index, 1, action.payload);

      state.monitoringGroup.data = data;
    });

    builder.addCase(updateIndicatorInformation.fulfilled, (state, action) => {
      const data = [...state.indicator.data];
      const index = data.findIndex((item) => item.id === action.payload.id);
      data.splice(index, 1, action.payload);

      state.indicator.data = data;
    });

    builder.addCase(updateIndicatorThreshold.fulfilled, (state, action) => {
      const data = [...state.indicator.data];
      const index = data.findIndex((item) => item.id === action.payload.id);
      data.splice(index, 1, action.payload);

      state.indicator.data = data;
    });
    builder.addCase(getStationsByMonitoringType.fulfilled, (state, action) => {
      const data = state.stations.data.concat(action.payload.data);
      state.stations.data = data;
      state.stations.totalData = action.payload.totalData;
    });
  },
});

export const {
  resetState,
  setSelected,
  setCreateData,
  changeTypeTabDetail,
  changePayloadTypes,
  setIsShowAction,
} = indicatorsSlice.actions;

export default indicatorsSlice.reducer;
