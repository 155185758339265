import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  loading: {
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: -30,
    marginTop: -30,
    display: "none",
  },
  load: {
    display: "block",
  },
  noLoad: {
    display: "none",
  },
}));
