import React, { useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

import stationIcon from 'src/assets/icons/station_marker.png';
import CameraInfoWindow from '../camera_info_window';
import { ICamera } from 'src/types/camera';

function CameraMarkers({ cameras }: { cameras: ICamera[] }) {
  const [selectedId, setSelectedId] = useState<string>('');
  const [markerLoaded, setMarkerLoaded] = useState(false);

  const handleClick = (camera: ICamera) => {
    setSelectedId(camera.id);
  };

  const handleClose = () => {
    setSelectedId('');
  };

  const _onLoad = () => {
    setMarkerLoaded(true);
  };

  if (!cameras.length) return null;

  return (
    <>
      {cameras.map((camera) => {
        if (camera.lat && camera.lng)
          return (
            <Marker
              key={camera.id}
              position={{
                lat: camera.lat,
                lng: camera.lng,
              }}
              icon={stationIcon}
              onClick={() => handleClick(camera)}
              onLoad={_onLoad}
            >
              {markerLoaded && selectedId === camera.id && (
                <InfoWindow onCloseClick={handleClose}>
                  <CameraInfoWindow cameras={cameras} camera={camera} />
                </InfoWindow>
              )}
            </Marker>
          );
      })}
    </>
  );
}

export default CameraMarkers;
