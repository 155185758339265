import {
  IPayloadGetLatestData,
  ILatestData,
  IGetDetailIndicatorLatestData,
} from 'src/types/latest_data';
import { createClient } from './axios_client';

const client = createClient('http://10.49.46.53:5000/api/v1');
// const client = createClient('https://qamt.danateq.vn/api/v1');

export const latestDataApi = {
  getLatestData: (payload: IPayloadGetLatestData) => {
    return client.get<{ data: ILatestData[]; totalData: number }>('/latest_data', {
      params: payload,
    });
  },

  getIndicatorDetailInfo: ({ stationId, symbol }: { stationId: string; symbol: string }) => {
    return client.get<IGetDetailIndicatorLatestData>(`/stations/${stationId}/indicator/${symbol}`);
  },

  getStationDataLast: (stationId: string) => {
    return client.get<ILatestData>(`/latest_data/${stationId}`);
  },
};
