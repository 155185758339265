import { IGetDevice } from 'src/types/my_account';
import { createClient } from './axios_client';

const client = createClient('https://10.49.46.54:9000/api/v1');

export const sessionApi = {
  getSessionList: (userId: string) => {
    return client.get<IGetDevice[]>(`/users/${userId}/sessions`);
  },
  revokeSession: (userId: string, sessionId: string) => {
    return client.post<any>(`/users/${userId}/sessions/revoke`, { sessionId });
  },
};
