import {
  IChangeNotificationSound,
  IChangeNotificationSource,
  IChangePopupStatus,
  INotification,
  INotificationSetting,
  IPayloadNotification,
} from 'src/types/notification';

import qs from 'query-string';
import { createClient } from './axios_client';

const client = createClient('http://10.49.46.54:9002/api/v1');

// const token = '1rpKvVBYCCgwO3LMo9Ufug5vLI7SzIn2PLGlIjtm';

export const notificationApi = {
  getAllNotification: (payload: IPayloadNotification) => {
    const param = qs.stringify(payload, { arrayFormat: 'comma' });
    return client.get<INotification[]>(`/notifications?${param}`);
  },

  getInfoNotification: (id: string) => {
    return client.get<INotification>(`/notifications/${id}`);
  },

  markNotificationSeen: (id: string) => {
    return client.get<string>(`/notifications/${id}/seen`);
  },
  markNotificationRead: (id: string) => {
    return client.get<string>(`/notifications/${id}/read`);
  },
  markNotificationUnread: (id: string) => {
    return client.get<string>(`/notifications/${id}/unread`);
  },
  deleteNotification: (id: string) => {
    return client.delete<string>(`/notifications/${id}`);
  },

  getNotificationSetting: () => {
    return client.get<INotificationSetting>('/notifications/settings');
  },

  changeNotificationSound: (payload: IChangeNotificationSound) => {
    return client.patch<any>('/notifications/settings', payload);
  },
  changeNotificationSource: (payload: IChangeNotificationSource) => {
    return client.patch<any>('/notifications/settings', payload);
  },

  changeNotificationPopup: (payload: IChangePopupStatus) => {
    return client.patch<any>('/notifications/settings', payload);
  },

  unseenNotification: () => {
    return client.get<{ numberOfNotifications: number }>('notifications/unseen');
  },
  markAllAsSeen: () => {
    return client.get<any>('/notifications/mark_all_as_seen');
  },
};
