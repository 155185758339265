import { latestDataApi } from './../../clients/http/latest_data';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  IPayloadGetLatestData,
  IGetDetailIndicatorLatestData,
  ILatestData,
  IStationInfo,
} from 'src/types/latest_data';
import { stationApi } from 'src/clients/http';
import { fileApi } from 'src/clients/http/file_api';

export const getStationDetailInfo = createAsyncThunk<IStationInfo, string>(
  'latestData/getStationDetailInfo',
  async (id, { rejectWithValue }) => {
    try {
      const res = await stationApi.getStationInfo(id);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getStationDataLast = createAsyncThunk<ILatestData, string>(
  'latestData/getStationDataLast',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await latestDataApi.getStationDataLast(payload);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getLatestData = createAsyncThunk<
  { data: ILatestData[]; totalData: number },
  IPayloadGetLatestData
>('latestData/getLatestData', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await latestDataApi.getLatestData(payload);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getIndicatorDetailInfo = createAsyncThunk<
  IGetDetailIndicatorLatestData,
  { stationId: string; symbol: string }
>('latestData/getIndicatorDetailInfo', async (payload, { rejectWithValue }) => {
  try {
    const { data } = await latestDataApi.getIndicatorDetailInfo(payload);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileImageOfStation = createAsyncThunk<{ id: string; path: string }[], string>(
  'latestData/getFileImageOfStation',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await fileApi.getFileImageOfStation(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
