import { createSlice } from '@reduxjs/toolkit';
import { IPost, IPostSearch } from 'src/types/post';
import { deletePostById, addNewPosts, editPostById, getAllPosts } from './post_action';

interface IState {
  imageIdList: string[];
  postList: IPost[];
  totalData: number;
  payload: IPostSearch;
}

const initialState: IState = {
  imageIdList: [],
  postList: [],
  totalData: 0,
  payload: {
    limit: 10,
    page: 1,
    published: '',
    searchKeyword: '',
    startTime: '',
    endTime: '',
  },
};

const postSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    changePayload: (state, action: { payload: IPostSearch }) => {
      const newPayload = action.payload;
      state.payload = newPayload;
    },
    resetPayload: (state) => {
      state.payload = initialState.payload;
    },
    addIdImage: (state, action) => {
      state.imageIdList.push(action.payload);
    },
    deleteAllImageId: (state) => {
      state.imageIdList = [];
    },
  },
  extraReducers: (builder) => {
    // Post
    builder.addCase(getAllPosts.fulfilled, (state, action) => {
      const { data, totalData } = action.payload;
      state.postList = data;
      state.totalData = totalData;
    });
    builder.addCase(addNewPosts.fulfilled, (state, action) => {
      state.postList = [action.payload, ...state.postList];
    });

    builder.addCase(editPostById.fulfilled, (state, action) => {
      const index = state.postList.findIndex((e) => e.id === action.payload.id);
      if (index !== -1) {
        state.postList.splice(index, 1, action.payload);
      }
    });
    builder.addCase(deletePostById.fulfilled, (state, action) => {
      state.postList = state.postList.filter((e) => e.id !== action.payload);
      state.totalData = state.totalData - 1;
    });
  },
});

export const { changePayload, addIdImage, deleteAllImageId, resetPayload } = postSlice.actions;

export default postSlice.reducer;
