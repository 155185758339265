import React from 'react';
import { Box, Typography } from '@mui/material';
import { BiCctv } from 'react-icons/bi';
import theme from 'src/theme';
import { ICamera } from 'src/types/camera';
import { ECameraStatus } from 'src/types/enum';
import useStyles from './styles';
import Scrollbars from 'react-custom-scrollbars-2';

interface ICameraInfoWindow {
  camera: ICamera;
  cameras: ICamera[];
}

const renderIconColor = (status: ECameraStatus) => {
  if (status === ECameraStatus.OFFLINE) return theme.palette.secondary.main;
  if (status === ECameraStatus.NORMAL) return theme.palette.success.main;
  if (status === ECameraStatus.ERROR) return theme.palette.error.main;
  if (status === ECameraStatus.WARNING) return theme.palette.warning.main;
};

const CameraInfoWindow = ({ camera, cameras }: ICameraInfoWindow) => {
  const classes = useStyles();

  const renderCameraList = () => {
    const cameraList = cameras.filter((cam) => cam.stationName === camera.stationName);
    return (
      <Box mt={1} height={150} width={450}>
        <Scrollbars>
          <Box display="flex" flexWrap="wrap">
            {cameraList.length ? (
              cameraList.map((camera: ICamera, index: number) => (
                <Box key={index} className={classes.cameraItem}>
                  <BiCctv color={renderIconColor(camera.status)} size={26} />
                  <Typography
                    flex={1}
                    sx={{ fontWeight: 500, fontSize: 14, overflow: 'hidden' }}
                    title={camera.name}
                    textOverflow="ellipsis"
                    noWrap
                  >
                    {camera.name}
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body2" textAlign="center">
                Không có camera nào
              </Typography>
            )}
          </Box>
        </Scrollbars>
      </Box>
    );
  };

  return (
    <Box pr={2} pb={1} textAlign="center">
      <Typography component="h6" fontWeight={600}>
        {camera.stationName}
      </Typography>

      {renderCameraList()}
    </Box>
  );
};

export default CameraInfoWindow;
