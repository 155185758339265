import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
  container: {
    overflow: 'initial !important',
    height: '100% !important',
    margin: '0px !important',
    maxWidth: '100% !important',
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '100% !important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100% !important',
    },
  },
  body: {
    width: '90%',
    hight: '100px',
    marginLeft: '5%',
    [theme.breakpoints.down('md')]: {
      width: '130% !important',
    },
    [theme.breakpoints.up('md')]: {
      width: '115% !important',
    },
    [theme.breakpoints.up('lg')]: {
      width: '90% !important',
    },
  },
  box_Information: {
    marginTop: '10px',
    width: '100%',
    height: '380px !important',
  },
  buttonPassword: {
    backgroundColor: '#3e50b4',
    color: '#fff',
    alignItems: 'center',
    outline: 'none',
    border: '1px solid',
    cursor: 'pointer',
    margin: '10px 0 !important',
  },
  text: {
    marginTop: '0',
    marginBottom: '1px',
    color: '#3e5060',
    fontSize: '15px !important',
    lineHeight: '20px',
  },
}));
