import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useAppSelector } from 'src/hooks';
import NotificationSettingSourceItem from '../notification_setting_source_item';

const NotificationSettingSource = () => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const {
    notificationSetting: { notificationSources },
  } = useAppSelector((state) => state.notificationSlice);

  return (
    <Box>
      {notificationSources?.map((source, index) => {
        return (
          <NotificationSettingSourceItem
            key={index}
            expanded={expanded}
            index={index}
            setExpanded={setExpanded}
            settingItem={source}
          />
        );
      })}
    </Box>
  );
};

export default NotificationSettingSource;
