import { ICamera, ICameraConnect, ICameraParams, ICameraSearch } from 'src/types/camera';
import {
  IRtcStream,
  IStreamCreate,
  IStreamFilterRequest,
  IStreamFilterResponse,
} from 'src/types/stream';
import { createClient } from './axios_client';

const client = createClient('https://devmt.danateq.vn/api/v1');

export const streamApi = {
  getFollowGroups: () => client.get<{ id: string; name: string }[]>('/classified_list'),

  addFollowGroup: (listName: string) =>
    client.post<{ id: string; name: string }>('/classified_list', { listName }),

  deleteFollowGroup: (id: string) => client.delete<string>(`/classified_list/${id}`),

  getLiveStreamingCams: (data: IStreamFilterRequest, cameraFilter?: ICameraSearch) => {
    let params: any = {
      page: data.page,
      limit: data.listSize,
    };

    if (cameraFilter) {
      params = { ...cameraFilter, ...params };
    }

    return client.get<IStreamFilterResponse>(`/classified_list/${data.groupId}/cameras`, {
      params,
    });
  },

  addSingleCamToWatchlist: (data: IStreamCreate) =>
    client.post<IRtcStream>(`/classified_list/${data.groupId}/cameras`, data.data),

  removeSingleCamFromWatchlist: (position: number, groupId: string) =>
    client.delete(`/classified_list/${groupId}/position/${position}`),

  createCamera: (data: ICameraConnect) => {
    return client.post<ICamera>('/camera', data);
  },
  getCameraConnect: (id: string) => {
    return client.get<ICameraConnect>(`/camera/${id}/connect`);
  },
  getCameraParams: (id: string) => {
    return client.get<ICameraParams>(`/camera/${id}/params`);
  },

  editCameraConnect: (id: string, data: ICameraConnect) => {
    return client.put<ICameraConnect>(`/camera/${id}/connect`, data);
  },
  editCameraParams: (id: string, data: ICameraParams) => {
    return client.put<ICameraParams>(`/camera/${id}/params`, data);
  },
};
