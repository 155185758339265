import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import TabLayout from 'src/layout/tab_layout';
import theme from 'src/theme';
import { ICamera } from 'src/types/camera';
import { ITabItem } from 'src/types/common';
import CameraInfo from './camera_info';
import CameraParams from './camera_params';
import CameraLogs from './camera_logs';
import { useStyles } from './styles';
import CameraFunction from './camera_function';
import ConfirmationDialog from 'src/components/modal/confirm_dialog';
import { CModalIds } from 'src/constants';
import { toastMessage } from 'src/utils/toast';
import { useAppDispatch } from 'src/redux_store';
import { closeModal, openModal } from 'src/redux_store/common/modal_slice';
import { deleteCamera } from 'src/redux_store/camera/camera_action';

interface IProps {
  camera: ICamera;
  handleClose: () => void;
  refreshCameras?: () => void;
}

const CameraDetail = ({ camera, handleClose, refreshCameras }: IProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const deleteCameraAction = () => {
    if (camera.id) {
      dispatch(deleteCamera(camera.id))
        .unwrap()
        .then(() => {
          toastMessage.success('Xóa camera thành công!');
          handleClose();
          dispatch(closeModal({ modalId: CModalIds.deleteCamera }));
          if (refreshCameras) {
            refreshCameras();
          }
        });
    }
  };

  const handleDeleteCamera = () => {
    dispatch(
      openModal({
        modalId: CModalIds.deleteCamera,
        modalComponent: (
          <ConfirmationDialog
            callback={deleteCameraAction}
            modalId={CModalIds.deleteCamera}
            sliceName="camera"
            functionName="deleteCamera"
            describe={'Bạn có muốn xóa camera này!'}
          />
        ),
      }),
    );
  };

  const tabList: ITabItem[] = [
    {
      key: '1',
      label: 'Thông tin',
      component: (
        <CameraInfo
          camera={camera}
          deleteCamera={handleDeleteCamera}
          refreshCameras={refreshCameras}
        />
      ),
    },
    {
      key: '2',
      label: 'Tham số',
      component: (
        <CameraParams
          camera={camera}
          deleteCamera={handleDeleteCamera}
          refreshCameras={refreshCameras}
        />
      ),
    },
    {
      key: '3',
      label: 'Chức năng',
      component: (
        <CameraFunction
          camera={camera}
          deleteCamera={handleDeleteCamera}
          refreshCameras={refreshCameras}
        />
      ),
    },
    {
      key: '4',
      label: 'Logs',
      component: <CameraLogs camera={camera} />,
    },
  ];

  const [value, setValue] = React.useState('1');

  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Box className={classes.header}>
          <Typography color={theme.palette.secondary.main} className={classes.headerTitle}>
            {camera.name || 'Chi tiết camera'}
          </Typography>

          <Box>
            <IconButton onClick={handleClose}>
              <CloseOutlined />
            </IconButton>
          </Box>
        </Box>
        <TabLayout sx={{ mx: 0 }} tabList={tabList} handleChange={handleChange} value={value} />
      </Box>
    </Box>
  );
};

export default CameraDetail;
