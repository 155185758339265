import React, { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import DeviceHubOutlinedIcon from '@mui/icons-material/DeviceHubOutlined';
import classNames from 'classnames';

import theme from 'src/theme';
import { EStationEventTypes, TPalateColor } from 'src/types/enum';
import { IStation } from 'src/types/station';
import { useStyles } from './styles';
import config from 'src/config';
import { isNumber } from 'lodash';

interface IProps {
  station: IStation;
  children?: ReactNode;
  icon?: ReactNode;
  selectedId?: string;
  selectStation?: (station: IStation) => void;
}

function StationItem(props: IProps) {
  const { icon, station, children, selectedId, selectStation } = props;

  const classes = useStyles();

  const handleCardClick = () => {
    if (selectStation) {
      selectStation(station);
    }
  };

  const renderIconColor = (status?: EStationEventTypes) => {
    if (!status) return 'disabled';

    const stationColors: {
      [x in EStationEventTypes]: TPalateColor;
    } = {
      [EStationEventTypes.CONNECTED]: 'success',
      [EStationEventTypes.DISCONNECTED]: 'disabled',
      [EStationEventTypes.DEFECTIVE_DEVICE]: 'primary',
      [EStationEventTypes.OVER_THRESHOLD]: 'error',
      [EStationEventTypes.WRONG_STRUCTURE]: 'warning',
    };

    return stationColors[status] || 'disabled';
  };

  const isEqual = selectedId === station.id;

  return (
    <Box
      className={classNames(classes.flex, classes.card, {
        [classes.activeCard]: isEqual,
      })}
      onClick={handleCardClick}
    >
      <Box className={classes.flex}>
        {icon ? (
          icon
        ) : (
          <DeviceHubOutlinedIcon color={renderIconColor(station.status)} fontSize="large" />
        )}
      </Box>
      <Box className={classes.cardContent}>
        <Box className={classes.flex} justifyContent="space-between">
          <Typography
            fontWeight={600}
            className={classes.textEllipsis}
            style={{ color: theme.palette.secondary.main }}
          >
            {station.name}
          </Typography>
          {children}
        </Box>
        <Typography
          className={classes.textEllipsis}
          style={{ color: theme.palette.secondary.dark, margin: 0 }}
          title={station.address}
        >
          {station.address}
        </Typography>

        {config.systemName === 'SITECH' ? (
          <Typography
            className={classes.textEllipsis}
            style={{ color: theme.palette.secondary.dark, margin: 0 }}
            title={station.address}
          >
            {isNumber(station.battery) ? `Pin: ${station.battery}%` : 'Pin: '}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
}

export default StationItem;
