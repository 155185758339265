import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  camName: {
    display: "block",
    position: "absolute",
    color: "#fff",
    top: 0,
    left: 12,
    right: 12,
    textAlign: "left",
    paddingTop: 8,
  },
}));
