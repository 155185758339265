import React, { ReactNode } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as BiIcons from 'react-icons/bi';
import * as Icons from '@mui/icons-material';

import PTZButton from './ptz_button';
import { ICamera } from 'src/types/camera';

import {
  autoFocus,
  initPTZControl,
  startPTZControl,
  stopPTZControl,
  syncDatetime,
} from 'src/redux_store/ptz/ptz_action';
import { useAppDispatch } from 'src/redux_store';
import { PtzControlOptions, ptzSpeedOs } from 'src/constants';
import { toastMessage } from 'src/utils/toast';
import { useIsRequestPending } from 'src/hooks';

const Layout = ({ label, children }: { label: ReactNode; children: ReactNode }) => {
  return (
    <Grid container spacing={0.5} mb={1.5} alignItems="center">
      <Grid item xs={3}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={9}>
        {children}
      </Grid>
    </Grid>
  );
};

interface IPTZControl {
  camera: ICamera;
}

const PTZControl = ({ camera }: IPTZControl) => {
  const dispatch = useAppDispatch();

  const [ptzControlState, setPtzControlState] = React.useState({
    ...PtzControlOptions.default,
  });

  const [speedType, setSpeedType] = React.useState('default');
  const [isStart, setIsStart] = React.useState(false);
  const isLoadingSync = useIsRequestPending('ptz', 'syncDatetime');

  const handleUpdateSpeed = (option: any, type: string) => {
    setPtzControlState({
      ...option,
    });
    setSpeedType(type);
  };

  React.useEffect(() => {
    if (camera.isSupportPtz) {
      dispatch(initPTZControl(camera.id));
    }
  }, [camera]);

  const handlePressIn = (pan: string, tilt: string, zoom: string) => {
    if (!camera.id || !camera.isSupportPtz) return;

    dispatch(startPTZControl({ id: camera.id, data: { pan, tilt, zoom } }));
  };

  const handlePressOut = () => {
    if (!camera.id || !camera.isSupportPtz) return;

    dispatch(stopPTZControl(camera.id));
  };

  const syncTime = () => {
    if (!camera.id || !camera.isSupportPtz || isLoadingSync) return;

    dispatch(syncDatetime(camera.id))
      .unwrap()
      .then(() => toastMessage.success('Đồng bộ thời gian thành công!'))
      .catch(() => {
        toastMessage.error('Đồng bộ thời gian không thành công!');
      });
  };
  const handlePtzControl = (pan: string, tilt: string, zoom: string) => {
    if (!camera.id || !camera.isSupportPtz) return;

    if (isStart) {
      dispatch(stopPTZControl(camera.id));
    } else {
      dispatch(
        startPTZControl({
          id: camera.id,
          data: { pan, tilt, zoom },
        }),
      );
    }
    setIsStart(!isStart);
  };

  const handleAutoFocus = () => {
    if (!camera.id || !camera.isSupportPtz) return;

    dispatch(autoFocus(camera.id));
  };

  const PtzPanTiltOs = [
    [
      {
        icon: <BiIcons.BiLeftTopArrowCircle />,
        pan: ptzControlState.pan.left,
        tilt: ptzControlState.tilt.up,
        zoom: ptzControlState.zoom.default,
        title: 'Lên + Trái',
      },
      {
        icon: <BiIcons.BiUpArrowCircle />,
        pan: ptzControlState.pan.default,
        tilt: ptzControlState.tilt.up,
        zoom: ptzControlState.zoom.default,
        title: 'Lên',
      },
      {
        icon: <BiIcons.BiRightTopArrowCircle />,
        pan: ptzControlState.pan.right,
        tilt: ptzControlState.tilt.up,
        zoom: ptzControlState.zoom.default,
        title: 'Lên + Phải',
      },
    ],
    [
      {
        icon: <BiIcons.BiLeftArrowCircle />,
        pan: ptzControlState.pan.left,
        tilt: ptzControlState.tilt.default,
        zoom: ptzControlState.zoom.default,
        title: 'Trái',
      },
      {
        icon: <Icons.AutorenewOutlined />,
        pan: ptzControlState.pan.loopLeft,
        tilt: ptzControlState.tilt.default,
        zoom: ptzControlState.zoom.default,
        loop: true,
        title: 'Quay tự động',
      },
      {
        icon: <BiIcons.BiRightArrowCircle />,
        pan: ptzControlState.pan.right,
        tilt: ptzControlState.tilt.default,
        zoom: ptzControlState.zoom.default,
        title: 'Phải',
      },
    ],
    [
      {
        icon: <BiIcons.BiLeftDownArrowCircle />,
        pan: ptzControlState.pan.left,
        tilt: ptzControlState.tilt.down,
        zoom: ptzControlState.zoom.default,
        title: 'Xuống + Trái',
      },
      {
        icon: <BiIcons.BiDownArrowCircle />,
        pan: ptzControlState.pan.default,
        tilt: ptzControlState.tilt.down,
        zoom: ptzControlState.zoom.default,
        title: 'Xuống',
      },
      {
        icon: <BiIcons.BiRightDownArrowCircle />,
        pan: ptzControlState.pan.right,
        tilt: ptzControlState.tilt.down,
        zoom: ptzControlState.zoom.default,
        title: 'Xuống + Phải',
      },
    ],
  ];

  const PtzZoomOs = [
    {
      icon: <BiIcons.BiZoomOut />,
      pan: ptzControlState.pan.default,
      tilt: ptzControlState.tilt.default,
      zoom: ptzControlState.zoom.out,
      title: 'Zoom xa',
    },
    {
      icon: <BiIcons.BiZoomIn />,
      pan: ptzControlState.pan.default,
      tilt: ptzControlState.tilt.default,
      zoom: ptzControlState.zoom.in,
      title: 'Zoom gần',
    },
  ];

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>Đồng bộ thời gian</Typography>
        <PTZButton onClick={syncTime} icon={<Icons.AccessTimeOutlined />} />
      </Box>
      <Typography display="flex" alignItems="center" gap={0.5} my={2}>
        <Icons.ControlCamera /> {'PTZ'}
      </Typography>
      <Layout label="Tốc độ">
        <Box display="flex" width="100%" gap={1}>
          {ptzSpeedOs.map((speed) => {
            const { type, title, speedOs, speed: newSpeed } = speed;
            return (
              <Button
                variant={speedType === type ? 'contained' : 'outlined'}
                fullWidth
                onClick={() => handleUpdateSpeed(speedOs, type)}
                key={newSpeed}
              >
                {title}
              </Button>
            );
          })}
        </Box>
      </Layout>
      <Layout label={'Focus'}>
        <Box display="flex" width="100%" gap={1}>
          <PTZButton onClick={handleAutoFocus} icon={<Icons.CenterFocusStrong />} />
        </Box>
      </Layout>
      <Layout label={'Zoom'}>
        <Box display="flex" width="100%" gap={1}>
          {PtzZoomOs.map((item, index) => {
            const { pan, tilt, zoom, icon } = item;
            return (
              <PTZButton
                key={index}
                icon={icon}
                onMouseDown={() => handlePressIn(pan, tilt, zoom)}
                onMouseUp={handlePressOut}
              />
            );
          })}
        </Box>
      </Layout>
      <Layout label={'Quay quét'}>
        <Box display="flex" flexDirection="column" gap={1}>
          {PtzPanTiltOs.map((ptzItem, ptzIndex) => {
            return (
              <Box key={ptzIndex} display="flex" width="100%" gap={1}>
                {ptzItem.map((item, index) => {
                  const { pan, tilt, zoom, icon, loop } = item;
                  if (loop) {
                    return (
                      <PTZButton
                        key={`${ptzIndex}-${index}`}
                        icon={icon}
                        onClick={() => handlePtzControl(pan, tilt, zoom)}
                      />
                    );
                  } else {
                    return (
                      <PTZButton
                        key={`${ptzIndex}-${index}`}
                        icon={icon}
                        onMouseDown={() => handlePressIn(pan, tilt, zoom)}
                        onMouseUp={handlePressOut}
                      />
                    );
                  }
                })}
              </Box>
            );
          })}
        </Box>
      </Layout>
    </Box>
  );
};

export default PTZControl;
