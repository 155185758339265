import React, { ReactNode } from 'react';
import { ButtonCustom } from './styles';

interface IProps {
  icon: ReactNode;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  disabled?: boolean;
  active?: boolean;
}

const PTZButton = ({ icon, onClick, onMouseDown, onMouseUp, disabled, active }: IProps) => {
  return (
    <ButtonCustom
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      variant={active ? 'contained' : 'outlined'}
      onClick={onClick}
      disabled={disabled}
      startIcon={icon}
    />
  );
};

export default PTZButton;
