import React from 'react';
import { Box, Typography } from '@mui/material';
import { BiCctv } from 'react-icons/bi';
import { useDrag } from 'react-dnd';
import classNames from 'classnames';

import { dragPlayerCollect, dragPlayerEnd } from 'src/utils/stream';
import { ECameraStatus } from 'src/types/enum';
import { ICameraItemProps } from 'src/types/camera';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import theme from 'src/theme';
import { useStyles } from './styles';

function CameraItem(props: ICameraItemProps) {
  const { icon, camera, isStation, children, selectedId, selectCamera } = props;

  const { cameraFollowed } = useAppSelector(({ watchlistSlice }) => watchlistSlice);

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [, drag] = useDrag(() => ({
    type: 'box',
    item: props,
    end: (item, monitor) => dragPlayerEnd(item, monitor, dispatch),
    collect: (monitor) => dragPlayerCollect(monitor),
  }));

  const handleCardClick = () => {
    if (selectCamera) {
      selectCamera(camera);
    }
  };

  const renderIconColor = (status: ECameraStatus) => {
    if (status === ECameraStatus.OFFLINE) return theme.palette.secondary.main;
    if (status === ECameraStatus.NORMAL) return theme.palette.success.main;
    if (status === ECameraStatus.ERROR) return theme.palette.error.main;
    if (status === ECameraStatus.WARNING) return theme.palette.warning.main;
  };

  const isEqual = !selectCamera
    ? cameraFollowed.find((cameraFl) => cameraFl.camera.id === camera.id)?.camera.id
    : selectedId === camera.id;

  return (
    <Box
      className={classNames(classes.flex, classes.card, {
        [classes.activeCard]: isEqual,
      })}
      // onClick={handleCardClick}
      onClick={handleCardClick}
      ref={!selectCamera ? drag : null}
    >
      <Box className={classes.flex}>
        {icon ? icon : <BiCctv color={renderIconColor(camera.status)} size={36} />}
      </Box>
      <Box className={classes.cardContent}>
        <Box className={classes.flex} justifyContent="space-between">
          <Typography
            fontWeight={600}
            className={classes.textEllipsis}
            style={{ color: theme.palette.secondary.main }}
          >
            {camera.name}
          </Typography>
          {children}
        </Box>
        <Typography
          className={classes.textEllipsis}
          style={{ color: theme.palette.secondary.dark, margin: 0 }}
          title={isStation ? camera.address : camera.stationName}
        >
          {isStation ? camera.address : camera.stationName}
        </Typography>
      </Box>
    </Box>
  );
}

export default CameraItem;
