import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Box, Grid, Typography } from '@mui/material';

import DialogWrapper from 'src/components/modal/dialog_wrapper';
import { CModalIds } from 'src/constants/modal';
import theme from 'src/theme';
import { IChangePopupStatus, TAudioSoundType } from 'src/types/notification';
import Loading from 'src/components/loading';
import ErrorMessage from 'src/components/error_message';
import {
  changePopupStatus,
  getNotificationSetting,
} from 'src/redux_store/notification/notification_action';
import { useAppDispatch, useAppSelector, useIsRequestError, useIsRequestPending } from 'src/hooks';

import { useStyles } from './styles';
import NotificationSettingSource from '../notification_setting_source';
import NotificationSettingAudioItem from '../notification_setting_audio';
import _ from 'lodash';
import SwitchWithText from 'src/components/switch_with_text';

const notificationSettingAudioList: { type: TAudioSoundType; name: string }[] = [
  {
    type: 'info',
    name: 'Thông báo thông tin',
  },
  {
    type: 'warning',
    name: 'Thông báo cảnh báo',
  },
  {
    type: 'error',
    name: 'Thông báo lỗi',
  },
  {
    type: 'critical',
    name: 'Thông báo quan trọng',
  },
];

const NotificationSetting = () => {
  const classes = useStyles();
  const isLoadingGetNotificationSetting = useIsRequestPending(
    'notification',
    'getNotificationSetting',
  );
  const isErrorGetNotificationSetting = useIsRequestError('notification', 'getNotificationSetting');
  const { notificationSetting } = useAppSelector((state) => state.notificationSlice);
  const dispatch = useAppDispatch();

  const handleChangeStatusPopup = (name: string, checked: boolean) => {
    const payload: IChangePopupStatus = {
      data: {
        popupStatus: checked,
      },
    };
    dispatch(changePopupStatus(payload));
  };

  const selectAudioNotification = () => {
    return (
      <>
        {notificationSettingAudioList.map((item, index) => {
          return <NotificationSettingAudioItem key={index} audioSetting={item} />;
        })}

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          p={1}
          pr={0}
          borderBottom={`1px solid ${theme.palette.secondary.light}`}
        >
          <SwitchWithText
            title="Thông báo Popup"
            statusSwitch={notificationSetting.popupStatus}
            name={'popupStatus'}
            onChange={handleChangeStatusPopup}
          />
        </Grid>
      </>
    );
  };

  const handleRetryRequest = () => {
    dispatch(getNotificationSetting());
  };

  const renderContent = () => {
    if (isLoadingGetNotificationSetting) return <Loading />;
    if (isErrorGetNotificationSetting) return <ErrorMessage onClick={handleRetryRequest} />;
    if (_.isEmpty(notificationSetting)) {
      return (
        <Typography textAlign="center">
          Không tìm thấy cấu hình của người dùng. Vui lòng quay lại sau
        </Typography>
      );
    }
    return (
      <>
        <Box mb={3}>
          <Typography variant="h6" fontWeight="bold" color={theme.palette.secondary.main}>
            Cài đặt loại thông báo
          </Typography>

          {selectAudioNotification()}
        </Box>
        <Box>
          <Typography variant="h6" fontWeight="bold" color={theme.palette.secondary.main}>
            Cài đặt nguồn thông báo
          </Typography>
          <NotificationSettingSource />
        </Box>
      </>
    );
  };

  return (
    <DialogWrapper
      modalId={CModalIds.notificationSetting}
      maxWidth={1152}
      minWidth={1152}
      sx={{ height: '100%', paddingY: theme.spacing(2) }}
      isFullHeight
    >
      <Scrollbars>
        <Box className={classes.root}>{renderContent()}</Box>
      </Scrollbars>
    </DialogWrapper>
  );
};

export default NotificationSetting;
