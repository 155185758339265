import { createAsyncThunk } from '@reduxjs/toolkit';
import { dataApi } from 'src/clients/http/data_api';
import { IGetData, IPayloadApproveData, IPayloadEditData, IPayloadGetData } from 'src/types/data';
import { toastMessage } from 'src/utils/toast';

export const getData = createAsyncThunk<IGetData, IPayloadGetData>(
  'myData/getData',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dataApi.getData(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const approveData = createAsyncThunk<any, IPayloadApproveData>(
  'myData/approveData',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dataApi.approveData(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);

export const deleteData = createAsyncThunk<
  any,
  {
    bucketId: string;
    dataId: string;
  }
>('myData/deleteData', async (payload, { rejectWithValue }) => {
  try {
    const res = await dataApi.deleteData(payload);
    return res.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
export const editData = createAsyncThunk<any, IPayloadEditData>(
  'myData/editData',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await dataApi.editData(payload);
      return data;
    } catch (error: any) {
      toastMessage.error(error?.message || 'Lỗi hệ thống ');
      return rejectWithValue(error);
    }
  },
);
