import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormAutocomplete } from 'src/components/hook_form';
import { useAppSelector } from 'src/hooks';
import { IFilterSourceNotification, TNotificationSource } from 'src/types/notification';

type Props = {
  onFilter: (value: string[]) => void;
};

// const typeNotification: TNotificationSource[] = [
//   'SystemManagement',
//   'UserManagement',
//   'CameraManagement',
//   'StationManagement',
//   'AiEvent',
//   'Record',
//   'Publisher',
//   'OtherSource',
// ];

const NotificationFilterType = (props: Props) => {
  const {
    notificationSetting: { notificationSources },
  } = useAppSelector((state) => state.notificationSlice);
  const [filterList, setFilterList] = useState<{ id: TNotificationSource; value: string }[]>([]);

  useEffect(() => {
    const arrFilter = notificationSources?.map((item) => {
      return { id: item.source, value: item.title };
    });

    setFilterList(arrFilter);
  }, []);

  const { onFilter } = props;
  const { control } = useForm({
    defaultValues: {
      filter: [],
    },
  });

  const onFilterNotification = (name: string, value: any) => {
    const typeList: string[] = [];

    value?.map((type: IFilterSourceNotification) => {
      typeList.push(type.id);
    });

    onFilter(typeList);
  };

  return (
    <FormAutocomplete
      control={control}
      name="filter"
      label="Lọc thông báo"
      keyOption="id"
      labelOption="value"
      options={filterList}
      handleChange={onFilterNotification}
      isMultiple
    />
  );
};

export default NotificationFilterType;
