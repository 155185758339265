import { createAsyncThunk } from '@reduxjs/toolkit';
import { sampleApi } from 'src/clients/http/sample_api';
import { IPagination } from 'src/types/common';
import {
  IPayloadGetSample,
  IStationsSample,
  IDataCreateSample,
  IPayloadFormSample,
  IUpdateSample,
  IPayloadGetHistorySample,
} from 'src/types/sample';
import { IStation, IStationParams } from 'src/types/station';

export const getSampleList = createAsyncThunk<IPagination<IStationsSample>, IPayloadGetSample>(
  'autoSample/getListSample',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.getSampleList(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createSample = createAsyncThunk<IDataCreateSample, IPayloadFormSample>(
  'autoSample/createSample',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.createSample(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStationSampleById = createAsyncThunk<IPayloadFormSample, string>(
  'autoSample/getStationSampleById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.getInfoStationSampleById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const editInfoStationSample = createAsyncThunk<
  IPayloadFormSample,
  {
    id: string;
    payload: IUpdateSample;
  }
>('autoSample/editInfoStationSample', async ({ id, payload }, { rejectWithValue }) => {
  try {
    const { data } = await sampleApi.editInfoStationSample(id, payload);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteInfoStationSample = createAsyncThunk<any, string>(
  'autoSample/deleteInfoStationSample',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.deleteInfoStationSample(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getHistorySampleByStation = createAsyncThunk<
  any,
  {
    id: string;
    params: IPayloadGetHistorySample;
  }
>('autoSample/getHistorySampleByStation', async ({ id, params }, { rejectWithValue }) => {
  try {
    const { data } = await sampleApi.getHistorySampleByStation(id, params);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getHistorySampleBySampleIds = createAsyncThunk<any, string[]>(
  'autoSample/getHistorySampleBySampleIds',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.getHistorySampleBySampleIds(params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteHistoryById = createAsyncThunk<any, string>(
  'autoSample/deleteHistoryById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.deleteHistoryById(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createAutoSample = createAsyncThunk<any, string>(
  'autoSample/createAutoSample',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await sampleApi.createAutoSample(id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getStationOptions = createAsyncThunk<IStation[], IStationParams>(
  'station/getStationOptions',
  async (params, { rejectWithValue }) => {
    try {
      const res = await sampleApi.getStationOptions(params);
      return res.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);
