import { IStationInfo } from 'src/types/latest_data';
import {
  IStation,
  IStationLogs,
  IStationParams,
  IStationSearch,
  TStationDocument,
  TStationHostUnit,
  TStationIndicators,
  TStationInformation,
  TStationMethod,
} from 'src/types/station';
import { IIndicator, IIndicatorChart } from './../../types/indicators';
import { createClient } from './axios_client';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const stationApi = {
  createStationInformation: (data: TStationInformation) => {
    return client.post<IStation>('/stations/information', data);
  },
  createStationHostUnit: (data: TStationHostUnit) => {
    return client.post<IStation>('/stations/host', data);
  },
  createStationMethod: (data: TStationMethod) => {
    return client.post<IStation>('/stations/method', data);
  },
  createStationIndicators: (data: Omit<IStation, 'id'>) => {
    return client.post<IStation>('/stations/indicators', data);
  },

  getStationCameras: (stationId: string) => client.get(`/camera/station/${stationId}`),

  getStationLatestData: (stationId: string) => client.get(`/latest_data/${stationId}`),

  getStations: (params: IStationParams) => {
    const {
      district = '',
      limit = 15,
      monitoringGroup = '',
      monitoringType = '',
      page = 1,
      searchKeyword = '',
      activeStatus = '',
      confirmationStatus = '',
      status = '',
    } = params;

    return client.get<{
      data: IStation[];

      totalData: number;
    }>(
      `/stations?page=${page}&limit=${limit}&searchKeyword=${searchKeyword}&monitoringType=${monitoringType}&monitoringGroup=${monitoringGroup}&district=${district}&activeStatus=${activeStatus}&confirmationStatus=${confirmationStatus}&status=${status}`,
    );
  },

  getStationMap: (params: IStationParams) => {
    return client.get<IStation[]>('/stations/map', {
      params,
    });
  },

  getStationsListInfo: (params: IStationParams) => {
    const {
      district = '',
      limit = 15,
      monitoringGroup = '',
      monitoringType = '',
      page = 1,
      searchKeyword = '',
      status = '',
    } = params;

    return client.get<{
      data: IStation[];
      totalData: number;
    }>(
      `/stations/list_info?page=${page}&limit=${limit}&searchKeyword=${searchKeyword}&monitoringType=${monitoringType}&monitoringGroup=${monitoringGroup}&district=${district}&status=${status}`,
    );
  },

  getStationOptions: (params: IStationParams) => {
    return client.get<IStation[]>('/stations/options', {
      params,
    });
  },
  getStationBaseCreateById: (id: string) => {
    return client.get<IStation>(`/stations/${id}/base_create`);
  },
  getStationInfoById: (id: string) => {
    return client.get<TStationInformation>(`/stations/${id}/information`);
  },
  getStationHostById: (id: string) => {
    return client.get<TStationHostUnit>(`/stations/${id}/host_unit`);
  },
  getStationMethodById: (id: string) => {
    return client.get<TStationMethod>(`/stations/${id}/method`);
  },
  getStationIndicatorsById: (id: string) => {
    return client.get<TStationIndicators>(`/stations/${id}/indicators`);
  },
  getStationDocumentById: (id: string) => {
    return client.get<TStationDocument>(`/stations/${id}/document`);
  },
  updateStationInfo: (id: string, data: TStationInformation) => {
    return client.put<TStationInformation>(`/stations/${id}/information`, data);
  },
  updateStationHost: (id: string, data: TStationHostUnit) => {
    return client.put<TStationHostUnit>(`/stations/${id}/host_unit`, data);
  },
  updateStationMethod: (id: string, data: TStationMethod) => {
    return client.put<TStationMethod>(`/stations/${id}/method`, data);
  },
  updateStationIndicators: (id: string, data: TStationIndicators) => {
    return client.put<TStationIndicators>(`/stations/${id}/indicators`, data);
  },
  updateStationDocument: (id: string, data: TStationDocument) => {
    return client.put<TStationDocument>(`/stations/${id}/document`, data);
  },

  deleteStation: (id: string) => {
    return client.delete<string>(`/stations/${id}`);
  },

  //user_station

  getMyStation: (params: IStationSearch) => {
    return client.get<{ id: string; name: string }[]>('/user_stations', { params });
  },

  getUserStationConfig: (userId: string) => {
    return client.get<{ trackAll: boolean; stationList: { id: string; name: string }[] }>(
      `/user_stations/${userId}/config`,
    );
  },

  configUserStation: ({
    userId,
    stationIds,
    trackAll,
  }: {
    trackAll: boolean;
    stationIds: string[];
    userId: string;
  }) => {
    return client.put<string>(`/user_stations/${userId}/config`, { trackAll, stationIds });
  },

  getStationInfo: (stationId: string) => {
    return client.get<IStationInfo>(`/stations/${stationId}/detail_info`);
  },
  getAllIndicatorOfStation: (stationId: string) => {
    return client.get<IIndicator[]>(`/stations/${stationId}/get_all_indicators`);
  },
  getAllIndicatorOfChart: (stationId: string) => {
    return client.get<IIndicatorChart[]>(`/stations/${stationId}/get_all_indicators`);
  },
  getStationLogs: (
    id: string,
    params: {
      after: string;
      limit: number;
    },
  ) => {
    return client.get<IStationLogs[]>(`/station_logs/${id}`, {
      params,
    });
  },

  getStationLora: (params: IStationParams) => {
    return client.get<
      {
        id: string;
        name: string;
        sendingMethod: {
          devUEI: string;
          applicationId: string;
        };
      }[]
    >('/stations/lora', {
      params,
    });
  },
};
