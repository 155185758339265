/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const schemaCameraConnect = (isShareLink: boolean) => {
  return yup.object({
    name: yup.string().required('Vui lòng nhập tên camera'),
    stationId: yup.string().required('Vui lòng chọn trạm quan trắc'),
    isShareLink: yup.boolean(),
    ...(isShareLink && {
      rtspLinks: yup
        .array()
        .min(1, 'Vui lòng nhập ít nhất 1 link rtsp')
        .of(
          yup.object().shape({
            name: yup.string().required('Vui lòng nhập tên luồng stream'),
            rtspLink: yup
              .string()
              .required('Vui lòng nhập link rtsp')
              .matches(
                /(rtsp):\/\/(?:([^\s@\/]+?)[@])?([^\s\/:]+)(?:[:]([0-9]+))?(?:(\/[^\s?#]+)([?][^\s#]+)?)?([#]\S*)?/g,
                'vui lòng nhập đúng định dạng link rtsp',
              ),
          }),
        ),
    }),
    ...(!isShareLink && {
      ip: yup.string().required('Vui lòng nhập địa chỉ ip'),
      user: yup.string().required('Vui lòng nhập tên đăng nhập'),
      pass: yup.string().required('Vui lòng nhập mật khẩu'),
      portOnvif: yup.string().required('Vui lòng nhập port'),
      portRtsp: yup.string().required('Vui lòng nhập port'),
    }),
  });
};
