import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  LogItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '4px',
    background: '#fff',
    transition: '.3s easy-in-out',
    borderRadius: '4px',
    marginTop: '8px !important',
    ' &:last-child ': {
      marginBottom: '8px !important',
    }
  },
  LogWrap: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row wrap',
    width: '100%',
    justifyContent: 'space-between',
  },
  LogTime: {
    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'row nowrap',
    width: 'calc(100% + 4px)',
    marginLeft: '-4px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '4px',
  },
  container: {
    padding: `0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    height: '100%',
    width: '100%',
  },
}));
