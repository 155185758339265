import React from 'react';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box } from '@mui/system';
import ConfirmationDialog from 'src/components/modal/confirm_dialog';
import { useAppDispatch } from 'src/redux_store';
import { deleteData, getData } from 'src/redux_store/data/data_actions';
import { toastMessage } from 'src/utils/toast';
import { closeModal } from 'src/redux_store/common/modal_slice';
import { editDataList } from 'src/redux_store/data/data_slice';
import { useAppSelector } from 'src/hooks';

interface IProps {
  modalId: string;
  payload: {
    bucketId: string;
    dataId: string;
  };
}

const ModalDeleteData = ({ modalId, payload }: IProps) => {
  const dispatch = useAppDispatch();
  const { payloadData } = useAppSelector((state) => state.dataSlice);

  const handleDelete = () => {
    dispatch(deleteData(payload))
      .unwrap()
      .then(() => {
        toastMessage.success('Xóa thành công');
        dispatch(editDataList(payload.dataId));
        dispatch(getData(payloadData));
        handleClose();
      });
  };

  const handleClose = () => {
    dispatch(closeModal({ modalId }));
  };

  return (
    <Box>
      <ConfirmationDialog
        modalId={modalId}
        functionName="deleteData"
        sliceName="news"
        describe={'Bạn có muốn xóa dữ liệu này không ?'}
        callback={handleDelete}
        icon={<WarningAmberOutlinedIcon sx={{ color: '#ff0000' }} />}
        okLabel="Xác nhận"
        cancelLabel="Hủy"
      ></ConfirmationDialog>
    </Box>
  );
};

export default ModalDeleteData;
