import React from 'react';
import { FilterListOutlined } from '@mui/icons-material';
import { Box, Collapse } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ICameraSearch } from 'src/types/camera';
import { useStyles } from './styles';
import { useToggle } from 'src/hooks';
import { FormAutocomplete, FormInput } from 'src/components/hook_form';
import theme from 'src/theme';
import { IStation } from 'src/types/station';
import { useAppDispatch } from 'src/redux_store';
import { getStationOptions } from 'src/redux_store/station/station_action';

interface IFormState {
  searchKeyword: string;
  station: IStation[];
}

interface ICameraFilter {
  handleFilter: (data: ICameraSearch) => void;
  disabled?: boolean;
}

function CameraFilter({ handleFilter, disabled }: ICameraFilter) {
  const classes = useStyles();

  const [searchDelay, setSearchDelay] = React.useState<number | null>(null);
  const [stations, setStations] = React.useState<IStation[]>([]);
  const [expanded, setExpanded] = useToggle(false);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getStationOptions({}))
      .unwrap()
      .then((data) => setStations(data));
  }, []);

  const { control, getValues } = useForm<IFormState>({
    defaultValues: {
      searchKeyword: '',
      station: [],
    },
  });

  const onFilter = (name: string, value: any) => {
    const { searchKeyword, station } = getValues();

    if (name === 'station') {
      const stationId = value.map((item: IStation) => item.id);
      return handleFilter({ station: stationId.join(','), searchKeyword });
    }

    if (name === 'searchKeyword') {
      if (searchDelay) {
        clearTimeout(searchDelay);
      }
      const delay: any = setTimeout(() => {
        const stationId = station.map((item) => item.id);
        handleFilter({ station: stationId.join(','), searchKeyword: value });
      }, 1250);

      setSearchDelay(delay);
      return;
    }
  };

  return (
    <Box className={classes.form}>
      <FormInput
        control={control}
        name="searchKeyword"
        label="Tìm kiếm từ khóa"
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <FilterListOutlined
              sx={{ cursor: 'pointer' }}
              onClick={setExpanded}
              style={{ color: theme.palette.secondary.main }}
            />
          ),
        }}
        handleChange={onFilter}
        sx={{ background: theme.palette.background.paper }}
      />
      <Collapse in={expanded}>
        <FormAutocomplete
          isMultiple
          control={control}
          name="station"
          label="Trạm"
          labelOption="name"
          keyOption="id"
          options={stations}
          disabled={disabled}
          handleChange={onFilter}
          sx={{ background: theme.palette.background.paper }}
        />
      </Collapse>
    </Box>
  );
}

export default CameraFilter;
