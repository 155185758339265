import { PermissionSchemes } from 'src/constants/role';
import React from 'react';

import AdminLayout from 'src/layout/admin_layout';
import AuthLayout from 'src/layout/auth_layout';
import CameraManagement from 'src/pages/camera_management';
import AddNewPost from 'src/pages/post_management/add_edit_post';
import DataManagement from 'src/pages/data_management';

const Home = React.lazy(() => import('src/pages/home'));
const Login = React.lazy(() => import('src/pages/auth/login'));
const Register = React.lazy(() => import('src/pages/auth/register'));
const VerifyPassword = React.lazy(() => import('src/pages/auth/verify_password'));
const IndicatorManagement = React.lazy(() => import('src/pages/indicator_management'));
const StationManagement = React.lazy(() => import('src/pages/station_management'));
const Map = React.lazy(() => import('src/pages/map'));
const SystemSetting = React.lazy(() => import('src/pages/system_setting'));
const NotFound = React.lazy(() => import('src/components/not_found'));
const UserAndRoleManagement = React.lazy(() => import('src/pages/user_management'));
const SendEmail = React.lazy(() => import('src/pages/auth/send_email'));
const SampleManager = React.lazy(() => import('src/pages/auto_sample/sample'));
// const Watchlist = React.lazy(() => import('src/pages/watchlist'));
const PostManagement = React.lazy(() => import('src/pages/post_management'));
const StationInfor = React.lazy(() => import('src/pages/station_infor'));
const PenaltyManagement = React.lazy(() => import('src/pages/penalty_management'));
const Statistic = React.lazy(() => import('src/pages/statistic'));
const ApiManagement = React.lazy(() => import('src/pages/api__management'));
const StationChart = React.lazy(() => import('src/pages/station_chart'));
const UserLogs = React.lazy(() => import('src/pages/user_log'));
const SynchronizedData = React.lazy(() => import('src/pages/synchronized_data'));
const CollectData = React.lazy(() => import('src/pages/collect_data'));
const FirmwareManagement = React.lazy(() => import('src/pages/firmware_management'));

import { IRoute } from 'src/types/route';
import { CPath } from './path';
import { isSTNMT, isSiTechSystem } from 'src/utils/function';

export const CRouteList: IRoute[] = [
  {
    path: CPath.home,
    name: 'Dữ liệu mới nhất',
    component: Home,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListNewestData],
  },
  {
    path: CPath.login,
    name: 'Đăng nhập',
    component: Login,
    layout: AuthLayout,
  },
  {
    path: CPath.register,
    name: 'Đăng ký',
    component: Register,
    layout: AuthLayout,
  },
  {
    path: CPath.verifyPassword,
    name: 'Thay đổi mật khẩu',
    component: VerifyPassword,
    layout: AuthLayout,
  },
  {
    path: CPath.sendEmail,
    name: 'Thay đổi mật khẩu',
    component: SendEmail,
    layout: AuthLayout,
  },
  {
    path: CPath.indicator,
    name: 'Quản lý chỉ số',
    component: IndicatorManagement,
    layout: AdminLayout,
    permission: [
      PermissionSchemes.getListMonitoringType,
      PermissionSchemes.getListMonitoringGroup,
      PermissionSchemes.getListIndicator,
    ],
  },
  {
    path: CPath.station,
    name: 'Quản lý trạm quan trắc',
    component: StationManagement,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListStation],
  },
  {
    path: CPath.map,
    name: 'Bản đồ',
    component: Map,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListMap],
  },
  {
    path: CPath.userManagement,
    name: 'Quản lý người dùng',
    component: UserAndRoleManagement,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListUser],
  },
  {
    path: CPath.camera,
    name: 'Quản lý camera',
    component: CameraManagement,
    isNotDefaultRoute: isSiTechSystem,
    layout: AdminLayout,
    permission: [PermissionSchemes.configCamera],
  },
  {
    path: CPath.systemSetting,
    name: 'Cấu hình hệ thống',
    component: SystemSetting,
    layout: AdminLayout,
    permission: [PermissionSchemes.getSystemSetting],
  },
  {
    path: CPath.autoSample,
    name: 'Lấy mẫu tự động',
    component: SampleManager,
    layout: AdminLayout,
    isNotDefaultRoute: isSiTechSystem,
    permission: [PermissionSchemes.autoSample],
  },
  // {
  //   path: CPath.watchlist,
  //   name: 'Danh sách theo dõi',
  //   component: Watchlist,
  //   layout: AdminLayout,
  //   permission: [PermissionSchemes.getListStation],
  // },
  {
    path: CPath.dataManagement,
    name: 'Biên tập dữ liệu',
    component: DataManagement,
    layout: AdminLayout,
    permission: [PermissionSchemes.editData],
  },
  {
    path: CPath.stationChart,
    name: 'Biểu đồ',
    component: StationChart,
    layout: AdminLayout,
  },
  {
    path: CPath.stationInfo,
    name: 'Hệ Thống Quan Trắc Môi Trường Đà Nẵng',
    component: StationInfor,
    layout: AdminLayout,
  },
  {
    path: CPath.postManagement,
    name: 'Quản lý bài đăng',
    component: PostManagement,
    isNotDefaultRoute: isSiTechSystem,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListPost],
  },
  {
    path: CPath.postManagementAddPost,
    name: 'Thêm mới bài đăng',
    component: AddNewPost,
    isNotDefaultRoute: isSiTechSystem,
    layout: AdminLayout,
    permission: [PermissionSchemes.addPost],
  },
  {
    path: CPath.postManagementEditPost,
    name: 'Chỉnh sửa bài đăng',
    component: AddNewPost,
    layout: AdminLayout,
    isNotDefaultRoute: isSiTechSystem,
    permission: [PermissionSchemes.editPost],
  },
  {
    path: CPath.problemManagement,
    name: 'Quản lý sự cố',
    component: PenaltyManagement,
    isNotDefaultRoute: isSiTechSystem,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListStation],
  },
  {
    path: CPath.penaltyManagement,
    name: 'Quản lý xử phạt',
    component: PenaltyManagement,
    isNotDefaultRoute: isSiTechSystem,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListPenalties],
  },
  {
    path: CPath.statistic,
    name: 'Báo Cáo Thống Kê',
    component: Statistic,
    layout: AdminLayout,
    permission: [PermissionSchemes.getViewGeneralReport],
  },
  {
    path: CPath.apiManagement,
    name: 'Quản lý API',
    component: ApiManagement,
    layout: AdminLayout,
    isNotDefaultRoute: isSiTechSystem,
    permission: [PermissionSchemes.getApi],
  },
  {
    path: CPath.userLogs,
    name: 'Nhật ký người dùng',
    component: UserLogs,
    layout: AdminLayout,
    permission: [PermissionSchemes.getUserLog],
  },
  {
    path: CPath.synchronizedData,
    name: 'Đồng bộ một cửa',
    component: SynchronizedData,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListStation],
    isNotDefaultRoute: isSiTechSystem,
  },
  {
    path: CPath.collectData,
    name: 'Tiếp nhận dữ liệu',
    component: CollectData,
    layout: AdminLayout,
    isNotDefaultRoute: isSTNMT,
    permission: [PermissionSchemes.editData],
  },
  {
    path: CPath.firmware,
    name: 'Quản lý firmware',
    component: FirmwareManagement,
    layout: AdminLayout,
    permission: [PermissionSchemes.getListStation],
    isNotDefaultRoute: isSTNMT,
  },
  {
    path: CPath.notFound,
    name: 'Không tìm thấy trang',
    component: NotFound,
  },
];
