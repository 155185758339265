import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

const useStyles = makeStyles(() => ({
  cameraItem: {
    width: 'calc(100% / 2 - 8px)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    margin: '4px',
    padding: 6,
    cursor: 'pointer',
    background: 'white',
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    gap: 4,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default useStyles;
