import React from 'react';
import { VisibilityOffOutlined } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from 'src/components/player_hls/types/player';
import { toastMessage } from 'src/utils/toast';
import { removeSingleCamFromWatchlist } from 'src/redux_store/watchlist/watchlist_action';
import { debugLog } from '../../../utils/log';
import store from 'src/redux_store';

const dispatch = store.dispatch;

function FollowToggle(props: any) {
  const slice = { ...props } as IPlayerProps;
  const { cam } = slice;

  const controlText = 'Bỏ theo dõi';

  // const handleClick = () => {
  //   console.log({ props });
  //   debugLog('🚀 ~ CPN.video.FollowToggle');
  // };

  const handleClick = () => {
    if (!cam.classifiedListId || !cam.position) return;
    if (slice.isFollowDisabled) return toastMessage.error('Không thể xóa camera khỏi nhóm tất cả');
    dispatch(
      removeSingleCamFromWatchlist({ position: cam.position, groupId: cam.classifiedListId }),
    );

    debugLog('🚀 ~ CPN.video.FollowToggle');
  };

  if (!cam.classifiedListId || !cam.position) return null;

  return (
    <Box>
      <Tooltip
        title={controlText}
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <VisibilityOffOutlined />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default FollowToggle;
