import React from 'react';
import { RefreshRounded } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { IPlayerProps } from '../../../types/player';

function Refresh(props: any) {
  const slice = { ...props } as IPlayerProps;

  const handleClick = () => {
    const { manager, player } = slice;

    if (!manager.video) return;
    manager.video.handleHlsFullscreen(player.isFullscreen);
  };

  return (
    <Box>
      <Tooltip
        title="Tải lại"
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <IconButton onClick={handleClick}>
          <RefreshRounded />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default Refresh;
