import { makeStyles } from '@mui/styles';
import theme from 'src/theme';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: '#3e50b4',
    position: 'relative',
  },
  body: {
    position: 'absolute',
    width: '100%',
    height: '80%',
    backgroundColor: 'rgb(243 242 241)',
    bottom: 0,
  },
  bodyDetail: {
    position: 'absolute',
    width: '96%',
    height: '88%',
    bottom: 0,
  },
  avatar: {
    position: 'absolute',
    width: '60%',
    height: '60px',
    marginLeft: '10px',
    top: '-15px',
    display: 'flex',
  },
  avatarImage: {
    position: 'absolute',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#3e50b4',
    border: '4px solid #fff',
    display: 'grid',
    placeItems: 'center',
    color: '#fff',
  },
  informationDetail: {
    width: '97%',
    height: 'auto',
    backgroundColor: '#fff',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 2px',
    [theme.breakpoints.down('md')]: {
      margin: '15px 15px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '20px 15px',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '20px ',
    },
  },
  boxOnline: {
    position: 'absolute',
    height: '15px',
    width: '15px',
    backgroundColor: '#2ecc71',
    zIndex: '13712681723',
    borderRadius: '50%',
    right: '0',
    bottom: '-2px',
    border: '2px solid #fff',
  },
  name: {
    position: 'absolute',
    width: '60% !important',
    height: 'auto',
    marginLeft: '70px !important',
    fontWeight: '600 !important',
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden ',
  },
}));
