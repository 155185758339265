import React, { Component } from 'react';
import Hls from 'hls.js';
import { Typography } from '@mui/material';

import { ECameraStatus } from 'src/types/enum';
import { IPlayerProps } from '../../types/player';
import { debugLog } from '../../utils/log';
import { VideoStyled, VideoWrapper } from './styles';
import fullscreen from '../../utils/fullscreen';

const hlsOption = {};

export default class Video extends Component<any> {
  video: HTMLVideoElement | null = null;
  slice = this.props as IPlayerProps;
  hls = new Hls(hlsOption);

  componentDidUpdate(prevProps: IPlayerProps) {
    if (prevProps.cam.id !== this.props.cam.id) {
      this.handleHlsFullscreen(false);
    }
  }

  componentDidMount() {
    // fullscreen.addEventListener(this.handleFullscreenChange);
    // this.onRefreshHls();
    this.handleHlsFullscreen(false);

    if (Hls.isSupported()) {
      this.hls.on(Hls.Events.ERROR, (event, data) => {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              console.log('fatal network error encountered, try to recover');
              this.hls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover', data);
              this.hls.recoverMediaError();
              break;
            default:
              this.hls.destroy();
              break;
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.destroyHls();
    // fullscreen.removeEventListener(this.handleFullscreenChange);
  }

  destroyHls = () => {
    if (this.hls) {
      this.hls.destroy();
    }
  };

  resetHls = () => {
    this.destroyHls();
    this.hls = new Hls(hlsOption);
  };

  // onRefreshHls = () => {
  //   this.resetHls();

  //   const video = this.video;
  //   if (!video) return;

  //   // console.log({ slice: this.slice });
  //   if (Hls.isSupported()) {
  //     this.hls.detachMedia();
  //     this.hls.attachMedia(video);
  //     this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
  //       this.hls.loadSource(`https://doxe.danang.gov.vn${this.props.cam.mainStream}`);
  //       video.play().catch((error) => console.log(error));
  //     });

  //     this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
  //       const promise = video.play();
  //       if (promise === undefined) {
  //         console.log('🚀 video.play() undefined');
  //       }
  //       if (promise !== undefined) {
  //         promise
  //           .catch((error) => console.log({ error }))
  //           .then(() => {
  //             console.log('🚀 Play');
  //           });
  //       }
  //     });
  //   }
  // };

  handleFullscreenChange = (e: Event) => {
    if (e.target === this.slice.manager.rootElement) {
      this.slice.actions.changeFullscreen(fullscreen.isFullscreen);
    }
  };

  handleHlsFullscreen = (isFullscreen: boolean) => {
    this.resetHls();

    const video = this.video;
    if (!video) return;

    if (Hls.isSupported()) {
      this.hls.detachMedia();
      this.hls.attachMedia(video);

      this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        this.hls.loadSource(`/video/livestream/${this.props.cam.id}/index.m3u8`);
        video.play().catch((error) => console.log(error));
      });
    }

    this.hls.on(Hls.Events.MANIFEST_PARSED, function () {
      const promise = video.play();
      if (promise === undefined) {
        console.log('🚀 video.play() undefined', isFullscreen);
      }
      if (promise !== undefined) {
        promise
          .catch((error) => console.log({ error }))
          .then(() => {
            console.log('🚀 Play');
          });
      }
    });
  };

  onPlay = () => this.handlePlay();

  onPause = () => this.handlePause();

  handleError = () => {
    debugLog('🚀 ~ CPN.video: handle error');
  };

  handlePause = () => {
    const { player } = this.slice;
    if (player.paused || !this.video) return;
    this.pause();
  };

  handlePlay = () => {
    if (!this.hls) return;
    this.play();
  };

  play = () => {
    if (!this.video) return;
    const { actions } = this.slice;

    const promise = this.video.play();
    if (promise !== undefined)
      promise
        .catch(() => {
          if (!this.video) return;
          this.video.pause();
          actions.pause();
        })
        .then(() => {
          actions.play();
        });
  };

  pause = () => {
    if (!this.video) return;
    const { actions } = this.slice;

    actions.pause();
    this.video.pause();
  };

  onSeeking = () => {
    debugLog('🚀 ~ CPN.video: onSeeking');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.seeking();
    this.video.play();
  };

  onSeeked = () => {
    debugLog('🚀 ~ CPN.video: onSeeked');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.seeked();
    this.play();
  };

  onEnded = () => {
    debugLog('🚀 ~ CPN.video: onEnded');
  };

  onWaiting = () => {
    debugLog('🚀 ~ CPN.video: onWaiting');
    const { actions } = this.slice;

    actions.waiting();
    this.handlePause();
  };

  onCanPlay = () => {
    debugLog('🚀 ~ CPN.video.onCanPlay');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.canPlay();
  };

  onPlaying = () => {
    debugLog('🚀 ~ CPN.video.onPlaying');
    if (!this.video) return;
    const { actions } = this.slice;

    actions.playing();
  };

  onCanPlayThrough = (e: React.BaseSyntheticEvent) => {
    debugLog({ '🚀 ~ CPN.video.onCanPlayThrough': e });
    if (!this.video) return;
    const { actions } = this.slice;
    actions.canPlayThrough();
  };

  render() {
    if (this.props.cam.status === ECameraStatus.OFFLINE)
      return (
        <VideoWrapper>
          <Typography
            position="absolute"
            top="50%"
            left="50%"
            color="white"
            sx={{
              transform: 'translate(-50%, -50%)',
            }}
          >
            Camera đang tắt
          </Typography>
        </VideoWrapper>
      );
    return (
      <VideoWrapper>
        <VideoStyled
          crossOrigin="anonymous"
          ref={(c) => (this.video = c)}
          muted
          autoPlay
          onSeeking={this.onSeeking}
          onSeeked={this.onSeeked}
          onEnded={this.onEnded}
          onError={this.handleError}
          onPlay={this.onPlay}
          onCanPlay={this.onCanPlay}
          onPause={this.onPause}
          onWaiting={this.onWaiting}
          onPlaying={this.onPlaying}
          onCanPlayThrough={this.onCanPlayThrough}
        />
      </VideoWrapper>
    );
  }
}
