import {
  CheckOutlined,
  ClearOutlined,
  CreateOutlined,
  ReplayOutlined,
  TaskAltOutlined,
  UnpublishedOutlined,
} from '@mui/icons-material';
import { Box, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState, useTransition } from 'react';

import { CModalIds } from 'src/constants';
import { useIsRequestPending } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal_slice';
import { toastMessage } from 'src/redux_store/common/toast/toast_action';
import { approveData, editData } from 'src/redux_store/data/data_actions';
import { IDataItem, IPayloadApproveData } from 'src/types/data';
import { IIndicator } from 'src/types/indicators';

import config from 'src/config';
import { isSiTechSystem } from 'src/utils/function';
import ModalDeleteData from '../modal_delete_data';
import EditTable from './table';

type Props = {
  indicatorColumn: IIndicator[];
};
const notEdited = ['name', 'sentAt', 'STT', 'setting'];
const DataTable = ({ indicatorColumn }: Props) => {
  const { dataList, payloadData } = useAppSelector((state) => state.dataSlice);

  const dispatch = useAppDispatch();

  const [isPending] = useTransition();
  const isLoadingGetData = useIsRequestPending('myData', 'getData');
  const isLoadingApproveData = useIsRequestPending('myData', 'approveData');
  const [editableRowId, setEditableRowId] = React.useState<number | null>(null);
  const [columnsIndicators, setColumnsIndicators] = useState<ColumnDef<IDataItem>[]>([]);
  const [detailsArray, setDetailsArray] = React.useState<{ indicator: string; value: number }[]>(
    [],
  );
  const handleOpenEditInfo = (row: any) => {
    const data: {
      indicator: string;
      value: number;
    }[] = [];

    const columns: string[] = [];

    columnsIndicators.forEach((item) => {
      if (item.id) {
        columns.push(item.id);
      }
    });

    Object.keys(row.original.data).forEach((key) => {
      data.push({
        indicator: key,
        value: row.original.data[key],
      });
    });

    const diffColumns = _.differenceWith(columns, data, (x, y) => {
      return x === y.indicator;
    });

    diffColumns.forEach((key: string) => {
      data.push({
        indicator: key,
        value: 0,
      });
    });

    setDetailsArray(data);
    setEditableRowId(row.index);
  };

  const handleCloseEditInfo = () => {
    setEditableRowId(null);
  };

  const handleEditInfo = (row: any) => {
    const newPayload = {
      bucketId: row.original.bucketId,
      dataId: row.original.id,
      details: [...detailsArray],
    };

    dispatch(editData(newPayload))
      .unwrap()
      .then(() => {
        toastMessage.success('Cập nhật dữ liệu thành công');
        handleCloseEditInfo();
      });
  };

  const handleApproveInfo = (row: any) => {
    const newPayload: IPayloadApproveData = {
      approved: !row.original.approved,
      bucketId: row.original.bucketId,
      dataId: row.original.id,
    };
    dispatch(approveData(newPayload))
      .unwrap()
      .then(() => {
        if (row.original.approved == false) {
          toastMessage.success('Duyệt dữ liệu thành công');
        } else {
          toastMessage.success('Huỷ duyệt dữ liệu thành công');
        }
      });
  };
  const handleDeleteInfo = async (row: any) => {
    const newPayload = {
      bucketId: row.original.bucketId,
      dataId: row.original.id,
    };
    dispatch(
      openModal({
        modalId: CModalIds.deleteData,
        modalComponent: <ModalDeleteData modalId={CModalIds.deleteData} payload={newPayload} />,
      }),
    );
  };

  const defaultEditColumn: Partial<ColumnDef<IDataItem>> = useMemo(() => {
    return {
      cell: ({ row, column }) => {
        const newName = column.id.trim();
        const initialValue = row.original.data[newName] || 0;
        const [value, setValue] = React.useState<number>(initialValue);

        const onBlur = () => {
          const arr = [...detailsArray];
          for (const item of arr) {
            if (item.indicator === newName) {
              item.value = value;
              setDetailsArray(arr);
              return;
            }
          }
        };

        if (!notEdited.includes(column.id) && row.index === editableRowId) {
          return (
            <TextField
              type="number"
              variant="standard"
              sx={{ p: 0 }}
              value={value as number}
              onChange={(e) => setValue(Number(e.target.value))}
              onBlur={onBlur}
            />
          );
        }
        return value;
      },
    };
  }, [editableRowId]);

  const columns: ColumnDef<IDataItem>[] = useMemo(() => {
    const finalColumn: ColumnDef<IDataItem>[] = [
      ...(config.systemName !== 'SITECH'
        ? [
            {
              id: 'setting',
              header: 'Tuỳ chọn',
              size: 100,
              cell: (info: any) => {
                return info.row.index === editableRowId ? (
                  <Box>
                    <Tooltip title="Cập nhật">
                      <IconButton
                        color="success"
                        onClick={() => {
                          handleEditInfo(info.row);
                        }}
                      >
                        <CheckOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Khôi phục"
                      onClick={() => {
                        handleCloseEditInfo();
                      }}
                    >
                      <IconButton color="error">
                        <ReplayOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box>
                    <Tooltip title="Chỉnh sửa">
                      <IconButton
                        color="success"
                        onClick={() => {
                          handleOpenEditInfo(info.row);
                        }}
                      >
                        <CreateOutlined />
                      </IconButton>
                    </Tooltip>
                    {info.row.original.approved === true ? (
                      <Tooltip title="Đã Duyệt">
                        <IconButton
                          color="warning"
                          onClick={() => {
                            handleApproveInfo(info.row);
                          }}
                          disabled={isLoadingApproveData}
                        >
                          <TaskAltOutlined />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Chưa duyệt">
                        <IconButton
                          color="warning"
                          onClick={() => {
                            handleApproveInfo(info.row);
                          }}
                          disabled={isLoadingApproveData}
                        >
                          <UnpublishedOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Xóa">
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleDeleteInfo(info.row);
                        }}
                      >
                        <ClearOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]
        : []),
      {
        id: 'STT',
        header: 'STT',
        size: 20,
        maxSize: 20,
        cell: ({ row }) => (
          <Typography textAlign={isSiTechSystem ? 'center' : undefined}>
            {(payloadData.page - 1) * payloadData.limit + (row.index + 1)}
          </Typography>
        ),
      },
      {
        id: 'name',
        accessorKey: 'name',
        size: 200,
        header: 'Tên trạm',
        cell: ({ row }) => row.original.name,
      },
      {
        id: 'sentAt',
        header: 'Thời gian',
        accessorKey: 'sentAt',
        cell: ({ row }) => moment(row.original.sentAt).utc(false).format('DD-MM-YYYY HH:mm:ss'),
      },
      ...(isSiTechSystem
        ? [
            {
              id: 'setting',
              header: 'Tuỳ chọn',
              size: 200,
              cell: (info: any) => {
                return info.row.index === editableRowId ? (
                  <Box>
                    <Tooltip title="Cập nhật">
                      <IconButton
                        color="success"
                        onClick={() => {
                          handleEditInfo(info.row);
                        }}
                      >
                        <CheckOutlined />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Khôi phục"
                      onClick={() => {
                        handleCloseEditInfo();
                      }}
                    >
                      <IconButton color="error">
                        <ReplayOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  <Box>
                    <Tooltip title="Chỉnh sửa">
                      <IconButton
                        color="success"
                        onClick={() => {
                          console.log(info);
                          handleOpenEditInfo(info.row);
                        }}
                      >
                        <CreateOutlined />
                      </IconButton>
                    </Tooltip>
                    {info.row.original.approved === true ? (
                      <Tooltip title="Đã Duyệt">
                        <IconButton
                          color="warning"
                          onClick={() => {
                            handleApproveInfo(info.row);
                          }}
                          disabled={isLoadingApproveData}
                        >
                          <TaskAltOutlined />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Chưa duyệt">
                        <IconButton
                          color="warning"
                          onClick={() => {
                            handleApproveInfo(info.row);
                          }}
                          disabled={isLoadingApproveData}
                        >
                          <UnpublishedOutlined />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Xóa">
                      <IconButton
                        color="error"
                        onClick={() => {
                          handleDeleteInfo(info.row);
                        }}
                      >
                        <ClearOutlined />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]
        : []),
    ];

    const columnsIndicator: ColumnDef<IDataItem, unknown>[] = indicatorColumn.map((item) => {
      const columnIndicatorItem = {
        id: item.symbol,
        size: 150,
        accessorKey: `data.${item.symbol}`,
        header: () => <span>{`${item.symbol} (${item.unit})`}</span>,
      };

      return columnIndicatorItem;
    });

    setColumnsIndicators(columnsIndicator);
    const columnTable = finalColumn.concat(columnsIndicator);

    return columnTable;
  }, [editableRowId, indicatorColumn, payloadData]);

  return (
    <EditTable<IDataItem>
      data={dataList.data}
      isLoading={isPending || isLoadingGetData}
      columns={columns}
      defaultEditColumn={defaultEditColumn}
      size="small"
    />
  );
};
export default DataTable;
