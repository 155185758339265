import { createAsyncThunk } from '@reduxjs/toolkit';
import { systemSettingApi } from 'src/clients/http';
import { ISystemSetting } from 'src/types/system_setting';

export const getSystemSetting = createAsyncThunk<ISystemSetting>(
  'systemSetting/getSystemSetting',
  async (_, { rejectWithValue }) => {
    try {
      const response = await systemSettingApi.getSystemSetting();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const updateSystemSettingInfo = createAsyncThunk<
  string,
  { id: string; data: Pick<ISystemSetting, 'generalInfo'> }
>('systemSetting/updateSystemSettingInfo', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await systemSettingApi.updateSystemSettingInfo(id, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateSystemSettingService = createAsyncThunk<
  string,
  { id: string; data: Omit<ISystemSetting, 'generalInfo' | 'updatedAt' | 'id'> }
>('systemSetting/updateSystemSettingService', async ({ id, data }, { rejectWithValue }) => {
  try {
    const response = await systemSettingApi.updateSystemSettingService(id, data);
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
