import { Avatar, Badge, Box, Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from 'src/assets/images/logo.png';
import logoSTTTT from 'src/assets/images/logo_stttt.png';
import sitechLogo from 'src/assets/svg/sitech-logo.svg';
import { CModalIds } from 'src/constants';
import { CPath } from 'src/constants/path';
import AccountManagement from 'src/pages/account_management';
import { useAppDispatch, useAppSelector } from 'src/redux_store';
import { openModal } from 'src/redux_store/common/modal_slice';
import theme from 'src/theme';

import ICON_TOPBAR_LIST from 'src/constants/icon_topbar_list';
import ModalNoteIndicator from 'src/pages/home/modal_note';
import { openBoxRightContent } from 'src/redux_store/common/topbar/topbar_slice';
import { markAllAsSeenNotification } from 'src/redux_store/notification/notification_action';
import { setNumberAndInfoOfNotification } from 'src/redux_store/notification/notification_slice';
import { INotification } from 'src/types/notification';
import { isSTNMT, isSiTechSystem } from 'src/utils/function';
import { syncNumberOfNotification } from 'src/utils/notification';
import ModalAcceptLogout from '../modal/modal_logout';
import IconButtonTooltip from '../tooltip/icon_button_tooltip';
import { useStyles } from './styles';
// import ICON_TOPBAR_LIST from 'src/constants/icon_topbar_list';
// import IconButtonTooltip from '../tooltip/icon_button_tooltip';
import { ContactSupport, ContactSupportOutlined } from '@mui/icons-material';
import config from 'src/config';

interface ITopbar {
  title: string;
}

const Topbar: React.FC<ITopbar> = ({ title }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { indexActive } = useAppSelector(({ topBarSlice }) => topBarSlice);
  const { numberOfNotifications } = useAppSelector((state) => state.notificationSlice);
  const { me } = useAppSelector((state) => state.myAccountSlice);
  const modalState = useAppSelector((state) => state.modalSlice);
  const modal = modalState[CModalIds.noteIndicator];

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [open, setOpen] = React.useState<{
    [x: string]: boolean;
  }>({});

  const handleOpen = (event: React.MouseEvent<HTMLElement>, name: string) => {
    setAnchorEl(event.currentTarget);
    setOpen({ [name]: true });
  };

  const handleClose = (name: string) => {
    setAnchorEl(null);
    setOpen({ [name]: false });
  };

  const stringAvatar = (name: string) => {
    return {
      children: `${name.split(' ')[0][0]}`,
    };
  };

  const handleOpenModalLogout = () => {
    dispatch(openModal({ modalId: CModalIds.logout, modalComponent: <ModalAcceptLogout /> }));
  };
  const handleOpenModalGetUser = () => {
    dispatch(
      openModal({
        modalId: CModalIds.getUser,
        modalComponent: <AccountManagement modalId={CModalIds.getUser} />,
      }),
    );
  };

  const handleOpenModalNote = () => {
    dispatch(
      openModal({
        modalId: CModalIds.noteIndicator,
        modalComponent: <ModalNoteIndicator />,
      }),
    );
  };

  const handleClickTopBarIcon = (index: number, typeId: string) => {
    if (typeId === 'notification') {
      if (me) {
        syncNumberOfNotification(me.id);
      }
      dispatch(
        setNumberAndInfoOfNotification({
          numberNotification: 0,
          data: {} as INotification,
        }),
      );
      dispatch(markAllAsSeenNotification());
    }

    dispatch(openBoxRightContent({ index, typeId }));
  };

  const renderIconTopBar = () => {
    return ICON_TOPBAR_LIST.map((icon, index) => {
      const Icon = icon.icon;
      const ActiveIcon = icon.activeIcon;

      return (
        <Grid item key={index}>
          {indexActive === index || `/${icon.id}` === location.pathname ? (
            <IconButtonTooltip
              title={icon.title}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <ActiveIcon fontSize="medium" color="primary" />
                  </Badge>
                ) : (
                  <ActiveIcon fontSize="medium" color="primary" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
            />
          ) : (
            <IconButtonTooltip
              title={icon.title}
              icon={
                icon.id === 'notification' ? (
                  <Badge badgeContent={numberOfNotifications} color="error">
                    <Icon fontSize="medium" />
                  </Badge>
                ) : (
                  <Icon fontSize="medium" />
                )
              }
              size="medium"
              style={{ color: theme.palette.secondary.main }}
              onClick={() => handleClickTopBarIcon(index, icon.id)}
            />
          )}
        </Grid>
      );
    });
  };

  return (
    <Box
      className={classes.root}
      sx={{
        height: {
          md: isSiTechSystem ? theme.spacing(11) : theme.spacing(6),
          xs: isSiTechSystem ? theme.spacing(7) : theme.spacing(6),
        },
        px: {
          md: theme.spacing(isSiTechSystem ? 7 : 2),
          xs: theme.spacing(isSiTechSystem ? 5 : 2),
        },
      }}
    >
      <Box display="flex" flexWrap="nowrap" height="100%">
        <Box
          className={classes.logo}
          sx={{
            py: {
              md: '10px',
              xs: '6px',
            },
            height: {
              md: '100%',
              xs: '100%',
            },
          }}
        >
          <img
            onClick={() => navigate(CPath.home)}
            src={isSiTechSystem ? sitechLogo : isSTNMT ? logo : logoSTTTT}
            className={classes.logoImg}
            alt="danateq.vn"
          />
        </Box>

        {config.systemName !== 'SITECH' && (
          <Box
            className={classes.title}
            sx={{
              display: {
                xs: 'none',
                md: 'flex',
              },
            }}
          >
            <Typography fontWeight={600} variant="body1" textTransform="uppercase">
              {title}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        mx={2}
        display="flex"
        alignItems="center"
        flex={1}
      >
        <Typography
          textAlign="center"
          width="100%"
          fontWeight={600}
          variant="subtitle1"
          textTransform="uppercase"
          sx={{
            fontSize: {
              lg: isSiTechSystem ? 36 : 20,
              md: isSiTechSystem ? 28 : 20,
              xs: 0,
            },
            fontFamily: isSiTechSystem ? 'Oswald' : 'inherit',
          }}
          color={isSiTechSystem ? theme.palette.success.main : theme.palette.primary.main}
        >
          Hệ thống quan trắc môi trường
        </Typography>
      </Box>

      <Box className={classes.buttonAction}>
        <IconButtonTooltip
          title="Chú thích màu"
          icon={!modal?.open ? <ContactSupportOutlined /> : <ContactSupport />}
          color={modal?.open ? `primary` : 'default'}
          onClick={handleOpenModalNote}
          size="medium"
        />
        <Grid container>{renderIconTopBar()}</Grid>
        <Box>
          <Button
            sx={{ minWidth: 'unset', marginLeft: '12px' }}
            onClick={(e) => handleOpen(e, 'userSetting')}
          >
            <Avatar
              sx={{
                bgcolor: theme.palette.error.main,
                height: '28px',
                width: '28px',
                fontSize: '18px',
              }}
              {...stringAvatar(me?.name || '')}
            ></Avatar>
          </Button>
          <Menu
            id="userSetting"
            open={Boolean(open?.userSetting)}
            anchorEl={anchorEl}
            elevation={2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={() => handleClose('userSetting')}
          >
            <MenuItem onClick={handleOpenModalGetUser} disabled={me ? false : true}>
              Tài khoản của tôi
            </MenuItem>
            <MenuItem onClick={handleOpenModalLogout}>Đăng xuất</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Topbar;
