import { createSlice } from '@reduxjs/toolkit';

interface IState {
  isReloadVideo: boolean;
  streamStopTotal: number;
  streamErrorTotal: number;
}

const initialState: IState = {
  isReloadVideo: false,
  streamStopTotal: 0,
  streamErrorTotal: 0,
};

const ptzSlice = createSlice({
  initialState,
  name: 'ptz',
  reducers: {
    setStreamStopTotal: (state, action) => {
      state.streamStopTotal = action.payload;
    },
    setStreamErrorTotal: (state, action) => {
      state.streamErrorTotal = action.payload;
    },
  },
});
const { actions, reducer } = ptzSlice;

export const { setStreamStopTotal, setStreamErrorTotal } = actions;
export default reducer;
