export const CPath = {
  home: '/latest_data',
  login: '/login',
  register: '/register',
  verifyPassword: '/change_password',
  sendEmail: '/send_email',
  indicator: '/indicator',
  watchlist: '/watchlist',
  station: '/station',
  map: '/map',
  systemSetting: '/system_setting',
  camera: '/camera',
  userManagement: '/user_management',
  postManagement: '/post_management',
  postManagementAddPost: '/post_management/add_new_post',
  postManagementEditPost: '/post_management/edit_post/:id',
  autoSample: '/auto_sample',
  penaltyManagement: '/penalty_management',
  problemManagement: '/problem_management',

  stationInfo: '/station/:id',
  dataManagement: '/data_management',

  stationChart: '/station_chart/:id',

  statistic: '/statistic',
  apiManagement: '/apis_management',

  userLogs: '/user_logs',
  synchronizedData: '/synchronized_data',
  firmware: '/firmware',

  collectData: '/collect_data',

  notFound: '*',
};
