import React from 'react';
import { Box } from '@mui/material';

// import { PlayerRTC } from 'src/components/player_rtc';
import { ICamera } from 'src/types/camera';
import theme from 'src/theme';
import { PlayerHls } from 'src/components/player_hls';

interface IProps {
  camera: ICamera | null;
  wsOpen: boolean;
}

const CameraStream = ({ camera }: IProps) => {
  if (!camera) return null;

  const { id, name = '', status, subStream = '', mainStream = '', isSupportPtz } = camera;

  return (
    <Box display="flex" flex={1} alignItems="center" bgcolor={theme.palette.common.black}>
      {/* <PlayerRTC
        cam={{
          id,
          name,
          status,
        }}
        wsOpen={wsOpen}
        isHideControlBar
      /> */}
      <PlayerHls
        cam={{
          id,
          mainStream,
          subStream,
          name,
          status,
          isSupportPtz,
        }}
        isHideModeOption
        isHiddenPtz
      />
    </Box>
  );
};

export default CameraStream;
