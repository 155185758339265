import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { initApisInfo } from 'src/constants/apis_management';
import { IApis, IGroup, IParams, IGroupSearch } from 'src/types/apis';
import { findIndexItem } from 'src/utils/function';
import {
  createGroup,
  createRequest,
  deleteGroupById,
  deleteRequestById,
  getGroupById,
  getGroups,
  getRequestById,
  updateGroupById,
  updateHeadersByIdRequest,
  updateParamsByIdRequest,
  updateQueryParamsByIdRequest,
  updateRequestById,
  updateBodyByIdRequest,
} from './apis_management_action';
interface IState {
  selectedGroup: string;
  selectedRequest: string;
  selectedTab: string;
  groups: IGroup[];
  group: IGroup;
  paramsUrl: IParams[];
  requestInfo: IApis;
  filters: IGroupSearch;
}

const initialState: IState = {
  selectedGroup: '',
  selectedRequest: '',
  selectedTab: '',
  groups: [],
  group: { id: '', name: '', description: '' },
  requestInfo: initApisInfo,
  paramsUrl: [],
  filters: {
    searchKeyword: '',
  },
};

const apisManagementSlice = createSlice({
  name: 'apis_management',
  initialState,
  reducers: {
    setSelectedGroup: (state: IState, action: { payload: string }) => {
      state.selectedGroup = action.payload;
    },

    setSelectedRequest: (state: IState, action) => {
      state.selectedRequest = action.payload;
    },

    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    setFilters: (state, action) => {
      state.filters = action.payload;
    },

    resetData: (state: IState) => {
      // state.selectedGroup = initialState.selectedGroup;
      state.selectedRequest = initialState.selectedRequest;
    },

    setParamsQuery: (state, action) => {
      const queryParams = action.payload;
      state.requestInfo.queryParams = queryParams;
    },

    setParamsUrl: (state, action) => {
      const paramsUrl = action.payload;
      state.requestInfo.params = paramsUrl;
    },

    setBody: (state, action) => {
      state.requestInfo.body = action.payload;
    },

    setUrl: (state, action) => {
      state.requestInfo.url = action.payload;
    },

    setHeaders: (state, action) => {
      state.requestInfo.headers = action.payload;
    },

    setResponse: (state, action) => {
      state.requestInfo.response = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.groups = action.payload;
    });
    builder.addCase(createGroup.fulfilled, (state, action) => {
      const newPayload: IGroup = {
        ...action.payload,
        apis: [],
      };
      state.groups.push(newPayload);
    });
    builder.addCase(getGroupById.fulfilled, (state, action) => {
      state.group = action.payload;
    });
    builder
      .addCase(deleteGroupById.fulfilled, (state, action) => {
        const currentData = [...state.groups];

        const idDelete = action.meta.arg;

        const newData = currentData.filter((item) => item.id !== idDelete);
        state.groups = newData;
      })
      .addCase(updateGroupById.fulfilled, (state, action) => {
        const { id } = action.payload;
        const index = findIndexItem(state.groups, id + '');
        const newGroups = [...state.groups];
        const newGroup = newGroups[index];
        newGroups[index] = {
          ...newGroup,
          ...action.payload,
        };
        state.groups = newGroups;
      })
      .addCase(createRequest.fulfilled, (state, action) => {
        const newRequest = action.payload;

        const { groupId, id, name, description, url, method } = newRequest;

        const cloneGroupList = [...state.groups];
        const indexOfGroup = cloneGroupList.findIndex((group) => group.id === groupId);

        if (indexOfGroup === -1) return;
        const groupItem: any = state.groups[indexOfGroup];
        if (!_.isEmpty(groupItem)) {
          const cloneItem = { ...groupItem };
          const cloneApiList = cloneItem.apis;

          cloneApiList.push({ groupId, id, name, description, url, method });

          state.groups[indexOfGroup].apis = cloneApiList;
        }
      })
      .addCase(updateRequestById.fulfilled, (state, action) => {
        const newRequest = action.payload;

        const { groupId, id } = newRequest;

        const cloneGroupList = [...state.groups];
        const indexOfGroup = cloneGroupList.findIndex((group) => group.id === groupId);

        if (indexOfGroup === -1) return;
        const groupItem: any = state.groups[indexOfGroup];
        if (!_.isEmpty(groupItem)) {
          const cloneItem = { ...groupItem };
          const cloneApiList = cloneItem.apis;
          const indexOfRequest = cloneApiList.findIndex((item: any) => item.id === id);
          if (indexOfRequest === -1) return;
          cloneApiList[indexOfRequest] = newRequest;

          state.groups[indexOfGroup].apis = cloneApiList;
        }
        state.requestInfo = action.payload;
      })
      .addCase(getRequestById.fulfilled, (state, action) => {
        state.requestInfo = action.payload;
      })
      .addCase(deleteRequestById.fulfilled, (state, action) => {
        const groupId = state.selectedGroup;
        const cloneGroupList = [...state.groups];

        const idRequest = action.meta.arg;

        const indexOfGroup = cloneGroupList.findIndex((group) => group.id === groupId);
        if (indexOfGroup === -1) return;
        const groupItem: any = state.groups[indexOfGroup];
        if (!_.isEmpty(groupItem)) {
          const cloneItem = { ...groupItem };
          const cloneApiList = cloneItem.apis;
          // const indexOfRequest = cloneApiList.findIndex(
          //   (item: any) => item.id === state.selectedRequest,
          // );

          const newApiList = cloneApiList.filter((item: any) => item.id !== idRequest);
          state.groups[indexOfGroup].apis = newApiList;
          state.selectedRequest = initialState.selectedRequest;
        }
      })
      .addCase(updateParamsByIdRequest.fulfilled, (state, action) => {
        state.requestInfo = action.payload;
      })
      .addCase(updateQueryParamsByIdRequest.fulfilled, (state, action) => {
        state.requestInfo = action.payload;
      })
      .addCase(updateHeadersByIdRequest.fulfilled, (state, action) => {
        state.requestInfo = action.payload;
      })
      .addCase(updateBodyByIdRequest.fulfilled, (state, action) => {
        state.requestInfo = action.payload;
      });
  },
});

export const {
  setSelectedGroup,
  resetData,
  setSelectedRequest,
  setParamsQuery,
  setParamsUrl,
  setBody,
  setUrl,
  setSelectedTab,
  setHeaders,
  setResponse,
  setFilters,
} = apisManagementSlice.actions;
export default apisManagementSlice.reducer;
