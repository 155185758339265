import React from 'react';
import { Box, Grid, Chip } from '@mui/material';
import ScrollContainer from 'react-indiana-drag-scroll';

import { useStyles } from './styles';

type TProps = {
  handleSelectType: (type: string) => void;
  type: string;
};

const typeList: { id: string; name: string }[] = [
  {
    id: 'UNREAD',
    name: 'Chưa đọc',
  },
  {
    id: 'INFO',
    name: 'Thông tin',
  },
  {
    id: 'ERROR',
    name: 'Lỗi',
  },
  {
    id: 'WARNING',
    name: 'Cảnh báo',
  },
  {
    id: 'CRITICAL',
    name: 'Quan trọng',
  },
];

const TypeTabNotification = (props: TProps) => {
  const { handleSelectType, type } = props;
  const classes = useStyles();

  const handleSelectTypeItem = (typeSelected: string) => {
    handleSelectType(typeSelected);
  };

  return (
    <Box p={1} overflow="hidden" className={classes.root}>
      <ScrollContainer className="scroll-container" hideScrollbars>
        <Grid container flexWrap="nowrap">
          {typeList.map((item) => {
            return (
              <Grid item key={item.id} mr={1}>
                {/* <Button
                  variant={type.includes(item.id) ? 'contained' : 'outlined'}
                  size="small"
                  onClick={() => handleSelectTypeItem(item.id)}
                >
                  {item.value}
                </Button> */}
                <Chip
                  label={item.name}
                  color="primary"
                  variant={type === item.id ? 'filled' : 'outlined'}
                  onClick={() => handleSelectTypeItem(item.id)}
                  sx={{ borderRadius: 2 }}
                />
              </Grid>
            );
          })}
        </Grid>
      </ScrollContainer>
    </Box>
  );
};

export default TypeTabNotification;
