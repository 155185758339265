import { IUserLog, IDataFilterUserLogs, IFilterUserLogs } from 'src/types/user_log';
import { createClient } from './axios_client';
import qs from 'query-string';

const client = createClient('https://qamt.danateq.vn/api/v1');

export const userLogsApi = {
  getFilterUserLog: () => {
    return client.get<IDataFilterUserLogs>(`/user_logs/filter`);
  },

  getDataUserLog: (params: IFilterUserLogs) => {
    return client.get<IUserLog>(`/user_logs?${qs.stringify(params)}`);
  },

  deleteUserLog: (userId: string) => {
    return client.delete<string>(`/user_logs/${userId}`);
  },
};
