import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider } from '@mui/material';
import Scrollbars from 'react-custom-scrollbars-2';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'src/components/error_message';

import { useGetStatus } from 'src/hooks';
import { useAppDispatch } from 'src/redux_store';
import { editCameraConnect, getCameraConnect } from 'src/redux_store/camera/camera_action';
import { ICamera, ICameraConnect } from 'src/types/camera';
import { toastMessage } from 'src/utils/toast';
import { CameraConnectForm, schemaCameraConnect } from '../../camera_form';
import { useStyles } from './styles';

interface IProps {
  camera: ICamera;
  deleteCamera: () => void;
  refreshCameras?: () => void;
}

const CameraInfo = ({ camera, refreshCameras, deleteCamera }: IProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const [information, setInformation] = React.useState<ICameraConnect | null>(null);
  const [isLoading, isError] = useGetStatus('camera', 'getCameraConnect');
  const [isUpdate] = useGetStatus('camera', 'editCameraConnect');
  const [isDelete] = useGetStatus('camera', 'deleteCamera');

  const defaultValues = React.useMemo(
    () => ({
      name: information?.name ? information.name : '',
      stationId: information?.stationId ? information.stationId : '',
      isSharedLink: information?.isSharedLink || camera.isSharedLink,
      ...(camera.isSharedLink && {
        rtspLinks: information?.rtspLinks
          ? information.rtspLinks
          : [{ name: 'Stream 1', rtspLink: '' }],
      }),
      ...(!camera.isSharedLink && {
        ip: information?.ip ? information.ip : '',
        pass: information?.pass ? information.pass : '',
        user: information?.user ? information.user : '',
        portOnvif: information?.portOnvif ? information.portOnvif : '80',
        portRtsp: information?.portRtsp ? information.portRtsp : '554',
      }),
    }),
    [information],
  );

  const { control, handleSubmit, reset, setError } = useForm<ICameraConnect>({
    resolver: yupResolver(schemaCameraConnect(camera.isSharedLink)),
    defaultValues,
  });

  const fetchApi = () => {
    dispatch(getCameraConnect(camera.id))
      .unwrap()
      .then((data) => setInformation(data));
  };

  React.useEffect(() => {
    if (information) {
      reset(defaultValues);
    }
  }, [information]);

  React.useEffect(() => {
    fetchApi();
  }, [camera.id]);

  const onSubmit = (data: ICameraConnect) => {
    dispatch(editCameraConnect({ id: camera.id, data }))
      .unwrap()
      .then(() => {
        toastMessage.success('Cập nhật camera thành công');
        if (refreshCameras) {
          refreshCameras();
        }
      })
      .catch((errors) => toastMessage.setErrors(errors, setError));
  };

  const renderContent = () => {
    if (isError) {
      return <ErrorMessage onClick={fetchApi} />;
    }

    return (
      <CameraConnectForm
        isLoading={isLoading}
        control={control}
        isSharedLink={camera.isSharedLink}
        disabled={isUpdate}
        isEdit
      />
    );
  };

  return (
    <Box className={classes.root} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Scrollbars autoHide>
        <Box flex={1} p={1}>
          {renderContent()}
        </Box>
      </Scrollbars>
      <Divider />
      <Box p={1} textAlign="right">
        <LoadingButton
          sx={{ mr: 1 }}
          type="button"
          variant="outlined"
          color="error"
          disabled={isUpdate}
          onClick={deleteCamera}
          startIcon={<Delete />}
          loading={isDelete}
        >
          Xóa camera
        </LoadingButton>
        <LoadingButton
          loading={isUpdate}
          type="submit"
          variant="outlined"
          startIcon={<SaveOutlined />}
        >
          Lưu
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CameraInfo;
